/* eslint-disable react-hooks/exhaustive-deps */
import { InfoCircleFilled } from "@ant-design/icons";
import { Affix, Button, Form, Space, Typography } from "antd";
import CreateButton from "components/CreateButton";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import LoadingComponent from "components/LoadingComponent";
import PageHeader from "components/PageHeader";
import { DIMENSION_UNIT, INPUT_TYPE, WEIGHT_UNIT } from "config/constants";
import Card from "features/Cart/components/Card";
import { PRODUCT_TYPE } from "features/Products/constants";
import { useFlags } from "flagsmith/react";
import { useAppFlags } from "hooks/flags";
import { useGetManufacturers } from "hooks/manufacturer/manufacturer";
import {
  convertAttributesProductGroupDetail,
  convertInitFormProducts,
  convertProdsWithNewAttValues,
  convertProductsInput,
  convertVariantTable,
} from "hooks/product/converter";
import {
  useCreateProductGroup,
  useGetAllCategories,
  useGetAttributeOfCategory,
  useGetBrand,
  useGetLatestProductCode,
  useGetProductGroupInitForm,
  useGetUom,
  useProductPermissions,
  useUpdateProductGroup,
} from "hooks/product/product";
import { useUpload } from "hooks/upload";
import usePrevious from "hooks/usePrevious";
import { flatten, has, isNil, omit } from "lodash";
import { VAT_TYPE } from "pages/App/Product/constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { notify, parseAntUploadFiles } from "utils/helperFuncs";
import { ACTION } from "../../conts";
import InfoProduct from "./components/InfoProduct";
import { ModalConfirmCreate } from "./components/ModalConfirmCreate";
import PriceInfo from "./components/PriceInfo";
import ProductAttributes from "./components/ProductAttributes";
import VariantManage from "./components/VariantManage";
import styles from "./index.module.scss";

const MAX_IMAGES = 9;

const FormProduct = ({ action }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [isDisableUpload, setIsDisableUpload] = useState(false);
  const [categoryID, setCategoryID] = useState();
  const prevCategoryID = usePrevious(categoryID);
  const { id: productGroupId } = useParams();

  const [isHasVariant, setIsHasVariant] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [disableCheckboxHasVariant, setDisableCheckboxHasVariant] = useState(false);
  const [products, setProducts] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [changeCategoryModalVisible, setChangeCategoryModalVisible] = useState(false);
  const { handleUpload } = useUpload();
  const { dataManufactures, loadingManufactures } = useGetManufacturers();
  const { dataBrand } = useGetBrand();

  const { data: dataAllCategories, loading: loadingAllCategories } = useGetAllCategories();
  const { data: dataAttributeOfCategory, loading: loadingAttributeOfCategory } = useGetAttributeOfCategory(categoryID);
  const { data: dataUom, loading: loadingUom } = useGetUom();
  const { handleCreateProductGroup, loading: loadingCreate } = useCreateProductGroup();

  const { data: productGroupData, loading: loadingInitProductGroup } = useGetProductGroupInitForm(productGroupId);

  const { handleUpdateProductGroup, loading: loadingUpdate } = useUpdateProductGroup();

  const { canUpdateInfo, loadingProductPermissions, canUpdatePrice } = useProductPermissions();

  const isActionCreate = action === ACTION.CREATE;
  const [isVAT, setIsVAT] = useState(true);
  const isProductGroupHasVariant = productGroupData?.hasVariant || false;

  const loadingComponent =
    loadingManufactures ||
    loadingAllCategories ||
    loadingAttributeOfCategory ||
    loadingUom ||
    loadingInitProductGroup ||
    loadingProductPermissions;

  const defaultVatValue =
    productGroupData?.products[0]?.vatValue !== undefined ? productGroupData?.products[0]?.vatValue : 0;

  const defaultVatPercent =
    productGroupData?.products?.[0]?.vatPercent !== undefined
      ? [-1, 0, 3, 5, 8, 10].includes(productGroupData?.products?.[0]?.vatPercent)
        ? productGroupData.products[0].vatPercent
        : "OTHER"
      : -1;

  const [productType, setProductType] = useState();
  const [isAlertProductName, setIsAlertProductName] = useState(false);
  const [isAlertCategory, setIsAlertCategory] = useState(false);
  const [isForever, setIsForever] = useState(false);

  const initForm = {
    name: location.state?.productName || productGroupData?.name || "",
    code: productGroupData?.code || "",
    imageURLs: parseAntUploadFiles(productGroupData?.imageURLs) || [],
    description: productGroupData?.description || "",
    brandID: productGroupData?.brandID || null,
    categoryID: productGroupData?.categoryID || null,
    uomID: productGroupData?.uomID || null,
    productPrices: {
      importPrice: productGroupData?.productPrices?.importPrice || undefined,
      price: productGroupData?.products && productGroupData?.products[0]?.productPrices?.price,
    },
    vat: productGroupData?.vat || isVAT,
    vatPercent:
      productGroupData?.products && [-1, 0, 3, 5, 8, 10].includes(productGroupData?.products?.[0]?.vatPercent)
        ? undefined
        : productGroupData?.products?.[0]?.vatPercent,
    vatValue:
      (productGroupData?.products && productGroupData?.products[0].vatValue) >= 0
        ? productGroupData?.products && productGroupData?.products[0].vatValue
        : 0,
    warranty: (productGroupData?.products && productGroupData?.products[0].warranty) || "",
    //attributeValues not control in init form
    attributeValues: [],
    //products not control in init form
    products: [],
    hasVariant: productGroupData?.hasVariant || false,
    dimension: {
      length: productGroupData?.dimension?.length,
      width: productGroupData?.dimension?.width,
      height: productGroupData?.dimension?.height,
      unit: productGroupData?.dimension?.unit || DIMENSION_UNIT.MILLIMETER,
    },
    weight: {
      weight: productGroupData?.weight?.weight,
      unit: productGroupData?.weight?.unit || WEIGHT_UNIT.KILOGRAM,
    },
    taxableGapVAT: defaultVatValue >= 0,
    taxablePercentVAT: defaultVatPercent,
    vatType: (productGroupData?.products && productGroupData?.products[0].vatType) || VAT_TYPE.REQUIRED,
    productType: isActionCreate
      ? PRODUCT_TYPE.KEY_LICENSE
      : productGroupData?.defaultProduct?.productType === "MATERIAL"
      ? PRODUCT_TYPE.KEY_LICENSE
      : productGroupData?.defaultProduct?.productType,
    copyrightTerm: productGroupData?.defaultProduct?.copyrightTerm,
    copyrightUnit: productGroupData?.defaultProduct?.copyrightUnit || "MONTH",
    productTypeLabel: isActionCreate
      ? "MATERIAL"
      : productGroupData?.defaultProduct?.productType === "MATERIAL"
      ? "MATERIAL"
      : PRODUCT_TYPE.KEY_LICENSE,
    warrantyUnit: (productGroupData?.products && productGroupData?.products[0].warrantyUnit) || "MONTH",
  };

  useEffect(() => {
    form.setFieldsValue({ ...initForm });
    setIsVAT(productGroupData?.vat);
    setProductType(
      isActionCreate
        ? "MATERIAL"
        : productGroupData?.defaultProduct?.productType === "MATERIAL"
        ? "MATERIAL"
        : PRODUCT_TYPE.KEY_LICENSE
    );
    setIsForever(productGroupData?.defaultProduct?.copyrightUnit === "FOREVER" ? true : false);
  }, [JSON.stringify(productGroupData)]);

  const handleGetSetIsVAT = (value) => {
    setIsVAT(value);
  };

  const clearFields = () => {
    form.resetFields();
    setFieldValue(initForm);
    setIsDisableUpload(false);
    setCategoryID(null);
    setIsHasVariant(false);
    setAttributes([]);
    setDisableCheckboxHasVariant(false);
    setProducts([]);
    setDataSource([]);
    setChangeCategoryModalVisible(false);
  };

  const handleSearchSelect = (search, option) => {
    return option?.label?.toLowerCase()?.includes(search?.toLowerCase());
  };

  const handleSearchTreeSelect = (search, option) => {
    return option?.label?.toLowerCase()?.indexOf(search?.toLowerCase()) >= 0;
  };

  const uploadImage = (values) =>
    values.map(async (item) => {
      if (!!item?.url) return item.url;
      if (typeof item == "string") return item;
      return await handleUpload({ file: item?.originFileObj });
    });

  const [isModalConfirm1, setIsModalConfirm1] = useState(false);

  const handleModalConfirmOpen1 = async () => {
    await form.validateFields();
    setIsModalConfirm1(true);
  };

  const handleModalConfirmCancel1 = () => {
    setIsModalConfirm1(false);
  };

  const [isModalConfirm2, setIsModalConfirm2] = useState(false);

  const handleModalConfirmOpen2 = async () => {
    await form.validateFields();
    setIsModalConfirm2(true);
  };

  const handleModalConfirmCancel2 = () => {
    setIsModalConfirm2(false);
  };

  //on finish form
  const handleFinishForm = async (isNavigate) => {
    const values = form.getFieldsValue(true);

    await form.validateFields();

    if (values.imageURLs) {
      const imageURLs = await Promise.all(uploadImage(values?.imageURLs)).then((res) => {
        return res;
      });

      const convertAttributeValueIDs = (isVariant, attributeValueID, attributeValueIDs) => {
        if (isVariant && attributeValueIDs?.length) return attributeValueIDs;
        if (!isVariant && attributeValueID) return [attributeValueID];
        return undefined;
      };

      //check if user fill prices
      const checkProductPrices = (productPrices) => {
        const allNull = Object.values(productPrices)?.every((item) => isNil(item)) || false;
        if (allNull) {
          return undefined;
        }
        return productPrices;
      };

      const commonInputValues = {
        name: values?.name,
        code: values?.code,
        imageURLs: imageURLs,
        description: values?.description,
        brandID: values?.brandID,
        categoryID: values?.categoryID,
        uomID: values?.uomID,
        warranty: values?.warranty,
        warrantyUnit: values?.warrantyUnit,
        hasVariant: values?.hasVariant,
        vat: values?.vat || false,
        attributeValues: attributes?.map((item) => ({
          attributeID: item?.attributeID,
          attributeValueIDs: convertAttributeValueIDs(item?.isVariant, item?.attributeValueID, item?.attributeValueIDs),
          isVariant: item?.isVariant,
          value: item?.value,
        })),
        dimension: values?.dimension,
        weight: values?.weight,
        vatType: values?.vatType,
        vatPercent: [-1, 0, 3, 5, 8, 10].includes(values?.taxablePercentVAT)
          ? values?.taxablePercentVAT
          : values?.vatPercent,
        vatValue: values?.taxableGapVAT ? values?.vatValue : -1,
        ...(values?.productTypeLabel === "MATERIAL"
          ? {
              dimension: values?.dimension,
              weight: values?.weight,
            }
          : {
              dimension: undefined,
              weight: undefined,
            }),
        ...(values?.productTypeLabel === PRODUCT_TYPE.KEY_LICENSE
          ? {
              productType: values?.productType,
              copyrightTerm: values?.copyrightUnit === "FOREVER" ? undefined : values?.copyrightTerm,
              copyrightUnit: values?.copyrightUnit,
            }
          : {
              productType: "MATERIAL",
              copyrightTerm: undefined,
              copyrightUnit: undefined,
            }),
      };

      if (!values?.hasVariant) {
        const noVariantInputValues = {
          productPrices: checkProductPrices(values?.productPrices),
          products: [],
        };

        if (isActionCreate) {
          try {
            await handleCreateProductGroup({
              ...commonInputValues,
              ...noVariantInputValues,
            }).then((res) => {
              const products = res?.data?.productGroup?.create?.products;
              const productID = products && products[0]?.id;
              notify.success({ message: t("product.modalConfirmCreate.successCreate") });
              isNavigate && history.push(`/product/detail/${productID}`);
              clearFields();
              handleModalConfirmCancel1();
              handleModalConfirmCancel2();
            });
          } catch (err) {
            notify.error({ message: t("common.error"), description: err.message });
          }
        } else {
          try {
            await handleUpdateProductGroup(productGroupId, {
              ...commonInputValues,
              ...noVariantInputValues,
            }).then(() => {
              notify.success({ message: t("product.modalConfirmCreate.successUpdate") });
              history.goBack();
            });
          } catch (err) {
            notify.error({ message: t("common.error"), description: err.message });
          }
        }
      } else {
        const imageProductsRaws = flatten(values?.products?.map((item) => item?.imageURL));
        const imageProductsURLs = await Promise.all(uploadImage(imageProductsRaws)).then((res) => {
          return res;
        });
        const products = values?.products?.map((item, index) => ({
          ...item,
          imageURL: imageProductsURLs[index],
        }));
        const newProducts = convertProductsInput(products);

        const hasVariantInputValues = { products: newProducts };

        if (isActionCreate) {
          try {
            await handleCreateProductGroup({
              ...commonInputValues,
              ...hasVariantInputValues,
            }).then(() => {
              notify.success({ message: t("product.modalConfirmCreate.successCreate") });
              isNavigate && history.push("/product");
              clearFields();
              handleModalConfirmCancel1();
              handleModalConfirmCancel2();
            });
          } catch (err) {
            notify.error({ message: t("common.error"), description: err.message });
          }
        } else {
          try {
            await handleUpdateProductGroup(productGroupId, {
              ...commonInputValues,
              ...hasVariantInputValues,
            }).then(() => {
              notify.success({ message: t("product.modalConfirmCreate.successUpdate") });
              history.push(`/product/detail/${productGroupData?.product?.[0]?.id}`);
            });
          } catch (err) {
            notify.error({ message: t("common.error"), description: err.message });
          }
        }
      }
    }
  };

  const setFieldValue = (object) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, ...object });
  };

  //on form value change
  const handleValuesChange = (changedValues, allValues) => {
    if (has(changedValues, "imageList")) {
      setIsDisableUpload(changedValues?.imageList.length === MAX_IMAGES);
    }

    if (has(changedValues, "categoryID")) {
      if (!prevCategoryID && !categoryID) {
        setCategoryID(changedValues?.categoryID);
      } else {
        setChangeCategoryModalVisible(true);
      }
    }

    if (has(changedValues, "hasVariant")) {
      const fetchedAttributesValues = convertAttributesProductGroupDetail(
        dataAttributeOfCategory,
        [],
        changedValues?.categoryID === productGroupData?.categoryID
      );
      setFieldValue({ attributeValues: fetchedAttributesValues });
      setIsHasVariant(changedValues?.hasVariant);
    }

    if (has(changedValues, "attributeValues")) {
      setAttributes([...allValues?.attributeValues]);
      setDisableCheckboxHasVariant(allValues?.attributeValues?.filter((item) => item?.isVariant).length === 3);
      setFieldValue({
        products: [...convertProdsWithNewAttValues(allValues?.products, allValues?.attributeValues)],
      });
      setProducts([...convertProdsWithNewAttValues(allValues?.products, allValues?.attributeValues)]);
    }

    if (has(changedValues, "products")) {
      setProducts(allValues?.products);
    }

    if (has(changedValues, "productTypeLabel")) {
      setProductType(allValues?.productTypeLabel);
      setIsAlertProductName(allValues?.productTypeLabel === PRODUCT_TYPE.KEY_LICENSE && true);
    }

    if (has(changedValues, "copyrightUnit")) {
      setIsForever(allValues?.copyrightUnit === "FOREVER" ? true : false);
    }
  };

  const handleCancel = () => {
    setChangeCategoryModalVisible(false);
    setFieldValue({ categoryID: categoryID });
  };

  const handleConfirm = () => {
    const categoryID = form.getFieldValue("categoryID");

    setCategoryID(categoryID);
    setChangeCategoryModalVisible(false);
    setIsHasVariant(false);
    // reset attribute values

    setFieldValue({
      categoryID: categoryID,
      attributeValues: [],
      products: [],
      hasVariant: false,
    });

    setAttributes([]);
    setDisableCheckboxHasVariant(false);
    //reset product table
    setDataSource([]);
  };

  useEffect(() => {
    setIsHasVariant(!!initForm.hasVariant);
    setCategoryID(initForm.categoryID);

    //form init
    form.setFieldsValue({
      ...initForm,
    });
  }, [JSON.stringify(omit(initForm, ["vat"]))]);

  const isHiddenAttributes = !(categoryID && attributes?.length > 0);

  useEffect(() => {
    const fetchedProducts = convertInitFormProducts(productGroupData?.products, dataAttributeOfCategory);
    setProducts(convertVariantTable(fetchedProducts));
    setDataSource(convertVariantTable(fetchedProducts));
    const fetchedAttributesValues = convertAttributesProductGroupDetail(
      dataAttributeOfCategory,
      productGroupData?.attributeValues,
      categoryID === productGroupData?.categoryID
    );
    setAttributes(fetchedAttributesValues);
    setDisableCheckboxHasVariant(fetchedAttributesValues?.filter((item) => item?.isVariant).length === 3);
    setFieldValue({ attributeValues: fetchedAttributesValues, products: fetchedProducts });
  }, [JSON.stringify(dataAttributeOfCategory), JSON.stringify(productGroupData)]);

  const { isDisableProductVariant } = useAppFlags();

  const productFormOptions = [
    {
      value: false,
      label: t("product.productForm.productHaveNotVariable"),
      disabled: isProductGroupHasVariant,
    },
    {
      value: true,
      label: t("product.productForm.productHaveVariable"),
      disabled: isDisableProductVariant,
    },
  ];

  const flags = useFlags(["is_create_product_key_license_account"]);
  const isCreateDigitalProduct = flags.is_create_product_key_license_account.enabled;
  const formData = form?.getFieldsValue(true);

  // suggestion product code
  const { latestProductCode } = useGetLatestProductCode();
  useEffect(() => {
    if (action === "CREATE") {
      form.setFieldsValue({
        ...initForm,
        code: latestProductCode,
      });
    }
  }, [latestProductCode]);

  return (
    <LoadingComponent loading={loadingComponent}>
      <PageHeader
        pageTitle={t("product.productForm.title")}
        routes={[
          {
            path: "/product",
            name: t("common.productList"),
          },
          {
            path: "",
            name: isActionCreate ? t("product.productForm.createProduct") : t("product.productForm.editProduct"),
          },
        ]}
      />
      <Form
        layout="vertical"
        initialValues={initForm}
        className={styles.container}
        form={form}
        onValuesChange={handleValuesChange}
      >
        <Space className={styles["product-type"]}>
          <Typography.Text strong>{t("common.productType")}</Typography.Text>
          <FormInput
            type={INPUT_TYPE.SELECT}
            formItemOptions={{
              rules: [
                {
                  required: true,
                  message: t("campaign.combo.errorMessage.qtyComboEstimate"),
                },
              ],
              style: { marginBottom: 0, width: "300px" },
              name: "productTypeLabel",
            }}
            inputOptions={{
              options: [
                { label: t("product.productManual"), value: "MATERIAL" },
                ...(isCreateDigitalProduct
                  ? [{ label: t("product.productKey"), value: PRODUCT_TYPE.KEY_LICENSE }]
                  : []),
              ],
              placeholder: t("campaign.combo.placeholder.type"),
              disabled: !isActionCreate && !canUpdateInfo,
            }}
          />
        </Space>
        <div className={styles["info-product"]}>
          <InfoProduct
            isDisableUpload={isDisableUpload}
            dataManufactures={dataManufactures}
            dataBrand={dataBrand}
            dataAllCategories={dataAllCategories}
            dataUom={dataUom}
            onSearchSelect={handleSearchSelect}
            onSearchTreeSelect={handleSearchTreeSelect}
            disabledInput={!isActionCreate && !canUpdateInfo}
            productType={productType}
            isAlertProductName={isAlertProductName}
            setIsAlertProductName={setIsAlertProductName}
            setIsAlertCategory={setIsAlertCategory}
            isAlertCategory={isAlertCategory}
            isForever={isForever}
            form={form}
          />
        </div>
        <div className={styles["product-attributes"]}>
          <Card
            title={
              <Typography.Title level={5} style={{ color: "#052987" }}>
                {t("product.productForm.productAttributes")}
              </Typography.Title>
            }
          >
            <FormInput
              type={INPUT_TYPE.RADIO_GROUP}
              formItemOptions={{
                name: "hasVariant",
                style: { marginBottom: "0px" },
              }}
              inputOptions={{ options: productFormOptions, disabled: !isActionCreate && !canUpdateInfo }}
            />
            {!isHiddenAttributes && (
              <ProductAttributes
                isHasVariant={isHasVariant}
                dataAttributeOfCategory={dataAttributeOfCategory}
                attributes={attributes}
                disableCheckboxHasVariant={disableCheckboxHasVariant}
                form={form}
                disabledInput={!isActionCreate && !canUpdateInfo}
              />
            )}
          </Card>
        </div>
        <div className={styles["price-info"]}>
          {!isHasVariant && (
            <Card
              title={
                <Typography.Title level={5} style={{ color: "#052987" }}>
                  {t("product.productForm.infoPrice")}
                </Typography.Title>
              }
            >
              <PriceInfo
                form={form}
                isVAT={isVAT}
                handleGetSetIsVAT={handleGetSetIsVAT}
                disabledInput={!isActionCreate && !canUpdatePrice}
                productGroupData={productGroupData}
              />
            </Card>
          )}
        </div>
        <div className={styles["variant-manage"]}>
          {isHasVariant && (
            <VariantManage
              form={form}
              disabledInputInfo={!isActionCreate && canUpdateInfo}
              disabledInputPrice={!isActionCreate && canUpdatePrice}
              attributes={attributes}
              products={products}
              dataSource={dataSource}
              setDataSource={setDataSource}
            />
          )}
        </div>
        <Affix className={styles["button-group"]} offsetBottom={10}>
          <div>
            <CustomModal
              onOke={() => history.push("/product")}
              centered={true}
              footer={false}
              customComponent={<Button className="custom-button">{t("common.cancel")}</Button>}
              isBlockCloseOnOke={true}
              message={
                <Space direction="vertical">
                  <InfoCircleFilled style={{ color: "rgba(239, 147, 81, 1)", fontSize: 50 }} />
                  <Typography.Text style={{ fontSize: "16px" }}>
                    {t("product.productForm.contentModal")}
                  </Typography.Text>
                </Space>
              }
            />

            <Form.Item className={styles.buttons}>
              <ModalConfirmCreate
                handleFinishForm={() => handleFinishForm(true)}
                isVAT={isVAT}
                customComponent={<CreateButton loading={loadingCreate || loadingUpdate} />}
                isModalConfirm={isModalConfirm1}
                handleModalConfirmOpen={handleModalConfirmOpen1}
                handleModalConfirmCancel={handleModalConfirmCancel1}
                loading={loadingCreate || loadingUpdate}
                quantityRealStockHasVat={
                  (productGroupData?.products &&
                    productGroupData?.products[0]?.stocks &&
                    productGroupData?.products[0]?.stocks[0]?.quantityRealStockHasVat) ??
                  0
                }
                productCode={productGroupData?.code}
                formData={formData}
                isActionCreate={isActionCreate}
              />
            </Form.Item>

            {isActionCreate && (
              <Form.Item className={styles.buttons}>
                <ModalConfirmCreate
                  handleFinishForm={() => handleFinishForm(false)}
                  isVAT={isVAT}
                  customComponent={<CreateButton title={t("common.saveAndAdd")} loading={loadingCreate} />}
                  isModalConfirm={isModalConfirm2}
                  handleModalConfirmOpen={handleModalConfirmOpen2}
                  handleModalConfirmCancel={handleModalConfirmCancel2}
                  loading={loadingCreate}
                  quantityRealStockHasVat={
                    (productGroupData?.products &&
                      productGroupData?.products[0]?.stocks &&
                      productGroupData?.products[0]?.stocks[0]?.quantityRealStockHasVat) ??
                    0
                  }
                  productCode={productGroupData?.code}
                  formData={formData}
                  isActionCreate={isActionCreate}
                />
              </Form.Item>
            )}
          </div>
        </Affix>
      </Form>
      {changeCategoryModalVisible && (
        <CustomModal
          closable={false}
          centered={true}
          footer={false}
          isBlockCloseOnOke
          switchClose={changeCategoryModalVisible}
          selfVisibleControlled={false}
          onCancel={handleCancel}
          // cancelButtonLabel={isValidCart ? t('common.close') : t('common.cancel')}
          // hideConfirmButton={isValidCart}
          onOke={handleConfirm}
        >
          <p>{t("product.productForm.warningMessageWhenChangeCategory")}</p>
        </CustomModal>
      )}
    </LoadingComponent>
  );
};

export default FormProduct;
