/* eslint-disable react-hooks/rules-of-hooks */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Card, Col, Row, Space, Typography } from "antd";
import { Icon, SvgIcon } from "assets/icons";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import FormInput from "components/FormInput";
import { INPUT_TYPE, PRODUCT_INPUT_TYPE } from "config/constants";
import {
  FilterLevelPrice,
  FilterManufacture,
} from "features/Campaign/components/Detail/ProductPromo/components/FilterProduct";
import RadioGroup from "features/Warranty/WarrantyList/component/RadioGroup";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatCurrency, validator } from "utils/helperFuncs";
import TagsList from "../TagsList";

import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { useEffect, useState } from "react";
import "./index.scss";
import useModalSelectGift from "./useModalSelectGift";

const FORM_ITEM_STYLE = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const optionVAT = [
  { label: t("product.vatCheckbox.yes"), value: true },
  { label: t("product.vatCheckbox.no"), value: false },
];

function ModalSelectGift({
  useInModal,
  form,
  promotionCampaignTiers,
  isUpdate,
  dataProductsServer,
  isCheckboxType,
  isGift,
  isProduct,
  indexComboGift,
  indexPromotionCampaignTiers,
  indexConditionProductGroups,
}) {
  const {
    isModalSelectProduct,
    dataAllCategories,
    filterItemsTypeOptions,
    rowSelection,
    loadingProduct,
    isFilterSelected,
    selectedRows,
    productData,
    paginationData,
    prodAtt,
    warehouses,
    openModalSelectProduct,
    closeModalSelectProduct,
    handleSelectProduct,
    filterProductByCategoryId,
    handleSearchTreeSelect,
    handleSearch,
    handleChange,
    onTableChange,
    renderProductAttributes,
    renderChildrenInStock,
    handleFilter,
    params,
    dataManufactures,
    loadingManufactures,
    listWarehouse,
    loadingWarehouses,
    setParams,
    filterProductExtra,
    filterProductPrices,
    selectedProducts,
    isVisibleQuestionModal,
    handleCancelModalQuestion,
    handleSubmitQuestion,
  } = useModalSelectGift({
    form,
    promotionCampaignTiers,
    isUpdate,
    dataProductsServer,
    isCheckboxType,
    isGift,
    isProduct,
    indexComboGift,
    indexPromotionCampaignTiers,
    indexConditionProductGroups,
  });

  const columns = [
    //Mã sản phẩm
    {
      title: <Typography.Text>{t("product.tableTitle.code")}</Typography.Text>,
      dataIndex: "code",
      width: 150,
      fixed: "left",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Link target={"_blank"} to={`/product/detail/${record?.id}`} className="linked-text">
              {record?.code}
            </Link>
            <CustomTextHiddenLine text={record?.name} line={1} />
          </div>
        );
      },
    },
    //Thương hiệu
    {
      title: <Typography.Text>{t("product.tableTitle.brand")}</Typography.Text>,
      dataIndex: ["manufacturer", "name"],
      align: "center",
      render: (_, record) => <div>{record?.manufacturer?.name ? record.manufacturer.name : "- -"}</div>,
      filterDropdown: <FilterManufacture dataManufactures={dataManufactures} onFilter={filterProductExtra} />,
    },
    //Bảo hành
    {
      title: <Typography.Text>{t("product.tableTitle.warranty")}</Typography.Text>,
      dataIndex: "warranty",
      align: "center",
      render: (_, record) => <div>{record?.warranty ? `${record.warranty} ${t("common.month")}` : "- -"}</div>,
    },
    ...renderProductAttributes(prodAtt),
    //Giá cấp 1
    {
      title: (
        <Typography.Text>
          <div className="title-table">GIÁ CHƯA VAT</div>
        </Typography.Text>
      ),
      dataIndex: ["productPrices", "price"],
      align: "right",
      render: (_, record) => <div>{formatCurrency(record?.productPrices?.price)}</div>,
      inputType: PRODUCT_INPUT_TYPE.CURRENCY,
      filterDropdown: (
        <FilterLevelPrice
          onFilter={(priceLevel) => {
            filterProductPrices(priceLevel, "price");
          }}
          defaultValue={params.filters.price}
        />
      ),
    },
    {
      title: (
        <Typography.Text>
          <div className="title-table">GIÁ CÓ VAT</div>
        </Typography.Text>
      ),
      dataIndex: ["productPrices", "priceVat"],
      align: "right",
      render: (_, record) => <div>{formatCurrency(record?.productPrices?.priceVat)}</div>,
      inputType: PRODUCT_INPUT_TYPE.CURRENCY,
      filterDropdown: (
        <FilterLevelPrice
          onFilter={(priceLevel) => {
            filterProductPrices(priceLevel, "priceVat");
          }}
          defaultValue={params.filters.priceVat}
        />
      ),
    },
    //Tồn thực
    {
      title: <Typography.Text>{t("product.tableTitle.inStock")}</Typography.Text>,
      children: renderChildrenInStock(warehouses),
      align: "center",
    },
    //Tồn kho
    ...(useInModal
      ? [
          {
            title: (
              <Typography.Text>
                <div className="title-table">{t("product.tableTitle.inStock")}</div>
              </Typography.Text>
            ),
            align: "right",
            render: (_, record) => <div>{record?.stocks?.at(0).quantity || "- -"}</div>,
          },
        ]
      : []),
    //Xuất vat
    {
      title: <Typography.Text>{t("product.EXPORT_VAT")}</Typography.Text>,
      render: (_, record) => (record?.vat ? <SvgIcon.SuccessIcon /> : <SvgIcon.CloseIcon />),
      align: "center",
      filterDropdown: (
        <FilterMenu type="radio" options={optionVAT} onFilter={(value) => handleFilter({ vat: value })} />
      ),
    },
  ];

  const dataSelected = selectedProducts || [];

  const dataSource = isFilterSelected ? selectedRows : productData;

  const columnsListItemGift = [
    {
      title: (
        <Space direction="horizontal" style={{ alignItems: "center" }}>
          <Typography.Text>{t("campaign.combo.giftCode")}</Typography.Text>
          <div style={{ cursor: "pointer" }} onClick={() => openModalSelectProduct(true)}>
            <Badge count={dataSelected.length} showZero>
              <SvgIcon.EditSquare />
            </Badge>
          </div>
        </Space>
      ),
      align: "left",
      width: "50%",
      render: (_, record, index) => {
        return (
          <Space direction="vertical">
            <Link key={index} to={`/product/detail/${record?.id}`} target="_blank">
              <Typography.Link strong>{record?.code}</Typography.Link>
            </Link>
            <CustomTextHiddenLine text={record?.name} line={1} />
          </Space>
        );
      },
    },
    {
      title: <Typography.Text strong>{t("campaign.combo.giftBudget")}</Typography.Text>,
      align: "center",
      render: (_, record, index) => {
        const [quantity, setQuantity] = useState(null);
        const [minQuantity, setMinQuantity] = useState(0);

        useEffect(() => {
          const validRegionItems = record?.stocks?.filter((item) => item?.warehouse?.regionID !== null);
          const totalQuantity = validRegionItems?.reduce((acc, stock) => acc + (stock?.quantity || 0), 0);
          setMinQuantity(totalQuantity || 0);
          setQuantity(record.budgetQuantity || record.quantity);
        }, [record]);

        return (
          <FormInput
            type={INPUT_TYPE.NUMBER}
            {...{
              formItemOptions: {
                name: [indexConditionProductGroups, index, "budgetQuantity"],
                validateStatus: quantity > minQuantity ? "warning" : undefined,
                help: quantity > minQuantity ? t("cart.lackOfStock") : undefined,
                rules: [
                  { required: true, message: t("campaign.combo.errorMessage.qty") },
                  {
                    type: "number",
                    min: 1,
                    message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                  },
                  validator({
                    type: "number",
                  }),
                ],
              },
              inputOptions: {
                placeholder: t("campaign.combo.placeholder.type"),
                className: "input-number",
                maxLength: 10,
                onChange: (value) => setQuantity(value),
              },
            }}
          />
        );
      },
    },
    {
      title: <Typography.Text strong>{t("campaign.combo.warrantyDate")}</Typography.Text>,
      align: "center",
      render: (_, record, index) => {
        const [warranty, setWarranty] = useState(record.productWarranty || record.warranty);

        useEffect(() => {
          setWarranty(record.productWarranty || record.warranty);
        }, [record]);

        return (
          <FormInput
            type={INPUT_TYPE.NUMBER}
            {...{
              formItemOptions: {
                name: [indexConditionProductGroups, index, "productWarranty"],
                validateStatus: warranty > record?.warranty ? "warning" : undefined,
                help: warranty > record?.warranty ? t("campaign.combo.warrantyDateWarning") : undefined,
                rules: [
                  {
                    required: (warranty === null || record?.warranty === null) && true,
                    message: t("campaign.combo.errorMessage.qty"),
                  },
                  validator({
                    type: "number",
                  }),
                ],
              },
              inputOptions: {
                placeholder: record?.warranty || t("campaign.combo.placeholder.type"),
                className: "input-number",
                maxLength: 10,
                // defaultValue: record?.warranty,
                onChange: (value) => setWarranty(value),
              },
            }}
          />
        );
      },
    },
  ];

  const renderCustomComponent = () => {
    if (dataSelected?.length) {
      return (
        <CustomTable
          columns={columnsListItemGift}
          dataSource={dataSelected}
          scroll={{
            y: 240,
          }}
        />
      );
    }
    return (
      <Typography.Link onClick={() => openModalSelectProduct(true)} underline>
        {t("campaign.combo.selectProductCodeGift")}
      </Typography.Link>
    );
  };

  return (
    <div className="custom-modal-select-product">
      <CustomModal
        footer={false}
        centered={true}
        visible={isVisibleQuestionModal}
        title={<Typography.Text style={{ fontSize: "22px" }}>{t("campaign.combo.changeCategory")}</Typography.Text>}
        message={
          <div style={{ textAlign: "justify", marginTop: "-20px" }}>{t("campaign.combo.questionModalCategory")}</div>
        }
        onCancel={handleCancelModalQuestion}
        onOke={() => handleSubmitQuestion()}
      />
      <Card className="custom-card-select-product">
        <CustomModal
          {...{
            message: <Typography.Title level={3}>{t("campaign.combo.addProductToPromotion")}</Typography.Title>,
            footer: false,
            width: "fit-content",
            visible: isModalSelectProduct,
            onOpen: openModalSelectProduct,
            onCancel: closeModalSelectProduct,
            onOke: handleSelectProduct,
            okeButtonLabel: t("campaign.combo.addProduct"),
            children: (
              <>
                <Row
                  gutter={[16, 4]}
                  style={{
                    alignItems: "center",
                    borderRadius: "8px",
                    margin: 0,
                  }}
                >
                  <Col span={6}>
                    <FormInput
                      type={INPUT_TYPE.TREE_SELECT}
                      formItemOptions={{
                        ...FORM_ITEM_STYLE,
                        placeholder: t("product.productForm.placeholderCategory"),
                        label: <Typography.Text strong>{t("product.productForm.category")}</Typography.Text>,
                        // name: 'categoryIDs',
                        style: { marginBottom: 0 },
                      }}
                      inputOptions={{
                        placeholder: t("product.productForm.placeholderCategory"),
                        treeData: dataAllCategories,
                        showSearch: true,
                        allowClear: true,
                        onChange: filterProductByCategoryId,
                        filterTreeNode: (search, item) => handleSearchTreeSelect(search, item),
                      }}
                    />
                  </Col>
                  <Col span={10}>
                    <FormInput
                      formItemOptions={{
                        ...FORM_ITEM_STYLE,
                        label: <Typography.Text strong>{t("campaign.combo.searchAdvance")}</Typography.Text>,
                        style: { marginBottom: 0 },
                      }}
                      inputOptions={{
                        className: "custom-input",
                        prefix: <FontAwesomeIcon icon={Icon.faSearch} />,
                        placeholder: t("product.searchPlaceholder"),
                        allowClear: true,
                        onChange: handleSearch,
                      }}
                    />
                  </Col>
                  <Col span={8} style={{ paddingTop: "30px" }}>
                    <RadioGroup
                      filterItemsTypeOptions={filterItemsTypeOptions}
                      handleChange={handleChange}
                      isUpdate={isUpdate}
                    />
                  </Col>
                  <Col span={24}>
                    <Typography.Text style={{ color: "orange" }}>{t("campaign.combo.notingModal")}</Typography.Text>
                  </Col>
                  <Col span={24}>
                    <TagsList
                      listFilter={params.filters}
                      dataManufactures={dataManufactures}
                      loadingManufactures={loadingManufactures}
                      dataWarehouses={listWarehouse}
                      loadingWarehouses={loadingWarehouses}
                      dataAttributes={prodAtt}
                      params={params}
                      setParams={setParams}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <CustomTable
                      rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                      }}
                      columns={columns}
                      loading={loadingProduct}
                      dataSource={dataSource}
                      rowKey={(record) => record?.id}
                      onChange={onTableChange}
                      pagination={{
                        total: isFilterSelected ? selectedRows?.length : paginationData?.total,
                        pageSize: paginationData?.limit,
                        current: paginationData?.offset / paginationData?.limit + 1 || 1,
                        showSizeChanger: false,
                      }}
                    />
                  </Col>
                </Row>
              </>
            ),
          }}
        />
        {renderCustomComponent()}
      </Card>
    </div>
  );
}

export default ModalSelectGift;
