import { Space, Table, Tag, Typography } from "antd";
import ProductParameter from "components/_storybooks/ProductParameter";
import { FilterLoadMore } from "components/CustomFilterMenu";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import CustomTableSelectRow from "components/CustomTableSelectRow";
import TabsCard from "components/CustomTabs/TabsCard";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import {
  CURRENCY_INPUT,
  PURCHASE_STATUS,
  PURCHASE_TYPE_OPTIONS,
  dataPeriodUnitLabel,
  purchaseRequestTypeLabel,
  purchaseStatusLabel,
  purchaseStatusOptions,
  setColorStatusPurchaseOrder,
  validTabValues,
} from "features/Purchase/constant";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { formatCurrency, formatCurrencyDollar, getQuery } from "utils/helperFuncs";
import Toolbar from "../Toolbar";
import AlertReason from "./AlertReason";
import ContentCardTab from "./ContentCardTab";
import useAccordingPurchaseOrder from "./useAccordingPurchaseOrder";

function AccordingPurchaseOrder() {
  const GET_QUERY = getQuery();

  const {
    tabCardValue,
    countSelectedRowKeys,
    setIsFilterSelected,
    handleSearch,
    handleChangeParams,
    params,
    optionsSeller,
    optionsStaff,
    warehouseOptions,
    isFilterSelected,
    selectedRows,
    purchaseOrderList,
    loadingPurchaseOrderList,
    handleChangeTable,
    selectedHighlight,
    rowSelection,
    sizeScrollPageAll,
    handleRowClick,
    paginationData,
    handleToggleRows,
    isTableSelectedRow,
    selectedRow,
    loadingStatusCount,
    statusCount,
    handleChangeTabsCard,
    onLoadMoreSeller,
    hasMoreSeller,
    handleSearchSellerLoadMore,
    loadingSeller,
    onFilter,
    onLoadMoreStaff,
    hasMoreStaff,
    handleSearchStaffLoadMore,
    loadingStaff,
    handleChangeRadioGroup,
  } = useAccordingPurchaseOrder();

  const tabs = [
    {
      title: (
        <ContentCardTab
          loading={loadingStatusCount}
          quantity={statusCount?.all}
          status={purchaseStatusLabel[`${PURCHASE_STATUS.ALL}`]}
        />
      ),
      key: PURCHASE_STATUS.ALL,
    },
    {
      title: (
        <ContentCardTab
          loading={loadingStatusCount}
          quantity={statusCount?.waitingDeposit}
          status={purchaseStatusLabel[`${PURCHASE_STATUS.WAITING_FOR_DEPOSIT}`]}
        />
      ),
      key: PURCHASE_STATUS.WAITING_FOR_DEPOSIT,
    },
    {
      title: (
        <ContentCardTab
          loading={loadingStatusCount}
          quantity={statusCount?.waitingForReceiving}
          status={purchaseStatusLabel[`${PURCHASE_STATUS.WAITING_FOR_RECEIVING}`]}
        />
      ),
      key: PURCHASE_STATUS.WAITING_FOR_RECEIVING,
    },
    {
      title: (
        <ContentCardTab
          loading={loadingStatusCount}
          quantity={statusCount?.waitingForHandling}
          status={purchaseStatusLabel[`${PURCHASE_STATUS.WAITING_FOR_HANDLING}`]}
        />
      ),
      key: PURCHASE_STATUS.WAITING_FOR_HANDLING,
    },
    {
      title: (
        <ContentCardTab
          loading={loadingStatusCount}
          quantity={statusCount?.waitingForImportStock}
          status={purchaseStatusLabel[`${PURCHASE_STATUS.WAITING_FOR_IMPORT_STOCK}`]}
        />
      ),
      key: PURCHASE_STATUS.WAITING_FOR_IMPORT_STOCK,
    },
    {
      title: (
        <ContentCardTab
          loading={loadingStatusCount}
          quantity={statusCount?.completed}
          status={purchaseStatusLabel[`${PURCHASE_STATUS.COMPLETED}`]}
        />
      ),
      key: PURCHASE_STATUS.COMPLETED,
    },
    {
      title: (
        <ContentCardTab
          loading={loadingStatusCount}
          quantity={statusCount?.canceled}
          status={purchaseStatusLabel[`${PURCHASE_STATUS.CANCELED}`]}
        />
      ),
      key: PURCHASE_STATUS.CANCELED,
    },
  ];

  const columns = [
    ...(!validTabValues.includes(tabCardValue)
      ? [
          {
            title: t("purchase.table.stt"),
            render: (_, record, index) => index + 1,
            align: "center",
            width: 60,
          },
        ]
      : []),
    {
      title: t("purchase.table.createdAt"),
      render: (_, record) => formatDateTime(record.createdAt),
      align: "center",
      width: 100,
      sorter: true,
    },
    {
      title: t("purchase.table.purchaseOrderCode"),
      width: 200,
      render: (_, record) => (
        <Typography.Text strong>
          <Link to={`/purchase/detail/${record?.id}`}>{record?.code}</Link>
        </Typography.Text>
      ),
    },
    {
      title: t("purchase.table.supplier"),
      width: 350,
      render: (_, record) => {
        return <CustomTextHiddenLine text={record?.seller?.fullName} line={2} />;
      },
      filterDropdown: (
        <FilterLoadMore
          onFilter={(values) => onFilter("sellerIDs", values)}
          data={optionsSeller}
          onLoadMoreData={onLoadMoreSeller}
          hasMore={hasMoreSeller}
          onSearchTextChange={handleSearchSellerLoadMore}
          loading={loadingSeller}
        />
      ),
    },
    {
      title: t("purchase.table.totalPayment"),
      align: "right",
      width: 200,
      render: (_, record) => formatCurrency(record?.total),
    },
    {
      title: t("purchase.table.wareHouseImport"),
      width: 120,
      render: (_, record) => record?.warehouse?.name,
      filterDropdown: (
        <FilterMenu
          onFilter={(values) => onFilter("warehouseIDs", values)}
          options={warehouseOptions}
          type="checkbox"
          searchable
        />
      ),
    },
    {
      title: t("purchase.table.recommendedType"),
      width: 120,
      render: (_, record) => purchaseRequestTypeLabel[record?.requestType],
      filterDropdown: (
        <FilterMenu
          onFilter={(values) => onFilter("types", values)}
          options={PURCHASE_TYPE_OPTIONS}
          type="checkbox"
          searchable
        />
      ),
    },
    ...(tabCardValue === PURCHASE_STATUS.ALL
      ? [
          {
            title: t("purchase.table.status"),
            width: 200,
            render: (_, record) => (
              <Tag color={setColorStatusPurchaseOrder(record?.status)}>
                <span style={{ fontSize: "13px" }}>{purchaseStatusLabel[record?.status]}</span>
              </Tag>
            ),
            filterDropdown: (
              <FilterMenu
                onFilter={(values) => onFilter("statuses", values)}
                options={purchaseStatusOptions}
                type="checkbox"
                searchable
              />
            ),
          },
        ]
      : []),
    {
      title: t("purchase.table.createdBy"),
      width: 200,
      render: (_, record) => record?.createdBy?.fullname,
      filterDropdown: (
        <FilterLoadMore
          data={optionsStaff}
          onLoadMoreData={onLoadMoreStaff}
          hasMore={hasMoreStaff}
          onFilter={(value) => onFilter("createdByIDs", value)}
          onSearchTextChange={handleSearchStaffLoadMore}
          loading={loadingStaff}
        />
      ),
    },
  ];

  const columnTableSelected = [
    {
      title: t("purchase.table.stt"),
      render: (_, record, index) => index + 1,
      align: "center",
      width: 60,
      fixed: true,
    },
    {
      title: t("purchase.table.parameter"),
      fixed: true,
      render: (_, record, index) => {
        return <ProductParameter parameter={record?.product} />;
      },
      width: 300,
    },
    {
      title: t("purchase.table.quantity"),
      render: (_, record, index) => record?.quantity,
      align: "center",
      width: 100,
    },
    {
      title: t("purchase.table.amountReceived"),
      render: (_, record, index) => {
        function handleCheckQuantity(receivedQuantity, quantity) {
          if (receivedQuantity > quantity) {
            return (
              <Typography.Text type="danger">
                {t("purchase.table.redundant")} {Math.abs(receivedQuantity - quantity)}
              </Typography.Text>
            );
          } else if (receivedQuantity < quantity) {
            return (
              <Typography.Text type="warning">
                {t("purchase.table.lack")} {Math.abs(receivedQuantity - quantity)}
              </Typography.Text>
            );
          } else {
            return <Typography.Text type="success">{t("purchase.table.enough")}</Typography.Text>;
          }
        }
        return (
          <Space direction="vertical" style={{ alignItems: "center" }}>
            <Typography.Text>{record?.receivedQuantity - (record?.notQualifiedQuantity || 0)}</Typography.Text>
            {GET_QUERY.key === PURCHASE_STATUS.WAITING_FOR_HANDLING &&
              handleCheckQuantity(record?.receivedQuantity - (record?.notQualifiedQuantity || 0), record?.quantity)}
          </Space>
        );
      },
      align: "center",
      width: 150,
    },
    {
      title: t("purchase.table.unitPriceAfterTax"),
      render: (_, record, index) => (
        <Typography.Text>
          {record?.currency === CURRENCY_INPUT.VND
            ? formatCurrency(record?.afterVatPrice)
            : formatCurrencyDollar(record?.afterVatPrice)}
        </Typography.Text>
      ),
      align: "right",
      width: 200,
    },
    {
      title: t("purchase.table.percentVat"),
      render: (_, record, index) => record?.vatPercent,
      align: "center",
      width: 60,
    },
    {
      title: t("purchase.table.unitPriceBeforeTax"),
      render: (_, record, index) => (
        <Typography.Text>
          {record?.currency === CURRENCY_INPUT.VND
            ? formatCurrency(record?.beforeVatPrice)
            : formatCurrencyDollar(record?.beforeVatPrice)}
        </Typography.Text>
      ),
      align: "right",
      width: 200,
    },
    {
      title: t("purchase.table.intoMoney"),
      render: (_, record, index) => (
        <Typography.Text>
          {record?.currency === CURRENCY_INPUT.VND
            ? formatCurrency(record?.subTotal)
            : formatCurrencyDollar(record?.subTotal)}
        </Typography.Text>
      ),
      align: "right",
      width: 200,
    },
    {
      title: t("purchase.table.vatMoney"),
      render: (_, record, index) => (
        <Typography.Text>
          {record?.currency === CURRENCY_INPUT.VND
            ? formatCurrency(record?.vatAmount)
            : formatCurrencyDollar(record?.vatAmount)}
        </Typography.Text>
      ),
      align: "right",
      width: 200,
    },
    {
      title: t("purchase.table.unitPriceAfterDiscount"),
      render: (_, record, index) => (
        <Typography.Text>
          {record?.currency === CURRENCY_INPUT.VND
            ? formatCurrency(record?.afterDiscountPrice)
            : formatCurrencyDollar(record?.afterDiscountPrice)}
        </Typography.Text>
      ),
      align: "right",
      width: 200,
    },
    {
      title: t("purchase.table.warrantyPeriod"),
      render: (_, record, index) => (
        <Space direction="horizontal">
          <Typography.Text>{record?.warranty}</Typography.Text>
          <Typography.Text>{dataPeriodUnitLabel?.[record?.warrantyPeriod]}</Typography.Text>
        </Space>
      ),
      align: "center",
      width: 200,
    },
    {
      title: t("purchase.table.numberDaysDebt"),
      render: (_, record, index) => record?.creditDays,
      align: "center",
      width: 150,
    },
    {
      title: t("purchase.table.expectedDelivery"),
      render: (_, record, index) => formatDateTime(record?.expectedDeliveryTime),
      align: "center",
      width: 200,
    },
  ];

  const renderSummaryTableSelected = (pageData) => {
    var sumReceivedQuantity = pageData?.reduce(function (total, current) {
      return total + current?.receivedQuantity;
    }, 0);
    const stylesSummary = {
      color: "#052987",
      textAlign: "center",
      fontWeight: "bold",
    };

    const colorTotals = { color: "#052987" };

    return (
      <Table.Summary fixed>
        <Table.Summary.Row index={0} style={stylesSummary}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <Typography.Text strong style={colorTotals}>
              {t("purchase.table.total")}
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={1}>
            <Typography.Text style={colorTotals}>{selectedRow?.productQuantity}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={1}>
            <Typography.Text style={colorTotals}>{sumReceivedQuantity}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} colSpan={4}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {selectedRow?.currency === CURRENCY_INPUT.VND
                  ? formatCurrency(selectedRow?.subTotal)
                  : formatCurrencyDollar(selectedRow?.subTotal)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} colSpan={1}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {selectedRow?.currency === CURRENCY_INPUT.VND
                  ? formatCurrency(selectedRow?.totalVatAmount)
                  : formatCurrencyDollar(selectedRow?.totalVatAmount)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} colSpan={4}></Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row index={0} style={stylesSummary}>
          <Table.Summary.Cell index={0} colSpan={2}>
            <Typography.Text strong style={colorTotals}>
              {t("purchase.table.totalPayment")}
            </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={7}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography.Text style={colorTotals}>
                {selectedRow?.currency === CURRENCY_INPUT.VND
                  ? formatCurrency(selectedRow?.total)
                  : formatCurrencyDollar(selectedRow?.total)}
              </Typography.Text>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={4}></Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <div className={"wrapperPurchaseOrder"}>
      <TabsCard tabs={tabs} activeKey={GET_QUERY.key} onChange={(e) => handleChangeTabsCard(e)} />

      <Toolbar
        placeholder={t("purchase.placeHolder.searchByCodePurchaseOrder")}
        titleFilterData={t("purchase.table.createdAt")}
        titleButton={t("purchase.cancelPurchase")}
        showButton={validTabValues.includes(tabCardValue)}
        countSelectedRowKeys={countSelectedRowKeys}
        setIsFilterSelected={setIsFilterSelected}
        isFilterSelected={isFilterSelected}
        onSearch={handleSearch}
        onChangeParams={handleChangeParams}
        params={params}
        optionsSeller={optionsSeller}
        optionsStaff={optionsStaff}
        warehouseOptions={warehouseOptions}
        showButtonGroups={validTabValues.includes(tabCardValue)}
        onChangeRadioGroup={handleChangeRadioGroup}
      />
      <CustomTableSelectRow
        columns={columns}
        data={isFilterSelected ? selectedRows : purchaseOrderList}
        loading={loadingPurchaseOrderList}
        onChangeTable={handleChangeTable}
        selectedHighlight={selectedHighlight}
        rowSelection={
          validTabValues.includes(tabCardValue) && {
            type: "checkbox",
            ...rowSelection,
          }
        }
        scroll={sizeScrollPageAll}
        onClickRow={handleRowClick}
        pagination={
          !isFilterSelected && {
            total: paginationData?.total,
            pageSize: paginationData?.limit,
            current: paginationData?.offset / paginationData?.limit + 1 || 1,
            showSizeChanger: false,
          }
        }
        onToggleArrow={handleToggleRows}
        isTableSelectedRow={isTableSelectedRow}
        reasonComponent={<AlertReason data={selectedRow} />}
        dataTableSelected={selectedRow?.items}
        columnTableSelected={columnTableSelected}
        summaryTableSelected={renderSummaryTableSelected}
        titleCardTableSelected={
          <Space direction="horizontal">
            <Typography.Text strong>{t("purchase.table.listOfProductBy")}</Typography.Text>
            <Typography.Link strong>{selectedRow?.code}</Typography.Link>
          </Space>
        }
      />
    </div>
  );
}

export default AccordingPurchaseOrder;
