import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip, Typography } from "antd";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { VAT_TYPE } from "pages/App/Product/constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

const PriceInfo = ({ form, disabledInput, productGroupData }) => {
  const { t } = useTranslation();

  const defaultVatValue =
    productGroupData?.products[0]?.vatValue !== undefined ? productGroupData?.products[0]?.vatValue : 0;
  const [isTaxableVat, setIsTaxableVat] = useState(productGroupData?.products[0]?.vatType !== VAT_TYPE.NOT_REQUIRED);
  const [isTaxableGapVAT, setIsTaxableGapVAT] = useState(defaultVatValue >= 0);
  const [isTaxablePercentVAT, setIsTaxablePercentVAT] = useState(
    productGroupData?.products?.[0]?.vatPercent !== undefined
      ? [-1, 0, 3, 5, 8, 10].includes(productGroupData?.products?.[0]?.vatPercent)
      : -1
  );

  const inputOptionsPrices = {
    min: 0,
    maxLength: 11,
    style: { width: "100%" },
    addonAfter: <div className="add-on-after">đ</div>,
    disabled: disabledInput,
  };

  const optionVatType = [
    {
      value: VAT_TYPE.REQUIRED,
      label: t("v2.product.drawer_edit_product.export_vat"),
    },
    {
      value: VAT_TYPE.NOT_REQUIRED,
      label: t("v2.product.drawer_edit_product.not_export_vat"),
    },
    {
      value: VAT_TYPE.OPTIONAL,
      label: t("v2.product.drawer_edit_product.optional"),
    },
  ];

  const optionVatPercent = [
    { value: -1, label: t("v2.product.drawer_edit_product.not_taxable") },
    { value: 0, label: "0%" },
    { value: 3, label: "3%" },
    { value: 5, label: "5%" },
    { value: 8, label: "8%" },
    { value: 10, label: "10%" },
    { value: "OTHER", label: "Khác" },
  ];

  const optionTaxable = [
    { value: false, label: t("v2.product.drawer_edit_product.not_taxable") },
    { value: true, label: t("v2.product.drawer_edit_product.taxable") },
  ];

  const handleChangeVatType = (value) => {
    if (value === VAT_TYPE.NOT_REQUIRED) {
      const formData = form.getFieldsValue(true);

      form.setFieldsValue({ ...formData, taxableGapVAT: false, taxablePercentVAT: -1 });
      setIsTaxableVat(false);
      setIsTaxableGapVAT(false);
      setIsTaxablePercentVAT(-1);
    } else {
      setIsTaxableVat(true);
    }
  };

  return (
    <div className="price-info-container">
      <Row justify="start" gutter={32} className="price-info-row">
        <Col span={4}>
          <FormInput
            type={INPUT_TYPE.CURRENCY_INPUT}
            formItemOptions={{
              name: ["productPrices", "importPrice"],
              label: <span className="title">{t("product.productForm.costPrice")}</span>,
            }}
            inputOptions={{
              ...inputOptionsPrices,
              placeholder: t("product.productForm.costPrice"),
            }}
          />
        </Col>
        <Col span={4}>
          <FormInput
            type={INPUT_TYPE.CURRENCY_INPUT}
            formItemOptions={{
              name: ["productPrices", "price"],
              label: <span className="title">Giá chưa VAT</span>,
              rules: [{ required: true, message: "Không được để trống trường này" }],
            }}
            inputOptions={{
              ...inputOptionsPrices,
              placeholder: "Nhập giá chưa bao gồm VAT",
            }}
          />
        </Col>
        <Col span={4}>
          <FormInput
            type={INPUT_TYPE.SELECT}
            formItemOptions={{
              name: "vatType",
              label: <span className="title">{t("v2.product.drawer_edit_product.product_export_vat")}</span>,
              rules: [
                { required: true, message: t("v2.product.drawer_edit_product.please_select_product_vat_exported") },
              ],
            }}
            inputOptions={{
              options: optionVatType,
              defaultValue: VAT_TYPE.REQUIRED,
              onChange: handleChangeVatType,
            }}
          />
        </Col>

        <Col span={6}>
          <Row className="vat-value-group" gutter={12}>
            <Col span={12}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  rules: [
                    {
                      required: true,
                      message: t("common.messageRequiredType", {
                        message: t("product.tableTitle.vatValue"),
                      }),
                    },
                  ],
                  name: "taxableGapVAT",
                  label: (
                    <div>
                      <Typography.Text strong>{t("product.tableTitle.vatValue")}</Typography.Text>{" "}
                      <Tooltip
                        title={
                          <>
                            <span>{t("product.productForm.VATDifferenceTooltipTop")}</span>
                            <br />
                            <span>{t("product.productForm.VATDifferenceTooltipBottom")}</span>
                          </>
                        }
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </div>
                  ),
                }}
                inputOptions={{
                  options: optionTaxable,
                  disabled: !isTaxableVat,
                  onChange: (value) => {
                    setIsTaxableGapVAT(value);
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <FormInput
                type={INPUT_TYPE.CURRENCY_INPUT}
                formItemOptions={{
                  rules: [
                    {
                      required: isTaxableGapVAT,
                      message: "Không được để trống trường này",
                    },
                  ],
                  name: "vatValue",
                  className: "vat",
                }}
                inputOptions={{
                  ...inputOptionsPrices,
                  placeholder: t("product.productForm.VATDifferencePlaceholder"),
                  disabled: !isTaxableGapVAT,
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row className="vat-value-group" gutter={12}>
            <Col span={12}>
              <FormInput
                type={INPUT_TYPE.SELECT}
                formItemOptions={{
                  rules: [
                    {
                      required: true,
                      message: t("common.messageRequiredType", {
                        message: t("product.tableTitle.vatValue"),
                      }),
                    },
                  ],
                  name: "taxablePercentVAT",
                  label: (
                    <div>
                      <Typography.Text strong>Loại % VAT</Typography.Text>{" "}
                      <Tooltip
                        title={
                          <>
                            <span>{t("product.productForm.percentVATTooltipTop")}</span>
                            <br />
                            <span>{t("product.productForm.percentVATTooltipBottom")}</span>
                          </>
                        }
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </div>
                  ),
                }}
                inputOptions={{
                  options: optionVatPercent,
                  disabled: !isTaxableVat,
                  onChange: (value) => {
                    setIsTaxablePercentVAT([-1, 0, 3, 5, 8, 10].includes(value));
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <FormInput
                type={INPUT_TYPE.NUMBER}
                key={isTaxablePercentVAT}
                formItemOptions={{
                  rules: [
                    {
                      required: !isTaxablePercentVAT,
                      message: "Không được để trống trường này",
                    },
                  ],
                  name: "vatPercent",
                  className: "vat",
                }}
                inputOptions={{
                  min: 0,
                  max: 100,
                  addonAfter: <div className="add-on-after">%</div>,
                  placeholder: t("product.productForm.percentVATPlaceholder"),
                  disabled: isTaxablePercentVAT,
                  maxLength: 2,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PriceInfo;
