import { Space, Tooltip, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import ProductParameter from "components/_storybooks/ProductParameter";
import { FilterLoadMore } from "components/CustomFilterMenu";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import WarrantyRequestDetailLink from "components/CustomRedirectLink/WarrantyRequestDetailLink";
import TextAlignLeft from "components/TextAlignLeft";
import { SHIPPING_TYPE } from "config/constants";
import {
  shippingTypeOptions,
  WARRANTY_REQUEST_ITEM_SOLUTION,
  WARRANTY_SERIAL_STATUS,
  warrantyRequestCategoryOptions,
  warrantyRequestItemResultOptions,
  warrantySerialLabel,
  warrantyStatusOptions,
} from "features/Warranty/constant";
import { renderWarrantyStatusLabel } from "features/Warranty/utlis";
import {
  renderItemsStatus,
  renderSolutionLabel,
  renderWarrantyRequestType,
  ResultMethod,
  warrantyResultLabel,
  WRequestSolutionMethod,
} from "features/WarrantyRequest/constant";
import { useGetBranchQuery } from "hooks/branch";
import { useGetInfoPlatform } from "hooks/info-platform";
import useDebounce from "hooks/useDebounce";
import { useGetStaff, useGetSummaryTabs, useGetWarrantySerial } from "hooks/warranty";
import { t } from "i18next";
import { isNil, omit } from "lodash";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { buildQueryString, getQuery } from "utils/helperFuncs";

const ContentCard = ({ quantity, status }) => {
  return (
    <Space direction="vertical" style={{ minWidth: "100px" }}>
      <Typography.Text strong style={{ color: "#052987" }}>
        {quantity}
      </Typography.Text>
      <Typography.Text>{status}</Typography.Text>
    </Space>
  );
};
const SORT_TYPES = {
  ascend: "ASC",
  descend: "DESC",
};

const useAccordingSerial = ({
  onChangeTabStatus,
  selectedRowKeys,
  setSelectedRowKeys,
  setSelectedRows,
  selectedRows,
}) => {
  const GET_QUERY = getQuery();
  const { data: valueFormSelectBrandId } = useGetBranchQuery();
  const [branchID, setbranchID] = useState(null);
  valueFormSelectBrandId?.unshift({ value: null, label: t("warehouse.recommendImport.allBranch") });
  const [params, setParams] = useState({
    activeKey: "accordingSerial",
    key: GET_QUERY.key,
    pagination: { offset: GET_QUERY.offset || 0, limit: GET_QUERY.limit || 10 },
    filters:
      GET_QUERY.key === "ALL"
        ? { query: GET_QUERY.query || undefined }
        : {
            status: [GET_QUERY.key || undefined] || undefined,
            query: GET_QUERY.query || undefined,
            warrantyNoteStatuses: GET_QUERY.warrantyNoteStatuses || undefined,
          },

    sort: [
      {
        by: "CREATED_AT",
        sortType: "DESC",
      },
    ],
  });

  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(paramsStaff);

  const { data, loading, paginationData, refetch } = useGetWarrantySerial(omit(params, ["activeKey", "key"]));
  const { totalItems } = useGetSummaryTabs();

  const [searchTerm, setSearchTerm] = useState(GET_QUERY.query);
  const debounceValue = useDebounce(searchTerm);
  const handleChangeInput = (e) => {
    setSearchTerm(e.target.value);
  };

  const resetSelected = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };

    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: newParams.activeKey,
        key: newParams.key,
      },
    });
  };

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  useEffect(() => {
    if (!isNil(debounceValue)) {
      const newParams = {
        ...params,
        filters: { ...params.filters, query: debounceValue },
        pagination: { offset: 0, limit: 10 },
      };
      setParams(newParams);
      buildQueryString({
        params: {
          ...newParams?.pagination,
          ...newParams?.filters,
          activeKey: newParams.activeKey,
          key: newParams.key,
        },
      });
    }
  }, [debounceValue]);

  const history = useHistory();

  const { platformName } = useGetInfoPlatform();

  const warrantyRequestItemSolutionLabel = {
    [WARRANTY_REQUEST_ITEM_SOLUTION.PROVIDER]: t("warrantyRequest.popup.solutionConfirm1"),
    [WARRANTY_REQUEST_ITEM_SOLUTION.FOREIGN_WARRANTY]: t("warrantyRequest.popup.foreignWarranty"),
    [WARRANTY_REQUEST_ITEM_SOLUTION.THIRD_PARTY]: t("warrantyRequest.popup.solutionConfirm2"),
    [WARRANTY_REQUEST_ITEM_SOLUTION.SELF]: t("warrantyRequest.popup.solutionConfirm3", {
      platformName,
    }),
  };

  const warrantyRequestItemSolutionOptions = [
    {
      value: WARRANTY_REQUEST_ITEM_SOLUTION.PROVIDER,
      label: warrantyRequestItemSolutionLabel[`${WARRANTY_REQUEST_ITEM_SOLUTION.PROVIDER}`],
    },
    {
      value: WARRANTY_REQUEST_ITEM_SOLUTION.THIRD_PARTY,
      label: warrantyRequestItemSolutionLabel[`${WARRANTY_REQUEST_ITEM_SOLUTION.THIRD_PARTY}`],
    },
    {
      value: WARRANTY_REQUEST_ITEM_SOLUTION.SELF,
      label: warrantyRequestItemSolutionLabel[`${WARRANTY_REQUEST_ITEM_SOLUTION.SELF}`],
    },
    {
      value: WARRANTY_REQUEST_ITEM_SOLUTION.FOREIGN_WARRANTY,
      label: warrantyRequestItemSolutionLabel[`${WARRANTY_REQUEST_ITEM_SOLUTION.FOREIGN_WARRANTY}`],
    },
  ];

  const handleClick = (record) => {
    history.push({
      pathname: `/warranty/serial/detail/${record?.id}`,
      state: { id: record?.warrantyRequestID },
    });
  };

  const handleSort = (by) => {
    if (params.sort[0]?.sortType === "ASC") {
      const newParams = {
        ...params,
        sort: [{ by: by, sortType: "DESC" }],
      };
      setParams(newParams);
    } else {
      const newParams = {
        ...params,
        sort: [{ by: by, sortType: "ASC" }],
      };
      setParams(newParams);
    }
  };

  const SHIPPING_METHOD = {
    [`${SHIPPING_TYPE.BUS}`]: t("serial.bus"),
    [`${SHIPPING_TYPE.DELIVER_3PARTY}`]: t("serial.deliver3Party"),
    [`${SHIPPING_TYPE.PICK_UP_COUNTER}`]: t("serial.pickUpCounter"),
    [`${SHIPPING_TYPE.URBAN_COD}`]: t("serial.urbanCOD", {
      platformName,
    }),
    null: "--",
  };

  const all = {
    index: {
      title: t("warranty.warrantyRequestList.indexAccordingSerial"),
      align: "center",
      render: (text, record, index) => index + 1,
      width: 70,
    },
    createdAt: {
      title: (
        <span className="title-table" onClick={() => handleSort("CREATED_AT")}>
          {t("warranty.warrantyRequestList.createdAtAccordingSerial")}{" "}
          <SvgIcon.SortIcon className={params.sort[0]?.sortType} />
        </span>
      ),
      // sorter: true,
      align: "center",
      width: 150,
      render: (_, record) => <div>{formatDateTime(record?.warrantyRequest?.createdAt)}</div>,
    },
    serialIMEI: {
      title: t("warranty.warrantyRequestList.serialIMEIAccordingSerial"),
      align: "center",
      render: (_, record) => {
        return (
          <TextAlignLeft
            value={
              <Typography.Link strong>
                <Link to={`/warranty/serial/detail/${record?.id}`} onClick={() => handleClick(record)}>
                  {record?.serial}
                </Link>
              </Typography.Link>
            }
          />
        );
      },
    },
    productName: {
      title: t("warranty.warrantyRequestList.productNameAccordingSerial"),
      render: (text, record, index) => {
        return <ProductParameter parameter={record?.product} />;
      },
      width: 300,
    },
    codeRequest: {
      title: t("warranty.warrantyRequestList.codeRequestAccordingSerial"),
      align: "center",
      render: (_, record) => <WarrantyRequestDetailLink warrantyRequest={record} />,
    },
    customerRequest: {
      title: t("warranty.warrantyRequestList.customerRequestAccordingSerial"),
      align: "center",
      render: (_, record) => {
        const customerName = record?.warrantyRequest?.warrantyCustomerName;
        return (
          <Tooltip title={customerName}>
            <TextAlignLeft value={customerName} />
          </Tooltip>
        );
      },
    },
    phoneNumber: {
      title: t("warranty.warrantyRequestList.phoneNumberAccordingSerial"),
      align: "center",
      render: (_, record) => record?.warrantyRequest?.warrantyCustomerPhone,
    },
    provider: {
      align: "center",
      title: t("warranty.warrantyRequestList.provider"),
      render: (_, record) => {
        const supplierNames = record?.warrantyNote?.suppierName ?? record?.warrantyNote?.provider?.fullName;
        return <TextAlignLeft value={supplierNames} />;
      },
    },
    staff: {
      title: t("warranty.warrantyRequestList.staffAccordingSerial"),
      filterDropdown: (
        <FilterLoadMore
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          hasMore={hasMoreData}
          params={params}
          onFilter={(value) => handleFilter({ createdByID: value })}
          onSearchTextChange={handleSearchStaffs}
        />
      ),
      align: "center",
      render: (_, record) => record?.warrantyRequest?.createdBy?.fullname,
    },
    status: {
      title: t("warranty.warrantyRequestList.statusAccordingSerial"),
      align: "center",
      render: (_, record) => <Typography.Text>{renderItemsStatus(record?.status)}</Typography.Text>,
    },
    warrantyStatus: {
      title: t("warranty.warrantyRequestList.warrantyStatusAccordingSerial"),
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => handleFilter({ warrantyNoteStatuses: value })}
          type="checkbox"
          options={warrantyStatusOptions}
        />
      ),
      align: "center",
      render: (_, record) => renderWarrantyStatusLabel?.[record?.warrantyNote?.status],
    },
    typeRequest: {
      title: t("warranty.warrantyRequestList.typeRequestAccordingSerial"),
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => handleFilter({ warrantyRequestCategoryFilter: value })}
          type="radio"
          options={warrantyRequestCategoryOptions}
        />
      ),
      align: "center",
      render: (_, record) => {
        return (
          <Typography.Text>
            {record?.warrantyNote?.remainingWarrantyDays <= 0
              ? t("warrantyRequest.detail.requestType.houseoldWarranty")
              : renderWarrantyRequestType(record?.warrantyRequestType)}
          </Typography.Text>
        );
      },
    },
    solution: {
      title: t("warranty.warrantyRequestList.solutionAccordingSerial"),
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => handleFilter({ solutions: value })}
          type="checkbox"
          options={warrantyRequestItemSolutionOptions}
        />
      ),
      render: (_, record) => {
        return <Typography.Text>{renderSolutionLabel(record?.solution, platformName)}</Typography.Text>;
      },
      align: "center",
    },
    methodSendCustomer: {
      title: t("warranty.warrantyRequestList.methodSendCustomerAccordingSerial"),
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => handleFilter({ shippingType: value })}
          type="checkbox"
          options={shippingTypeOptions}
        />
      ),
      render: (_, record) => (
        <div>
          <span>{SHIPPING_METHOD?.[record?.shippingType]}</span> <div>{record?.shippingConfig?.busConfig?.busName}</div>
          <div>{record?.shippingConfig?.deliver3PartyConfig?.provider?.name}</div>
        </div>
      ),
      align: "center",
    },
    partyImplementingWarranty: {
      title: t("warranty.warrantyRequestList.partyImplementingWarranty"),
      align: "center",
      render: (_, record) => {
        return (
          <Typography.Text>
            {record?.receivePartyName ||
              (record?.solution === WRequestSolutionMethod.FOREIGN_WARRANTY &&
                t("warrantyRequest.detail.foreignWarranty")) ||
              (record?.solution === WRequestSolutionMethod.SELF && record?.technicalStaff?.fullname)}
          </Typography.Text>
        );
      },
    },
    resultWarranty: {
      title: t("warranty.warrantyRequestList.resultWarranty"),
      align: "center",
      render: (_, record) => {
        const reImportSerial = `Serial: ${record?.alternativeProductSerial}`;

        const renderReImportSerial = {
          [ResultMethod.EXCHANGE_SIMILAR_PRODUCT]: reImportSerial,
          [ResultMethod.RENEW_SAME_PRODUCT]: reImportSerial,
        };

        return (
          <TextAlignLeft
            value={
              <Typography>
                <Typography.Text>{warrantyResultLabel?.[record?.result]}</Typography.Text>
                <br />
                <Typography.Link italic>{renderReImportSerial?.[record?.result]}</Typography.Link>
              </Typography>
            }
          />
        );
      },
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => handleFilter({ warrantyResults: value })}
          type="checkbox"
          options={warrantyRequestItemResultOptions}
        />
      ),
    },
  };

  const {
    index,
    createdAt,
    serialIMEI,
    productName,
    codeRequest,
    customerRequest,
    phoneNumber,
    staff,
    status,
    warrantyStatus,
    typeRequest,
    solution,
    methodSendCustomer,
    provider,
    partyImplementingWarranty,
    resultWarranty,
  } = all;

  const ALL = [index, createdAt, serialIMEI, productName, codeRequest, customerRequest, phoneNumber, staff, status];

  const WAITING_FOR_CHECK = [
    createdAt,
    serialIMEI,
    productName,
    codeRequest,
    customerRequest,
    phoneNumber,
    staff,
    warrantyStatus,
  ];

  const CHECKING = [createdAt, serialIMEI, productName, codeRequest, customerRequest, phoneNumber, staff, typeRequest];

  const WAITING_FOR_DISTRIBUTE = [
    createdAt,
    serialIMEI,
    productName,
    codeRequest,
    customerRequest,
    provider,
    staff,
    solution,
  ];

  const REPAIRING = [
    createdAt,
    serialIMEI,
    productName,
    codeRequest,
    customerRequest,
    partyImplementingWarranty,
    staff,
    solution,
  ];

  const WAITING_FOR_RETURN = [
    createdAt,
    serialIMEI,
    productName,
    codeRequest,
    customerRequest,
    partyImplementingWarranty,
    staff,
    resultWarranty,
  ];

  const DELIVERING = [
    createdAt,
    serialIMEI,
    productName,
    codeRequest,
    customerRequest,
    resultWarranty,
    staff,
    methodSendCustomer,
  ];

  const COMPLETED = [
    index,
    createdAt,
    serialIMEI,
    productName,
    codeRequest,
    customerRequest,
    resultWarranty,
    staff,
    methodSendCustomer,
  ];

  const CANCELED = [
    index,
    createdAt,
    serialIMEI,
    productName,
    codeRequest,
    customerRequest,
    phoneNumber,
    staff,
    typeRequest,
  ];

  const tabs = [
    {
      title: (
        <ContentCard
          quantity={totalItems?.all}
          status={t("warranty.warrantyRequestList.warrantySerialStatus.allSerial")}
        />
      ),
      key: "ALL",
    },

    {
      title: (
        <ContentCard
          quantity={totalItems?.waitingForCheck}
          status={warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.WAITING_FOR_CHECK}`]}
        />
      ),
      key: WARRANTY_SERIAL_STATUS.WAITING_FOR_CHECK,
    },
    {
      title: (
        <ContentCard
          quantity={totalItems?.checking}
          status={warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.CHECKING}`]}
        />
      ),
      key: WARRANTY_SERIAL_STATUS.CHECKING,
    },
    {
      title: (
        <ContentCard
          quantity={totalItems?.waitingForDistribute}
          status={warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.WAITING_FOR_DISTRIBUTE}`]}
        />
      ),
      key: WARRANTY_SERIAL_STATUS.WAITING_FOR_DISTRIBUTE,
    },
    {
      title: (
        <ContentCard
          quantity={totalItems?.repairing}
          status={warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.REPAIRING}`]}
        />
      ),
      key: WARRANTY_SERIAL_STATUS.REPAIRING,
    },
    {
      title: (
        <ContentCard
          quantity={totalItems?.waitingForReturn}
          status={warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.WAITING_FOR_RETURN}`]}
        />
      ),
      key: WARRANTY_SERIAL_STATUS.WAITING_FOR_RETURN,
    },
    {
      title: (
        <ContentCard
          quantity={totalItems?.delivering}
          status={warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.DELIVERING}`]}
        />
      ),
      key: WARRANTY_SERIAL_STATUS.DELIVERING,
    },
    {
      title: (
        <ContentCard
          quantity={totalItems?.completed}
          status={warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.COMPLETED}`]}
        />
      ),
      key: WARRANTY_SERIAL_STATUS.COMPLETED,
    },
    {
      title: (
        <ContentCard
          quantity={totalItems?.canceled}
          status={warrantySerialLabel[`${WARRANTY_SERIAL_STATUS.CANCELED}`]}
        />
      ),
      key: WARRANTY_SERIAL_STATUS.CANCELED,
    },
  ];
  const handleChangeTab = (value) => {
    onChangeTabStatus && onChangeTabStatus(value);
    resetSelected();
    const newParams = {
      ...params,
      key: value,
      pagination: { limit: 10, offset: 0 },
      filters:
        value !== "ALL" ? { status: [value], branchIDs: GET_QUERY.branchIDs } : { branchIDs: GET_QUERY.branchIDs },
    };
    setParams(newParams);

    buildQueryString({
      params: {
        key: newParams.key,
        ...newParams.pagination,
        activeKey: newParams.activeKey,
        branchIDs: GET_QUERY.branchIDs,
      },
    });
  };

  const renderColumns = {
    ALL: ALL,
    [`${WARRANTY_SERIAL_STATUS.WAITING_FOR_CHECK}`]: WAITING_FOR_CHECK,
    [`${WARRANTY_SERIAL_STATUS.CHECKING}`]: CHECKING,
    [`${WARRANTY_SERIAL_STATUS.WAITING_FOR_DISTRIBUTE}`]: WAITING_FOR_DISTRIBUTE,
    [`${WARRANTY_SERIAL_STATUS.REPAIRING}`]: REPAIRING,
    [`${WARRANTY_SERIAL_STATUS.WAITING_FOR_RETURN}`]: WAITING_FOR_RETURN,
    [`${WARRANTY_SERIAL_STATUS.DELIVERING}`]: DELIVERING,
    [`${WARRANTY_SERIAL_STATUS.COMPLETED}`]: COMPLETED,
    [`${WARRANTY_SERIAL_STATUS.CANCELED}`]: CANCELED,
  };
  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      sort: sorter?.order ? [{ by: "CREATED_AT", sortType: SORT_TYPES[`${sorter?.order}`] }] : [],
    };
    setParams(newParams);

    buildQueryString({
      params: {
        ...newParams?.pagination,
        ...newParams?.filters,
        activeKey: newParams.activeKey,
        key: newParams.key,
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedRowKeys,
    hideSelectAll: true,
    getCheckboxProps: (record) => {
      const condition = (status) => selectedRows?.length >= 1 && record?.status === status;
      if (condition(WARRANTY_SERIAL_STATUS.WAITING_FOR_RETURN)) {
        return {
          disabled: selectedRows[0]?.warrantyRequest?.code !== record?.warrantyRequest?.code,
        };
      }
      if (condition(WARRANTY_SERIAL_STATUS.REPAIRING)) {
        return {
          disabled: selectedRows[0]?.id !== record?.id,
        };
      }
      if (condition(WARRANTY_SERIAL_STATUS.DELIVERING)) {
        return {
          disabled: selectedRows[0]?.id !== record?.id,
        };
      }
    },
  };

  const renderRowSelection = (status) => {
    switch (status) {
      case WARRANTY_SERIAL_STATUS.DELIVERING:
      case WARRANTY_SERIAL_STATUS.WAITING_FOR_RETURN:
      case WARRANTY_SERIAL_STATUS.WAITING_FOR_DISTRIBUTE:
      case WARRANTY_SERIAL_STATUS.CHECKING:
      case WARRANTY_SERIAL_STATUS.WAITING_FOR_CHECK:
      case WARRANTY_SERIAL_STATUS.REPAIRING:
        return { ...rowSelection };
      default:
        return;
    }
  };

  const onChangeBranchesId = (e) => {
    setbranchID(e);
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        branchIDs: e,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.pagination,
        ...newParams?.filters,
        activeKey: newParams.activeKey,
        key: newParams.key,
      },
    });
  };

  useEffect(() => {
    resetSelected();
  }, [totalItems]);

  return {
    columns: renderColumns[`${GET_QUERY.key}`] || renderColumns?.ALL,
    tabs,
    handleChangeTab,
    handleChangeInput,
    data,
    loading,
    paginationData,
    rowSelection: renderRowSelection(GET_QUERY.key),
    handleChangeTable,
    refetch,
    staffs,
    onChangeParams: handleFilter,
    params,
    valueFormSelectBrandId,
    branchID,
    onChangeBranchesId,
  };
};

export default useAccordingSerial;
