import { Space, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import {
  MAXIMUM_CURRENCY,
  MAXIMUM_LENGTH_CURRENCY,
  MAXIMUM_LENGTH_MAX_DAYS_OF_DEBT,
  MAXIMUM_LENGTH_WARRANTY,
  MAXIMUM_QUANTITY,
  MINIMUM_CURRENCY,
  ORDER_TYPE,
  PAYMENT_METHOD,
} from "features/Cart/constants";
import { isMaterial } from "features/Products/helper";
import { useUpdateCartItemV2 } from "hooks/cart";
import { useGetInfoPlatform } from "hooks/info-platform";
import { t } from "i18next";
import { isEmpty, isNil } from "lodash";
import { Link } from "react-router-dom";
import { diffBetweenTwoNumber, formatCurrency, notify } from "utils/helperFuncs";

const platformCodes = {
  HA: "HA",
  C_GROUP: "C_GROUP",
};

const useInfoProductAndBill = ({
  data,
  handleUpdate,
  refetchCart,
  handleUpdateItem,
  handleRemoveCartItem,
  handleVatAllItems,
  useInOrder,
}) => {
  const {
    id: cartID,
    paymentMethod,
    temporaryTotal,
    customerSubTotal,
    totalServiceProduct,
    total,
    customerTotal,
    orderType,
    listProducts,
  } = data;
  const { platformCode } = useGetInfoPlatform();

  const { handleUpdateCartItemV2, loading } = useUpdateCartItemV2();

  const isHidden = orderType === ORDER_TYPE.SELLER;
  const isPaymentMethodByCash = paymentMethod === PAYMENT_METHOD.CASH;

  const calculateDifferencePrice = (firstValue, finalValue) => {
    const percentPrice = diffBetweenTwoNumber(firstValue, finalValue);
    // eslint-disable-next-line eqeqeq
    if (firstValue == 0) {
      return;
    } else {
      if (percentPrice < 0) {
        return <Typography.Text style={{ color: "#d2434d" }}>{percentPrice}%</Typography.Text>;
      } else {
        return <Typography.Text style={{ color: "#00ab78" }}>+{percentPrice}%</Typography.Text>;
      }
    }
  };

  const validWarranty = (_, value) => {
    if (isNil(value)) {
      return Promise.reject(new Error(t("cart.blankProductWarranty")));
    }
    if (value.toString().length > MAXIMUM_LENGTH_WARRANTY) {
      return Promise.reject(new Error(t("cart.maximumProductWarranty")));
    }
    return Promise.resolve();
  };

  const validMaxDaysOfDebt = (_, value) => {
    if (isNil(value)) {
      return Promise.reject(new Error(t("cart.blankMaxDaysOfDebt")));
    }
    if (value.toString().length > MAXIMUM_LENGTH_MAX_DAYS_OF_DEBT) {
      return Promise.reject(new Error(t("cart.maximumDaysOfDebt")));
    }
    return Promise.resolve();
  };

  const validCustomSellerPrice = (_, value) => {
    if (isNil(value)) {
      return Promise.reject(new Error(t("cart.blankCustomSellerPrice")));
    }
    if (value.toString().length > 10) {
      return Promise.reject(new Error(t("cart.maximumCustomSellerPrice")));
    }
    return Promise.resolve();
  };

  const validCustomerPrice = (_, value) => {
    if (isNil(value)) {
      return Promise.reject(new Error(t("cart.blankCustomerPrice")));
    }
    if (value.toString().length > MAXIMUM_LENGTH_CURRENCY) {
      return Promise.reject(new Error(t("cart.maximumCustomerPrice")));
    }
    return Promise.resolve();
  };

  const validDiscountAmount = (_, value) => {
    if (!isNil(value) && value.toString().length > MAXIMUM_LENGTH_CURRENCY) {
      return Promise.reject(new Error(t("cart.maximumCustomSellerPrice")));
    }
    return Promise.resolve();
  };

  const validQuantity = (_, value) => {
    console.log(listProducts);
    // if (value < minOrderQuantity) {
    //   return Promise.reject(new Error("hehe"));
    // }
    return Promise.resolve();
  };

  const columnsProductsInCart = [
    {
      title: t("cart.productInformationColumn"),
      align: "center",
      render: (text, record, index) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography.Link style={{ textAlign: "start", margin: 0 }}>
            <Link to={`/product/detail/${record?.product?.id}`} target="_blank">
              <Typography.Link strong> {record?.product?.code}</Typography.Link>
            </Link>
          </Typography.Link>
          <Typography.Text style={{ textAlign: "start", margin: 0 }} strong>
            {record?.product?.name}
          </Typography.Text>
          <Typography.Text style={{ textAlign: "end", margin: 0 }} italic>
            {record?.product?.uom?.name}
          </Typography.Text>
        </div>
      ),
      isHidden: false,
      fixed: "left",
      width: 250,
    },
    {
      title: (
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <Typography.Text style={{ color: "#5b6673" }}>{t("cart.exportVATColumn")}</Typography.Text>
          {platformCode === platformCodes.HA && (isEmpty(useInOrder) || useInOrder?.changeExportVAT) && (
            <FormInput
              type={INPUT_TYPE.CHECK_BOX}
              formItemOptions={{
                className: "custom-checkbox",
              }}
              inputOptions={{
                checked: data?.vatAllOrder,
                onChange: (e) => handleVatAllItems(e.target.checked),
              }}
            />
          )}
        </Space>
      ),
      align: "center",
      render: (text, record, index) => {
        return (
          <FormInput
            type={INPUT_TYPE.CHECK_BOX}
            formItemOptions={{ name: [index, "vat"], valuePropName: "checked" }}
            inputOptions={{
              onChange: handleUpdateItem,
              checked: true,
              defaultValue: true,
              value: true,
            }}
          />
        );
      },
      isHidden: false,
      width: 100,
    },
    {
      title: t("cart.quantityColumn"),
      width: 100,
      render: (text, record, index) =>
        isEmpty(useInOrder) || useInOrder?.changeProductInCart ? (
          <FormInput
            type={INPUT_TYPE.NUMBER}
            formItemOptions={{
              name: [index, "quantity"],
              ...(isMaterial(record?.product?.productType) && {
                validateStatus:
                  record?.quantity > record?.inStock || record?.quantity < record?.product?.minOrderQuantity
                    ? "warning"
                    : undefined,

                help: (() => {
                  const minOrderQty = record?.product?.minOrderQuantity;
                  const quantity = record?.quantity;
                  const inStock = record?.inStock;

                  if (quantity < minOrderQty && quantity > inStock) {
                    return `Không đủ tồn và không đạt MOQ = ${minOrderQty}`;
                  }
                  if (quantity < minOrderQty) {
                    return `Không đạt mức MOQ = ${minOrderQty}`;
                  }
                  if (quantity > inStock) {
                    return t("cart.lackOfStock");
                  }
                  return undefined;
                })(),
              }),
            }}
            inputOptions={{
              onBlur: handleUpdateItem,
              max: MAXIMUM_QUANTITY,
              min: 1,
            }}
          />
        ) : (
          record?.quantity
        ),
      isHidden: false,
      align: "center",
    },

    {
      title: t("cart.priceColumn"),
      render: (text, record, index) =>
        isEmpty(useInOrder) || useInOrder?.changePrice ? (
          <FormInput
            type={INPUT_TYPE.CURRENCY_INPUT}
            formItemOptions={{
              name: [index, "customSellerPrice"],
              rules: [
                {
                  validator: validCustomSellerPrice,
                },
              ],
            }}
            inputOptions={{
              onBlur: handleUpdateItem,
              // max: MAXIMUM_CURRENCY,
              maxLength: 13,
            }}
          />
        ) : (
          <Typography>{formatCurrency(record?.price)}</Typography>
        ),
      align: "center",
      isHidden: false,
      width: 150,
    },
    {
      title: t("cart.totalColumn"),
      render: (text, record, index) => <p style={{ textAlign: "end", margin: 0 }}>{formatCurrency(record?.total)}</p>,
      align: "center",
      width: 150,
      isHidden: false,
    },
    {
      title: t("cart.customerPriceColumn"),
      render: (text, record, index) =>
        isMaterial(record?.product?.productType) ? (
          (isEmpty(useInOrder) || useInOrder?.changeCustomerPrice) && (
            <>
              <Typography.Text italic style={{ color: "#2246DC" }}>
                {calculateDifferencePrice(record?.price, record?.customerPrice)}
              </Typography.Text>
              <FormInput
                type={INPUT_TYPE.CURRENCY_INPUT}
                formItemOptions={{
                  name: [index, "customerPrice"],
                  rules: [
                    {
                      validator: validCustomerPrice,
                    },
                  ],
                }}
                inputOptions={{
                  onBlur: handleUpdateItem,
                  max: MAXIMUM_CURRENCY,
                }}
              />
            </>
          )
        ) : (
          <Typography style={{ textAlign: "end" }}>{formatCurrency(record?.customerPrice)}</Typography>
        ),
      isHidden: isHidden,
      align: "center",
      width: 150,
    },
    {
      title: t("cart.customerTotalColumn"),
      render: (text, record, index) => (
        <p style={{ textAlign: "end", margin: 0 }}>{formatCurrency(record?.customerTotal)}</p>
      ),
      align: "center",
      width: 150,
      isHidden: isHidden,
    },

    {
      title: (
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <Typography.Text style={{ color: "#5b6673" }}>{t("cart.productWarrantyColumn")}</Typography.Text>
          <Typography.Text style={{ color: "#5b6673" }}>(THÁNG)</Typography.Text>
        </Space>
      ),
      render: (text, record, index) =>
        isMaterial(record?.product?.productType) && (
          <FormInput
            type={INPUT_TYPE.NUMBER}
            formItemOptions={{
              name: [index, "productWarranty"],
              rules: [
                // { required: true, message: 'Vui lòng nhập bảo hành' },
                {
                  validator: validWarranty,
                },
              ],
            }}
            inputOptions={{
              onBlur: handleUpdateItem,
            }}
          />
        ),
      width: 100,
      isHidden: false,
    },
    {
      title: (
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <Typography.Text style={{ color: "#5b6673" }}>{t("cart.maxDaysOfDebtColumn")}</Typography.Text>
          <Typography.Text style={{ color: "#5b6673" }}>(NGÀY)</Typography.Text>
        </Space>
      ),
      render: (text, record, index) =>
        isMaterial(record?.product?.productType) && (
          <FormInput
            type={INPUT_TYPE.NUMBER}
            formItemOptions={{
              name: [index, "maxDaysOfDebt"],
              rules: [
                {
                  validator: validMaxDaysOfDebt,
                },
              ],
            }}
            inputOptions={{
              onBlur: handleUpdateItem,
            }}
          />
        ),
      isHidden: false,
      width: 100,
    },
    {
      title: (
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <Typography.Text style={{ color: "#5b6673" }}>{t("cart.inStockColumn")}</Typography.Text>
        </Space>
      ),
      render: (text, record, index) =>
        isMaterial(record?.product?.productType) && (
          <p style={{ textAlign: "center", margin: 0 }}>{isNil(record?.inStock) ? 0 : record?.inStock}</p>
        ),
      isHidden: false,
      width: 100,
    },
    {
      render: (text, record, index) => (
        <CustomModal
          title={
            <b>
              {t("cart.confirmDeleteItem")} {record?.product?.name} ?
            </b>
          }
          footer={false}
          centered={true}
          onOke={() => handleRemoveCartItem(record?.id)}
          customComponent={<SvgIcon.TrashFullCartIcon />}
          buttonLoading={loading}
        />
      ),
      align: "center",
      isHidden: !(isEmpty(useInOrder) || useInOrder?.changeProductInCart),
      width: 50,
      fixed: "right",
    },
  ];

  const paymentMethodsOptions = [
    { label: t("cart.paymentMethods.cash"), value: isPaymentMethodByCash },
    { label: t("cart.paymentMethods.debt"), value: !isPaymentMethodByCash },
  ];

  const bill = [
    {
      title: t("cart.temporaryTotalLabel"),
      value: <Typography.Text strong>{formatCurrency(temporaryTotal)}</Typography.Text>,
      isHidden: false,
    },
    {
      title: t("cart.customerSubTotalLabel"),
      value: (
        <Typography.Text style={{ color: "#2246DC" }} strong>
          {formatCurrency(customerSubTotal)}
        </Typography.Text>
      ),
      isHidden: isHidden,
    },
    // {
    //   title: t('cart.shippingFeeLabel'),
    //   value: <Typography.Text strong>{formatCurrency(shippingFee)}1</Typography.Text>,
    //   isHidden: isHidden,
    // },
    {
      title: t("cart.totalServiceProductLabel"),
      value: <Typography.Text strong>{formatCurrency(totalServiceProduct)}</Typography.Text>,
      isHidden: false,
    },
    {
      title: t("cart.discountAmountLabel"),
      value:
        isEmpty(useInOrder) || useInOrder?.changeDiscountAmount ? (
          <FormInput
            type={INPUT_TYPE.CURRENCY_INPUT}
            formItemOptions={{
              name: "discountAmount",
              rules: [
                {
                  validator: validDiscountAmount,
                },
              ],
            }}
            inputOptions={{
              onBlur: () => handleUpdate().then(() => refetchCart()),
              max: MAXIMUM_CURRENCY,
              min: MINIMUM_CURRENCY,
            }}
          />
        ) : (
          <Typography.Text strong>{formatCurrency(total)}</Typography.Text>
        ),
      isHidden: false,
    },
    {
      title: t("cart.totalLabel"),
      value: <Typography.Text strong>{formatCurrency(data?.total)}</Typography.Text>,
      isHidden: false,
    },
    {
      title: t("cart.customerTotalLabel"),
      value: (
        <Typography.Text style={{ color: "#2246DC" }} strong>
          {formatCurrency(customerTotal)}
        </Typography.Text>
      ),
      isHidden: isHidden,
    },
  ];

  const onChange = async (value) => {
    try {
      await handleUpdateCartItemV2({
        cartID: cartID,
        request: {
          newItems: [{ productId: value, quantity: 1, vat: false, maxDaysOfDebt: 0, customerPrice: 0 }],
        },
      }).then(() => refetchCart());
    } catch (error) {
      notify.error({ message: error.message });
    }
  };

  const idsProductsInCart = data?.listProducts?.map(({ product }) => product.id);

  return {
    columnsProductsInCart: columnsProductsInCart?.filter((item) => !item?.isHidden),
    paymentMethodsOptions,
    bill,
    onChange,
    isPaymentMethodByCash,
    idsProductsInCart,
  };
};

export default useInfoProductAndBill;
