import CreateButton from "components/CreateButton";
import CustomModal from "components/CustomModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomCard from "../../../CustomCard";
import PriceInfo from "../PriceInfo";
import VariantTable from "./components/VariationTable";

const VariantManage = ({
  form,
  attributes,
  products,
  dataSource,
  setDataSource,
  disabledInputInfo,
  disabledInputPrice,
}) => {
  const { t } = useTranslation();
  const [openInfoPrice, setOpenInfoPrice] = useState(false);

  const handleFormInfoPrice = () => {
    const formData = form.getFieldsValue();
    const { products } = formData;
    const { price, priceVat } = formData?.productPrices;
    const newDataSource = [...dataSource]?.map((item) => ({
      ...item,
      price: price,
      priceVat: priceVat,
      vat: formData?.vat,
      vatPercent: formData?.vatPercent,
    }));

    form.setFieldsValue({
      ...form.getFieldsValue(),
      products: products?.map((item) => ({
        ...item,
        productPrices: {
          price: price,
          priceVat: priceVat,
        },
        vat: formData?.vat,
        vatPercent: formData?.vatPercent,
      })),
    });
    setDataSource([...newDataSource]);

    setOpenInfoPrice(!openInfoPrice);
  };

  const handleOpenModal = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      productPrices: {
        importPrice: undefined,
        price: undefined,
        priceVat: undefined,
      },
      vat: false,
      vatPercent: undefined,
    });
  };

  return (
    <CustomCard title={t("product.productForm.variantManage")}>
      {disabledInputPrice && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "16px" }}>
          <CustomModal
            title={<b>{t("product.productForm.infoPrice")}</b>}
            customComponent={<CreateButton title={t("product.productForm.inputPrices")} onClick={handleOpenModal} />}
            closable={false}
            centered={true}
            footer={false}
            isBlockCloseOnOke
            width="fit-content"
            switchClose={openInfoPrice}
            onOke={() => handleFormInfoPrice()}
            onCancel={() => {
              setOpenInfoPrice(!openInfoPrice);
            }}
            forceRender={true}
            getContainer={false}
          >
            <PriceInfo form={form} />
          </CustomModal>
        </div>
      )}
      <VariantTable
        form={form}
        attributes={attributes}
        products={products}
        dataSource={dataSource}
        setDataSource={setDataSource}
        disabledInputInfo={disabledInputInfo}
        disabledInputPrice={disabledInputPrice}
      />
    </CustomCard>
  );
};

export default VariantManage;
