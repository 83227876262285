import { PRODUCT_TYPE } from "features/Products/constants";
import { useCreateOrder } from "hooks/order";
import { t } from "i18next";
import { isNil } from "lodash";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { notify } from "utils/helperFuncs";

const useModalCreateOrder = ({ data, form }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { handleCreateOrder, loading } = useCreateOrder();
  const history = useHistory();

  const validateCreateOrder = () => {
    const items = data?.items ?? [];
    const sellerActive = data?.seller?.isActive;

    const issues = {
      noItems: items.length === 0,
      outOfStock: items.some(
        (item) =>
          PRODUCT_TYPE.MATERIAL === item.product.productType && (item.quantity > item.inStock || item.inStock === null)
      ),
      outOfMoq: items.some(
        (item) => PRODUCT_TYPE.MATERIAL === item.product.productType && item.quantity < item.product.minOrderQuantity
      ),
    };

    if (issues.noItems) {
      return { title: t("cart.notHasProduct"), hideConfirmButton: true };
    }

    if (issues.outOfStock && issues.outOfMoq) {
      return { title: t("cart.outOfStockAndMoq"), hideConfirmButton: false };
    }

    if (issues.outOfStock) {
      return { title: t("cart.outOfStock"), hideConfirmButton: false };
    }

    if (issues.outOfMoq) {
      return { title: t("cart.outOfMoq"), hideConfirmButton: false };
    }

    if (!sellerActive) {
      return { title: t("cart.inactiveSeller"), hideConfirmButton: true };
    }

    return { title: t("cart.createOrderValidateConfirmation"), hideConfirmButton: false };
  };

  const onOpenCreateOrder = async () => {
    await form.validateFields().then(() => {
      setIsVisible(true);
    });
  };

  const onCancel = () => setIsVisible(false);

  const onCreateOrder = async () => {
    try {
      await handleCreateOrder({ cartID: data?.id }).then((response) => {
        const { id: orderID } = response.data.order.create;
        notify.success({ message: t("cart.createOrderSuccess") });
        history.push({
          pathname: `/order/${orderID}`,
        });
        setIsVisible(false);
      });
    } catch (error) {
      notify.error({ message: error.message });
    }
  };

  return { validateCreateOrder, onOpenCreateOrder, isVisible, onCancel, onCreateOrder, loading };
};

export default useModalCreateOrder;
