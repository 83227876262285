import { gql } from "@apollo/client";

export const GET_LIST_CAMPAIGN_QUERY = gql`
  query GetCampaignList($filters: PromotionCampaignFilters, $pagination: PaginationRequest!, $sort: [PromotionSort!]) {
    promotionCampaign {
      pagination(filters: $filters, pagination: $pagination, sort: $sort) {
        paginationData {
          total
          offset
          limit
        }
        promotionCampaigns {
          id
          name
          startDate
          endDate
          campaignType
          status
          clonePromotionCampaignID
          isActive
          createdByID
          createdBy {
            fullname
          }
          saleChannels {
            id
            name
            type
          }
          appliedCategories {
            id
            code
            name
            isActive
            level
          }
          appliedBrands {
            id
            name
          }
        }
      }
    }
  }
`;
//Nam
export const GET_CAMPAIGN_DETAIL_QUERY = gql`
  query GetCampaignDetail($id: ID!) {
    promotionCampaign {
      get(id: $id) {
        id
        name
        campaignType
        startDate
        endDate
        bannerID
        banner {
          id
          imageURL
          clickURL
        }
        description
        maxOrderCompletedDate
        minTotalOrderValue
        categoryConditionType
        brandConditionType
        clonePromotionCampaignID
        saleChannels {
          id
          name
        }
        appliedCategories {
          id
          code
          name
          isActive
          level
        }
        appliedBrands {
          id
          name
        }
        fullEvents {
          state
          name
          createdAt
          createdByID
          createdBy {
            id
            fullname
          }
        }
        createdAt
        createdByID
        createdBy {
          id
          fullname
        }
      }
    }
  }
`;

export const GET_SELLER_RANKING_LIST = gql`
  query GetSellerRankingList(
    $promotionCampaignID: ID!
    $filters: PromotionSellerFilter
    $sort: PromotionSellerSort
    $pagination: PaginationRequest!
  ) {
    promotionCampaign {
      sellerRankingList(
        promotionCampaignID: $promotionCampaignID
        filters: $filters
        sort: $sort
        pagination: $pagination
      ) {
        lastUpdatedDate
        paginationData {
          total
          offset
          limit
        }
        promotionSellers {
          index
          seller {
            id
            code
            fullName
          }
          amount
          totalOrderCompleted
          totalOrderCreated
        }
      }
    }
  }
`;

export const GET_SUMMARY_TABS = gql`
  query CountCampaign {
    all: promotionCampaign {
      count(filters: { statuses: [] })
    }
    upcoming: promotionCampaign {
      count(filters: { statuses: [UPCOMING] })
    }
    onGoing: promotionCampaign {
      count(filters: { statuses: [ON_GOING] })
    }
    reconciling: promotionCampaign {
      count(filters: { statuses: [RECONCILING] })
    }
    ended: promotionCampaign {
      count(filters: { statuses: [ENDED] })
    }
  }
`;

export const GET_ORDER_IN_PROMO_TABLE = gql`
  query GetOrdersInPromo(
    $promotionCampaignID: ID!
    $filters: OrderPromotionFilter
    $pagination: PaginationRequest!
    $sort: [OrderRankingSort]
  ) {
    promotionCampaign {
      orderRankingList(
        promotionCampaignID: $promotionCampaignID
        filters: $filters
        pagination: $pagination
        sort: $sort
      ) {
        lastUpdatedDate
        ordersRanking {
          id
          createdAt
          orderCode
          orderID
          total
          status
          sellerName
          saleChannel
        }
        paginationData {
          total
          offset
          limit
        }
      }
    }
  }
`;

export const GET_SELLER_NAME_QUERY = gql`
  query GetSellerName($filters: SellersFilters, $pagination: PaginationRequest) {
    seller {
      pagination(filters: $filters, pagination: $pagination) {
        sellers {
          id
          fullName
        }
        paginationData {
          total
          offset
          limit
        }
      }
    }
  }
`;

const GET_MORE_PROMOTION_CAMPAIGN_TIER_FRAGMENT = gql`
  fragment PromotionCampaignTierFragment on PromotionCampaignTier {
    totalQuantity
    usedQuantity
  }
`;

export const GET_DETAIL_PROMOTION_CAMPAIGN = (includeFragment) => gql`
  query GetDetailPromotionCampaign($id: ID!, $warehouseIDs: [ID!], $sellerID: ID, $withCart: Boolean!) {
    promotionCampaign {
      get(id: $id) {
        id
        name
        status
        campaignType
        startDate
        endDate
        saleChannels {
          id
          name
        }
        banner {
          imageURL
          mobileURL
          clickURL
        }
        appliedBrands {
          name
        }
        totalBudget
        totalRemainingBudget
        totalGift
        totalFixedPriceProduct
        highlight
        description
        applyWithOtherPromoTypes
        isActive
        link
        promotionCampaignTierGroups {
          id
          type
          maxQuantityPerSeller
          budgetProductQuantity
          usedProductQuantity
          limitType
          promotionCampaignTiers {
            quantity
            limitType
            type
            discountType
            maxQuantityPerSeller
            minQuantity
            discountType
            discountAmount
            budgetAmount
            fixedComboPrice
            conditionProductGroups {
              type
              quantity
              conditionPromotionProducts {
                type
                budgetQuantity
                productWarranty
                productID
                product {
                  id
                  name
                  categoryID
                  code
                  inStock @skip(if: $withCart)
                  manufacturer {
                    name
                  }
                  vat
                  vatValue
                  note
                  warranty @skip(if: $withCart)
                  productPrices {
                    price
                    priceVat
                  }
                  priceOfSeller(sellerID: $sellerID) {
                    price
                    level
                  }
                  stocks(warehouseIDs: $warehouseIDs) {
                    productID
                    warehouseID
                    quantity
                    warehouse {
                      id
                      code
                      name
                      regionID
                    }
                  }
                  updatedAt @skip(if: $withCart)
                  vatPercent @skip(if: $withCart)
                  fullAttributeValues @skip(if: $withCart) {
                    attributeID
                    attributeValues {
                      id
                      value
                    }
                  }
                  isActive @skip(if: $withCart)
                  productType
                }
              }
            }
            ${includeFragment ? "...PromotionCampaignTierFragment" : ""}
          }
        }
      }
    }
  }
  ${includeFragment ? GET_MORE_PROMOTION_CAMPAIGN_TIER_FRAGMENT : ""}
`;

export const CHECK_DUPLICATE_CODES = gql`
  query checkDuplicateCodes($filters: GetConditionProductFilter) {
    promotionCampaign {
      getConditionPromotionProducts(filters: $filters) {
        productID
        product {
          name
          code
        }
      }
    }
  }
`;
