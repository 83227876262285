import { Badge, Typography } from "antd";

import "./index.scss";

const statusColors = {
  processing: "#052987",
  success: "#32BF46",
  error: "#D2434D",
  warning: "#EF9351",
  grey: "#5B6673",
};

const convertColor = (value) => {
  switch (value) {
    case "processing":
      return "blue";
    case "success":
      return "green";
    case "error":
      return "red";
    case "warning":
      return "orange";
    default:
      return value;
  }
};

function BadgeStatusDot({ status = "processing", showAfter = true, text, ...rest }) {
  const borderColor = statusColors[status] || statusColors.processing;
  const afterDisplay = showAfter ? "block" : "none";
  return (
    <Badge
      status={status}
      color={convertColor(status)}
      className="badge-status-dot-custom"
      style={{ "--border-color": borderColor, "--after-display": afterDisplay }}
      text={<Typography.Text style={{ color: statusColors[status] }}>{text}</Typography.Text>}
      {...rest}
    />
  );
}

export default BadgeStatusDot;
