import { Link } from "react-router-dom";
import EllipsisText from "../EllipsisText";
import SpaceCustom from "../SpaceCustom";

function ProductParameter({ parameter, isCopyAble = false }) {
  const { id, name, code } = parameter || {};

  const safeId = id ?? "unknown-id";
  const safeName = name ?? "Unknown Name";
  const safeCode = code ?? "Unknown Code";

  return (
    <SpaceCustom>
      <Link target="_blank" to={`product/detail/${safeId}`} style={{ display: "inline-block", maxWidth: "100%" }}>
        <EllipsisText
          isLink
          copyable={
            isCopyAble && {
              text: safeName,
            }
          }
          line={1}
          strong
          title={safeCode}
        />
      </Link>
      <EllipsisText line={2} title={safeName} />
    </SpaceCustom>
  );
}

export default ProductParameter;
