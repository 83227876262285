import { Checkbox, Form, Radio, Row, Space, Spin, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { MenuLayout } from "components/CustomFilterMenu";
import FilterFromTo from "components/CustomFilterMenu/FilterFromTo";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import FormInput from "components/FormInput";
import { INPUT_TYPE, PRICE_POLICY_TYPE, REGEX } from "config/constants";
import { useGetManufacturers } from "hooks/manufacturer/manufacturer";
import { has, isNil } from "lodash";
import { SELLING_STATUS_OPTIONS, TAXABLE, VAT_TYPE } from "pages/App/Product/constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./index.scss";

const MIN_PRICE = 0;
const MAX_PRICE = 999999999;
const MIN_VAT_PERCENT = -100;
const MAX_VAT_PERCENT = 100;

const FilterManufacture = ({ onFilter, defaultValue }) => {
  const { loadingManufactures, dataManufactures } = useGetManufacturers();
  function handleFilter(checkedList) {
    onFilter({ manufacturerIDs: checkedList });
  }

  return (
    <Spin spinning={loadingManufactures}>
      <FilterMenu
        options={dataManufactures}
        type="checkbox"
        onFilter={handleFilter}
        defaultValue={defaultValue}
        searchable
      />
    </Spin>
  );
};

const FilterLevelPrice = ({ onFilter, defaultValue }) => {
  const [form] = useForm();
  const [disabled, setDisabled] = useState(defaultValue?.isSpecified ? false : true);
  const { t } = useTranslation();
  const initialValues = {
    isSpecified: undefined,
    range: { from: null, to: null },
    ...defaultValue,
  };

  const priceRadio = [
    { value: undefined, label: t("common.all") },
    { value: false, label: t("product.priceRadio.haveNotPrice") },
    { value: true, label: t("product.priceRadio.havePrice") },
  ];

  function handleValuesChange(changedValues, allValues) {
    const formValues = form.getFieldsValue();
    if (has(changedValues, "isSpecified")) {
      if (changedValues.isSpecified === true) {
        setDisabled(false);
      } else {
        setDisabled(true);
        form.setFieldsValue({ ...formValues, range: { from: null, to: null } });
      }
    }
  }

  function handleResetFilter() {
    form.setFieldsValue({
      isSpecified: undefined,
      range: { from: null, to: null },
    });
    setDisabled(true);
  }

  function handleApplyFilter() {
    const formValues = form.getFieldsValue();
    onFilter(formValues);
  }

  return (
    <MenuLayout
      filterContent={
        <Form form={form} initialValues={initialValues} onValuesChange={handleValuesChange}>
          <FormInput
            type="RADIO_GROUP"
            formItemOptions={{ name: "isSpecified" }}
            inputOptions={{ options: priceRadio }}
          />
          <Space>
            {t("product.inputRange.from")}&nbsp;&nbsp;
            <FormInput
              type="NUMBER"
              formItemOptions={{
                name: ["range", "from"],
              }}
              inputOptions={{
                disabled,
                controls: false,
                min: MIN_PRICE,
                max: MAX_PRICE,

                formatter: (value) => value.replace(REGEX.CURRENCY, "."),
                parser: (value) => value.replace(REGEX.CURRENCY_PARSER, ""),
              }}
            />
            &nbsp;-&nbsp;
            <FormInput
              type="NUMBER"
              formItemOptions={{
                name: ["range", "to"],
              }}
              inputOptions={{
                disabled,
                controls: false,
                min: MIN_PRICE,
                max: MAX_PRICE,
                formatter: (value) => value.replace(REGEX.CURRENCY, "."),
                parser: (value) => value.replace(REGEX.CURRENCY_PARSER, ""),
              }}
            />
          </Space>
        </Form>
      }
      resetFilter={handleResetFilter}
      applyFilter={handleApplyFilter}
    />
  );
};

const FilterRealStocks = ({ onFilter, warehouseID, defaultValue }) => {
  return (
    <FilterFromTo
      onFilter={(value) => {
        if (isNil(value.from) && isNil(value.to)) {
          onFilter({
            warehouseID: warehouseID,
          });
        } else {
          onFilter({
            warehouseID: warehouseID,
            range: value,
          });
        }
      }}
      defaultValue={defaultValue?.range}
    />
  );
};

const FilterMOQ = ({ onFilter, values, defaultValue }) => {
  return (
    <FilterFromTo
      onFilter={(value) => {
        if (isNil(value.from) && isNil(value.to)) {
          onFilter({
            minOrderQuantity: value,
          });
        } else {
          onFilter({
            minOrderQuantity: value,
          });
        }
      }}
      defaultValue={defaultValue?.range}
    />
  );
};

function SupplierStocksFilter({ onFilter, regionID }) {
  const [form] = useForm();

  const [disabledRealStock, setDisabledRealStock] = useState(true);
  const [disabledRealStockVAT, setDisabledRealStockVAT] = useState(true);

  const initialValues = {
    isRealStock: undefined,
    isRealStockVAT: undefined,
    rangeRealStock: { from: undefined, to: undefined },
    rangeRealStockVAT: { from: undefined, to: undefined },
  };

  function handleValuesChange(changedValues, allValues) {
    const formValues = form.getFieldsValue();

    if (allValues.isRealStock) {
      setDisabledRealStock(false);
    } else {
      form.setFieldsValue({ ...formValues, rangeRealStock: { from: undefined, to: undefined } });
      setDisabledRealStock(true);
    }
    if (allValues.isRealStockVAT) {
      setDisabledRealStockVAT(false);
    } else {
      form.setFieldsValue({ ...formValues, rangeRealStockVAT: { from: undefined, to: undefined } });
      setDisabledRealStockVAT(true);
    }
  }

  function handleResetFilter() {
    setDisabledRealStock(true);
    setDisabledRealStockVAT(true);
    form.setFieldsValue({
      isRealStock: undefined,
      isRealStockVAT: undefined,
      rangeRealStock: { from: undefined, to: undefined },
      rangeRealStockVAT: { from: undefined, to: undefined },
      regionID: undefined,
    });
  }

  function handleApplyFilter() {
    const formValues = form.getFieldsValue();
    const { isRealStock, isRealStockVAT, rangeRealStock, rangeRealStockVAT } = formValues;

    if (!isRealStock) {
      form.setFieldsValue({
        isRealStock,
        rangeRealStock: { from: undefined, to: undefined },
      });
      onFilter && onFilter({ regionID, rangeRealStock: { from: undefined, to: undefined } });
    }
    if (!isRealStockVAT) {
      form.setFieldsValue({
        isRealStockVAT,
        rangeRealStockVAT: { from: undefined, to: undefined },
      });
      onFilter && onFilter({ regionID, rangeRealStockVAT: { from: undefined, to: undefined } });
    }
    if (rangeRealStock.from || rangeRealStockVAT.from) onFilter && onFilter({ ...formValues, regionID });
  }

  return (
    <MenuLayout
      filterContent={
        <Form
          form={form}
          style={{ display: "flex", gap: "8px", flexDirection: "column" }}
          initialValues={initialValues}
          onValuesChange={handleValuesChange}
        >
          <div>
            <Form.Item name="isRealStock" valuePropName="checked">
              <Checkbox value="isRealStock">Lọc theo tồn khả dụng</Checkbox>
            </Form.Item>
            <Space>
              <Typography.Text>Từ</Typography.Text>
              <FormInput
                type="NUMBER"
                formItemOptions={{
                  name: ["rangeRealStock", "from"],
                }}
                inputOptions={{ disabled: disabledRealStock, min: MIN_PRICE, max: MAX_PRICE }}
              />
              <Typography.Text>-</Typography.Text>
              <FormInput
                type="NUMBER"
                formItemOptions={{
                  name: ["rangeRealStock", "to"],
                }}
                inputOptions={{ disabled: disabledRealStock, min: MIN_PRICE, max: MAX_PRICE }}
              />
            </Space>
          </div>
          <div>
            <Form.Item name="isRealStockVAT" valuePropName="checked">
              <Checkbox value="isRealStockVAT">Lọc theo tồn VAT</Checkbox>
            </Form.Item>
            <Space>
              <Typography.Text>Từ</Typography.Text>
              <FormInput
                type="NUMBER"
                formItemOptions={{
                  name: ["rangeRealStockVAT", "from"],
                }}
                inputOptions={{ disabled: disabledRealStockVAT, min: MIN_PRICE, max: MAX_PRICE }}
              />
              <Typography.Text>-</Typography.Text>
              <FormInput
                type="NUMBER"
                formItemOptions={{
                  name: ["rangeRealStockVAT", "to"],
                }}
                inputOptions={{ disabled: disabledRealStockVAT, min: MIN_PRICE, max: MAX_PRICE }}
              />
            </Space>
          </div>
        </Form>
      }
      resetFilter={handleResetFilter}
      applyFilter={handleApplyFilter}
    />
  );
}

const FilterExportVAT = ({ onFilter, defaultValue }) => {
  const { t } = useTranslation();

  const vatTypeOptions = [
    { label: t("v2.product.drawer_edit_product.export_vat"), value: VAT_TYPE.REQUIRED },
    { label: t("v2.product.drawer_edit_product.not_export_vat"), value: VAT_TYPE.NOT_REQUIRED },
    { label: t("v2.product.drawer_edit_product.optional"), value: VAT_TYPE.OPTIONAL },
  ];

  return (
    <FilterMenu
      type="checkbox"
      options={vatTypeOptions}
      defaultValue={defaultValue}
      onFilter={(value) => onFilter({ vatTypes: value })}
    />
  );
};

const FilterVAT = ({ onFilter, defaultValue }) => {
  const { t } = useTranslation();

  const vatOptions = [
    { label: t("product.vatCheckbox.yes"), value: true },
    { label: t("product.vatCheckbox.no"), value: false },
  ];

  return (
    <FilterMenu
      type="radio"
      options={vatOptions}
      defaultValue={defaultValue}
      onFilter={(value) => onFilter({ vat: value })}
    />
  );
};

const FilterPricePolicy = ({ onFilter, defaultValue }) => {
  const { t } = useTranslation();

  const pricePolicyOptions = [
    { label: t("product.pricePolicy.no"), value: PRICE_POLICY_TYPE.NO_QUANTITY_DISCOUNT },
    { label: t("product.pricePolicy.yes"), value: PRICE_POLICY_TYPE.QUANTITY_DISCOUNT },
  ];

  return (
    <FilterMenu
      type="radio"
      options={pricePolicyOptions}
      defaultValue={defaultValue}
      onFilter={(value) => onFilter({ pricePolicyType: value })}
    />
  );
};

const FilterInstockVat = ({ onFilter, defaultValue }) => {
  const [form] = useForm();

  const { t } = useTranslation();
  const initialValues = { from: null, to: null, ...defaultValue };
  function handleResetFilter() {
    form.setFieldsValue({ from: null, to: null });
  }

  function handleApplyFilter() {
    const formValues = form.getFieldsValue();
    onFilter({
      realStockHasVat: formValues,
    });
  }

  return (
    <MenuLayout
      filterContent={
        <Form form={form} initialValues={initialValues}>
          <Space>
            {t("product.inputRange.from")}&nbsp;&nbsp;
            <FormInput
              type="NUMBER"
              formItemOptions={{
                name: ["from"],
              }}
              inputOptions={{
                controls: false,
                min: MIN_VAT_PERCENT,
                max: MAX_VAT_PERCENT,
              }}
            />
            &nbsp;-&nbsp;
            <FormInput
              type="NUMBER"
              formItemOptions={{
                name: ["to"],
              }}
              inputOptions={{
                controls: false,
                min: MIN_VAT_PERCENT,
                max: MAX_VAT_PERCENT,
              }}
            />
          </Space>
        </Form>
      }
      resetFilter={handleResetFilter}
      applyFilter={handleApplyFilter}
    />
  );
};

const FilterVatPercent = ({ onFilter, defaultValue }) => {
  const [form] = useForm();
  const [taxableValue, setTaxableValue] = useState(null);
  const { t } = useTranslation();
  const initialValues = { from: null, to: null, ...defaultValue };
  function handleResetFilter() {
    setTaxableValue(null);
    form.setFieldsValue({ from: null, to: null });
  }

  function handleApplyFilter() {
    const formValues = form.getFieldsValue();
    if (taxableValue === "OTHER") {
      onFilter({
        vatPercent: { from: formValues?.from, to: formValues?.from },
      });
    } else {
      onFilter({
        vatPercent: { from: taxableValue, to: taxableValue },
      });
    }
  }

  const taxableOptions = [
    { label: "Không chịu thuế", value: -1 },
    { label: "0%", value: 0 },
    { label: "3%", value: 3 },
    { label: "5%", value: 5 },
    { label: "8%", value: 8 },
    { label: "10%", value: 10 },
    { label: "Khác", value: "OTHER" },
  ];
  const handleTaxableChange = (e) => {
    setTaxableValue(e?.target?.value);
  };

  return (
    <MenuLayout
      style={{ width: "400px" }}
      filterContent={
        <Form form={form} initialValues={initialValues}>
          <FormInput
            type={INPUT_TYPE.RADIO_GROUP}
            formItemOptions={{
              name: "taxable",
              rules: [],
              style: { marginBottom: 0 },
            }}
            inputChildren={
              <Radio.Group value={taxableValue} onChange={(e) => handleTaxableChange(e)}>
                <Space direction="vertical">
                  {taxableOptions?.map((item, index) => (
                    <Radio key={item.value} value={item.value}>
                      {item.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            }
          />
          {taxableValue === "OTHER" && (
            <div>
              <FormInput
                type="NUMBER"
                formItemOptions={{
                  name: ["from"],
                }}
                inputOptions={{
                  controls: false,
                  min: MIN_VAT_PERCENT,
                  max: MAX_VAT_PERCENT,
                  placeholder: t("v2.product.list_product.value"),
                }}
              />
            </div>
          )}
        </Form>
      }
      resetFilter={handleResetFilter}
      applyFilter={handleApplyFilter}
    />
  );
};

const FilterVatValue = ({ onFilter, defaultValue }) => {
  const [form] = useForm();
  const [taxableValue, setTaxableValue] = useState(TAXABLE.ALL);
  const { t } = useTranslation();
  const initialValues = { from: null, to: null, ...defaultValue };
  function handleResetFilter() {
    setTaxableValue(TAXABLE.ALL);
    form.setFieldsValue({ from: null, to: null });
  }

  function handleApplyFilter() {
    const formValues = form.getFieldsValue();
    if (taxableValue === TAXABLE.ALL) {
      onFilter({
        vatValue: { from: -1, to: null },
      });
    } else if (taxableValue === TAXABLE.TAXABLE) {
      onFilter({
        vatValue: { from: formValues.from, to: formValues.to },
      });
    } else if (taxableValue === TAXABLE.NOT_TAXABLE) {
      onFilter({
        vatValue: { from: -1, to: -1 },
      });
    }
  }

  const taxableOptions = [
    { label: t("v2.product.list_product.all"), value: TAXABLE.ALL },
    { label: t("v2.product.drawer_edit_product.taxable"), value: TAXABLE.TAXABLE },
    { label: t("v2.product.drawer_edit_product.not_taxable"), value: TAXABLE.NOT_TAXABLE },
  ];
  const handleTaxableChange = (e) => {
    setTaxableValue(e?.target?.value);
  };

  return (
    <MenuLayout
      style={{ width: "400px" }}
      filterContent={
        <Form form={form} initialValues={initialValues}>
          <FormInput
            type={INPUT_TYPE.RADIO_GROUP}
            formItemOptions={{
              name: "taxable",
              rules: [],
              style: { marginBottom: 0 },
            }}
            inputChildren={
              <Row direction="horizontal" style={{ justifyContent: "space-between" }}>
                <Radio.Group value={taxableValue} onChange={(e) => handleTaxableChange(e)}>
                  {taxableOptions?.map((item, index) => (
                    <Radio key={item.value} value={item.value}>
                      {item.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Row>
            }
          />

          <Space>
            {t("product.inputRange.from")}&nbsp;&nbsp;
            <FormInput
              type={INPUT_TYPE.CURRENCY_INPUT}
              formItemOptions={{
                name: ["from"],
              }}
              inputOptions={{
                min: 0,
                maxLength: 11,
                style: { width: "100%" },
                addonAfter: <div className="add-on-after">đ</div>,
                placeholder: t("v2.product.list_product.value"),
                disabled: !(taxableValue === TAXABLE.TAXABLE),
              }}
            />
            &nbsp;-&nbsp;
            <FormInput
              type={INPUT_TYPE.CURRENCY_INPUT}
              formItemOptions={{
                name: ["to"],
              }}
              inputOptions={{
                min: 0,
                maxLength: 11,
                style: { width: "100%" },
                addonAfter: <div className="add-on-after">đ</div>,
                placeholder: t("v2.product.list_product.value"),
                disabled: !(taxableValue === TAXABLE.TAXABLE),
              }}
            />
          </Space>
        </Form>
      }
      resetFilter={handleResetFilter}
      applyFilter={handleApplyFilter}
    />
  );
};

const FilterAttributes = ({ onFilter, attributeId, attributeList = [], defaultValue }) => {
  const handleFilter = (checkedList) => {
    onFilter({ attributeID: attributeId, valueIDs: checkedList });
  };

  const convertedOptions = attributeList?.map((item) => ({ label: item.value, value: item.id }));

  return (
    <FilterMenu
      options={convertedOptions}
      type="checkbox"
      onFilter={handleFilter}
      defaultValue={defaultValue}
      searchable
    />
  );
};

const FilterPromotions = () => {
  const promotionsOptions = [
    { label: "Đua top", value: "" },
    { label: "Khuyến mãi theo sản phẩm", value: "" },
  ];
  const handleFilter = (checkedList) => {
    // onFilter({ attributeID: attributeId, valueIDs: checkedList });
  };

  // const convertedOptions = attributeList?.map((item) => ({ label: item.value, value: item.id }));

  return (
    <FilterMenu
      options={promotionsOptions}
      type="checkbox"
      onFilter={handleFilter}
      // defaultValue={defaultValue}
      // searchable
    />
  );
};

const FilterStatus = ({ onFilter, defaultValue }) => {
  return (
    <FilterMenu
      options={SELLING_STATUS_OPTIONS}
      type="checkbox"
      defaultValue={defaultValue}
      onFilter={(value) => onFilter({ sellingStatuses: value })}
    />
  );
};

export {
  FilterAttributes,
  FilterExportVAT,
  FilterInstockVat,
  FilterLevelPrice,
  FilterManufacture,
  FilterPricePolicy,
  FilterPromotions,
  FilterRealStocks,
  FilterStatus,
  SupplierStocksFilter as FilterStocksSupplier,
  FilterVAT,
  FilterVatPercent,
  FilterVatValue,
  FilterMOQ,
};
