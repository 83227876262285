import { Affix, Col, Form, Popconfirm, Row, Space, Spin, Tooltip, Typography } from "antd";
import { t } from "i18next";
import { uniq } from "lodash";
import { useState } from "react";

import { SvgIcon } from "assets/icons";
import CommonCard from "components/CommonCard";
import CreateButton from "components/CreateButton";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import ProductParameter from "components/_storybooks/ProductParameter";
import { INPUT_TYPE, REGEX } from "config/constants";
import Card from "features/Cart/components/Card";
import ModalEditNotOwner from "features/WarrantyRequest/Create/ModalEditNotOwner";
import ModalEditOwner from "features/WarrantyRequest/Create/ModalEditOwner";
import ViewProduct from "features/WarrantyRequest/components/ViewProduct";
import { WarrantyReceiveMethod } from "features/WarrantyRequest/constant";
import { useGetInfoPlatform } from "hooks/info-platform";
import { useGetSellerCreateCart } from "hooks/seller";
import { Link } from "react-router-dom";
import SelectProduct from "../components/SelectProductWarranty";
import styles from "../index.module.scss";
import useCreate from "./useCreate";

function ModalEditWarrantyRequestItem({ warrantyRequestItem, onSubmit }) {
  return warrantyRequestItem?.code ? (
    <ModalEditOwner warrantyRequestItem={warrantyRequestItem} onSubmit={onSubmit} />
  ) : (
    <ModalEditNotOwner onSubmit={onSubmit} record={warrantyRequestItem} />
  );
}

function WarrantyRequestCreate() {
  const {
    form,
    initialValues,
    createWarrantyRequest,
    loading,
    branchQuery,
    handleCancel,
    pendingWarrantyNoteLoading,
    handleRemoveSerial,
    handleSubmitWarrantyRequestItem,
    warrantyRequestItems,
    setWarrantyRequestItems,
    setActiveSerial,
    activeSerial,
    serialToWarrantyRequestItem,
  } = useCreate();
  const { platformName } = useGetInfoPlatform();
  const optionsReception = [
    {
      value: WarrantyReceiveMethod.BUS,
      label: t("warrantyRequest.create.shippingType.bus"),
    },
    {
      value: WarrantyReceiveMethod.SHIPPING_PROVIDER,
      label: t("warrantyRequest.create.shippingType.shippingProvider"),
    },
    {
      value: WarrantyReceiveMethod.CUSTOMER_BRING,
      label: t("warrantyRequest.create.shippingType.pickUp"),
    },
    {
      value: WarrantyReceiveMethod.SHIPPER,
      label: t("warrantyRequest.create.shippingType.shipper", { platformName }),
    },
  ];

  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: null, isActive: true, isSeller: true },
    pagination: { offset: 0, limit: 10 },
  });

  const { sellers, loading: loadingSeller } = useGetSellerCreateCart(paramsSeller, "cache-first");

  const onSearchSeller = (valueSearch) => {
    setParamsSeller({
      filters: { ...paramsSeller.filters, query: valueSearch || null, isSeller: true },
      pagination: { offset: 0, limit: 10 },
    });
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      warrantyCustomerName: valueSearch || undefined,
    });
  };
  const sellerOptions = sellers?.map((item) => ({
    value: item?.value,
    label: (
      <Row gutter={[16]}>
        <Col span={5} className={styles["item"]} style={{ padding: "5px 0 0 15px", textAlign: "left" }}>
          <Typography.Link strong>{item?.code}</Typography.Link>
        </Col>
        <Col span={7} className={styles["item"]} style={{ paddingTop: "5px", textAlign: "left" }}>
          <Tooltip title={item?.sellername}>
            <Typography.Text ellipsis>{item?.sellername}</Typography.Text>
          </Tooltip>
        </Col>
        <Col span={4} style={{ paddingTop: "5px", textAlign: "center" }}>
          <Typography.Text>{item?.telephone}</Typography.Text>
        </Col>
        <Col span={8} style={{ paddingTop: "5px", textAlign: "left" }}>
          <Tooltip title={item?.address}>
            <Typography.Text ellipsis>{item?.address}</Typography.Text>
          </Tooltip>
        </Col>
      </Row>
    ),
    seller: item,
    labelSeller: item?.sellername,
  }));
  const handleChangeSeller = (value, option) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      warrantyCustomerAddress: option?.seller?.address,
      warrantyCustomerPhone: option?.seller?.telephone,
      warrantyCustomerName: option?.seller?.sellername,
    });
  };

  const formFields = [
    {
      ...{
        type: INPUT_TYPE.AUTO_COMPLETE,
        formItemOptions: {
          label: <Typography.Text strong>{t("warrantyRequest.create.fullName")}</Typography.Text>,
          name: "warrantyCustomerName",
          rules: [{ required: true, message: t("common.validateRequired") }],
        },
        inputOptions: {
          placeholder: t("warrantyRequest.create.placeHolder.fullName"),
          maxLength: 255,
          allowClear: true,
          options: sellerOptions,
          dropdownMatchSelectWidth: 1000,
          optionLabelProp: "labelSeller",
          onSearch: onSearchSeller,
          onChange: (value, option) => handleChangeSeller(value, option),
          defaultActiveFirstOption: false,
          dropdownRender: (originNode) => (
            <>
              <Row gutter={16} className={styles["custom-dropdown"]}>
                <Col span={4} className={styles["item"]} style={{ padding: "10px 0 0 20px", textAlign: "center" }}>
                  <Typography.Text strong>{t("cart.agentCode")}</Typography.Text>
                </Col>
                <Col span={7} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "center" }}>
                  <Typography.Text strong>{t("cart.agentName")}</Typography.Text>
                </Col>
                <Col span={5} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "center" }}>
                  <Typography.Text strong>{t("supplier.table.phoneNumber")}</Typography.Text>
                </Col>
                <Col span={8} className={styles["item"]} style={{ paddingTop: "10px", textAlign: "center" }}>
                  <Typography.Text strong>{t("supplier.table.address")}</Typography.Text>
                </Col>
              </Row>
              <Spin spinning={loadingSeller}>{originNode}</Spin>
            </>
          ),
          dropdownStyle: { zIndex: "1000" },
        },
      },
    },
    {
      ...{
        type: INPUT_TYPE.PHONE_NUMBER_INPUT,
        formItemOptions: {
          label: <Typography.Text strong>{t("warrantyRequest.create.phoneNumber")}</Typography.Text>,
          name: "warrantyCustomerPhone",
          rules: [
            { required: true, message: t("seller.validateMessage.telephone") },
            {
              pattern: REGEX.PHONE,
              message: t("common.wrongPhone"),
            },
          ],
        },
        inputOptions: {
          placeholder: t("warrantyRequest.create.placeHolder.phoneNumber"),
        },
      },
    },
    {
      ...{
        formItemOptions: {
          label: <Typography.Text strong>{t("warrantyRequest.create.address")}</Typography.Text>,
          name: "warrantyCustomerAddress",
          rules: [{ required: true, message: t("common.validateRequired") }],
        },
        inputOptions: {
          placeholder: t("warrantyRequest.create.placeHolder.address"),
          maxLength: 255,
        },
      },
    },
    {
      ...{
        type: INPUT_TYPE.SELECT,
        formItemOptions: {
          label: <Typography.Text strong>{t("warrantyRequest.create.receptionType")}</Typography.Text>,
          name: "warrantyReceiveMethod",
          rules: [{ required: true, message: t("common.validateRequired") }],
        },
        inputOptions: {
          placeholder: t("warrantyRequest.create.placeHolder.receptionType"),
          options: optionsReception,
        },
      },
    },
  ];

  const columns = [
    {
      render: (_, record) => (
        <ModalEditWarrantyRequestItem warrantyRequestItem={record} onSubmit={handleSubmitWarrantyRequestItem} />
      ),
    },
    {
      title: <Typography.Text>{t("warrantyRequest.create.codeWarranty")}</Typography.Text>,
      dataIndex: "code",
      render: (_, record) => (
        <Typography.Text style={{ width: "50" }} className={styles["color-content"]}>
          <Link to={`/warranty-detail/${record?.id}`}>
            <Typography.Link ellipsis={{ expandable: true, tooltip: true }} strong>
              {record?.code}
            </Typography.Link>
          </Link>
        </Typography.Text>
      ),
    },
    {
      title: <Typography.Text>{t("warrantyRequest.create.serialIMEI")}</Typography.Text>,
      dataIndex: "serialImei",
    },
    {
      title: <Typography.Text>{t("warrantyRequest.create.detail")}</Typography.Text>,
      render: (_, record) => {
        return <ProductParameter parameter={record?.product} />;
      },
    },
    {
      render: (text, record, index) => {
        const title = (
          <Typography.Text>
            {`${t("cart.confirmDeleteItem")}`} <Typography.Link strong>{record?.serialImei}</Typography.Link> ?
          </Typography.Text>
        );
        return (
          <Popconfirm title={title} onConfirm={() => handleRemoveSerial(record?.id)}>
            <SvgIcon.TrashFullCartIcon />
          </Popconfirm>
        );
      },
    },
  ];

  function handleSerialSelected(serial) {
    const exists = warrantyRequestItems.some((item) => item.id === serial.id);
    if (!exists) {
      setWarrantyRequestItems(uniq([...warrantyRequestItems, serial]));
    }
  }
  return (
    <Form initialValues={initialValues} form={form} layout="vertical" onFinish={createWarrantyRequest}>
      <Row className={styles["form-warranty-request"]} gutter={[16, 16]}>
        <Col span={24}>
          <PageHeader
            pageTitle={t("warrantyRequest.create.title2")}
            routes={[
              { path: "/warranty-request", name: t("common.warranty") },
              { path: "/warranty-request", name: t("warrantyRequest.create.title1") },
              { path: "", name: t("warrantyRequest.create.title2") },
            ]}
          />
        </Col>

        {/* Section 1 */}
        <Col span={24}>
          <Card
            title={
              <Row gutter={[16]}>
                <Col>
                  <Typography.Text className={styles["title-card"]}>
                    {t("warrantyRequest.create.codeWarrantyRequest")}
                  </Typography.Text>
                </Col>
              </Row>
            }
          >
            <Row gutter={50}>
              <Col span={24} xl={16} style={{ borderRight: "1px dashed #9CADC4" }}>
                <Row gutter={[50, 16]}>
                  <Col span={24} className={styles["sub-title"]}>
                    <Typography.Text className={styles["color-title"]}>
                      {t("warrantyRequest.create.customerRequest")}
                    </Typography.Text>
                  </Col>
                  {formFields?.map((field, index) => {
                    return (
                      <Col span={24} xl={12} key={index}>
                        <FormInput {...field} />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col span={24} xl={8}>
                <Row gutter={[50, 16]}>
                  <Col span={24} className={styles["sub-title"]}>
                    <Typography.Text className={styles["color-title"]}>
                      {t("warrantyRequest.create.addInfomartion")}
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <FormInput
                      {...{
                        type: INPUT_TYPE.SELECT,
                        formItemOptions: {
                          label: <Typography.Text strong>{t("warrantyRequest.create.branch")}</Typography.Text>,
                          name: "branchID",
                          rules: [
                            {
                              required: true,
                              message: t("common.validateRequired"),
                            },
                          ],
                        },
                        inputOptions: {
                          placeholder: t("warrantyRequest.create.placeHolder.branch"),
                          options: branchQuery,
                        },
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <FormInput
                      {...{
                        type: INPUT_TYPE.TEXT_AREA,
                        formItemOptions: {
                          label: <Typography.Text strong>{t("warrantyRequest.create.note")}</Typography.Text>,
                          name: "note",
                        },
                        inputOptions: {
                          placeholder: t("warrantyRequest.create.placeHolder.note"),
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        {/* Section 2 */}
        <Col span={24} xl={12}>
          <CommonCard style={{ padding: "0 10px" }}>
            <Row gutter={[16, 16]}>
              <Col span={24} className={styles["sub-title"]}>
                <Typography.Text className={styles["color-title"]}>
                  {t("warrantyRequest.create.warRequestInfo")}
                </Typography.Text>
              </Col>
              <Col span={24} className={styles["input-search"]}>
                <SelectProduct
                  onSerialSelected={handleSerialSelected}
                  onSubmitNewProduct={handleSubmitWarrantyRequestItem}
                />
              </Col>
              <Col span={24} className={styles["width-cell"]}>
                <CustomTable
                  loading={pendingWarrantyNoteLoading}
                  columns={columns}
                  dataSource={warrantyRequestItems}
                  rowKey={(record) => record?.serialImei}
                  onRow={(record) => {
                    return {
                      onClick: (event) => {
                        setActiveSerial(record.serialImei);
                      }, // click row
                    };
                  }}
                  rowClassName={(record, index) =>
                    record?.serialImei === serialToWarrantyRequestItem[activeSerial]?.serialImei ? "highlight" : ""
                  }
                />
              </Col>
              <Col span={24}>
                <Space className={styles["space"]}>
                  <Row gutter={[10]}>
                    <Col>
                      <Typography.Text strong className={styles["color-content"]}>
                        {t("warrantyRequest.create.totalItem")}:
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text strong className={styles["color-content"]}>
                        {warrantyRequestItems?.length < 10
                          ? `0${warrantyRequestItems?.length}`
                          : warrantyRequestItems?.length}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Space>
              </Col>
            </Row>
          </CommonCard>
        </Col>
        {/* Section 3 */}
        <Col span={24} xl={12}>
          <CommonCard style={{ padding: "0 10px" }}>
            <Row gutter={[16, 16]}>
              <Col span={24} className={styles["sub-title"]}>
                <Typography.Text className={styles["color-title"]}>
                  {t("warrantyRequest.create.serialInfo")}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <ViewProduct dataRow={activeSerial && serialToWarrantyRequestItem[activeSerial]} />
              </Col>
            </Row>
          </CommonCard>
        </Col>
        {/* Button Group */}
        <Col span={24}>
          <Affix offsetBottom={10}>
            <Space className={styles["space"]}>
              <CustomModal
                {...{
                  okeButtonLabel: t("common.confirm"),
                  customComponent: (
                    <CreateButton
                      style={{ background: "#fff", color: "#4077e6" }}
                      title={t("warrantyRequest.create.btnCancel")}
                    />
                  ),
                  centered: true,
                  footer: false,
                  message: t("warrantyRequest.modal.cancel"),
                  onOke: handleCancel,
                }}
              />
              <CreateButton
                disabled={warrantyRequestItems?.length ? false : true}
                title={t("warrantyRequest.create.btnCreate")}
                htmlType="submit"
                loading={loading}
              />
            </Space>
          </Affix>
        </Col>
      </Row>
    </Form>
  );
}

export default WarrantyRequestCreate;
