import { Form } from "antd";
import { COMPANY_TYPE } from "features/Agent/constants";
import { useCreateSupplier, useUpdateSupplier } from "hooks/supplier";
import { useUpload } from "hooks/upload";
import { t } from "i18next";
import { trim } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { joinArray, notify, readFile, removeWhiteSpace } from "utils/helperFuncs";
import { PAYMENT_METHOD, SUPPLIER_TYPE } from "../constant";

function useSupplierCreate({ isUpdate, supplierDetail, loadingDetail }) {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [typeSupplier, setTypeSupplier] = useState(SUPPLIER_TYPE.COMPANY);
  const [isSeller, setIsSeller] = useState(false);
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.DEBT);
  const { handleCreateSupplier, loadingCreate } = useCreateSupplier();
  const { handleUpdateSupplier, loadingUpdateSupplier } = useUpdateSupplier();

  let dataBankAccountsServer = [];
  if (supplierDetail?.bankAccounts) {
    dataBankAccountsServer = supplierDetail?.bankAccounts;
  }

  const [bankAccounts, setBankAccounts] = useState([]);
  const convertBankAccounts = (values) => {
    return values?.map((item) => ({
      bankID: item?.bankID,
      ownerBankAccountName: item?.ownerBankAccountName || item?.name,
      bankAccountNumber: item?.bankAccountNumber,
      bankBranch: item?.bankBranch,
    }));
  };

  const handleGetBankAccounts = (values) => {
    setBankAccounts([...values]);
  };

  const [location, setLocation] = useState({
    cityId: null,
    districtId: null,
  });

  const [avatar, setAvatar] = useState({
    base64String: "",
    src: "",
  });

  const handleValuesChange = (valueChange, allValues) => {
    if (valueChange?.paymentMethod) {
      setPaymentMethod(valueChange?.paymentMethod);
    }

    setTypeSupplier(allValues?.type);
    setIsSeller(allValues?.isSeller);
  };

  function initForm() {
    if (isUpdate) {
      const formValues = {
        ...supplierDetail?.vatInfo,
        ...supplierDetail,
        companyAddress: supplierDetail?.vatInfo?.address,
        company_email: supplierDetail?.vatInfo?.email,
        legal_representative_phone: supplierDetail?.vatInfo?.telephone,
        warehouseIDs: supplierDetail?.warehouses?.map(({ id }) => id),
        contactName: supplierDetail?.representName,
        contactPhone: supplierDetail?.representPhone,
        contactEmail: supplierDetail?.representEmail,
        paymentMethod: supplierDetail?.paymentMethod || PAYMENT_METHOD.DEBT,
        debtLimit: supplierDetail?.debtLimit || undefined,
        debtAge: supplierDetail?.debtAge ?? undefined,
        sellerGroupID: supplierDetail?.sellerGroupID
          ? joinArray([supplierDetail?.sellerGroup?.code, supplierDetail?.sellerGroup?.name], " - ")
          : undefined,
      };

      form.setFieldsValue({
        ...formValues,
      });

      setLocation({
        ...location,
        cityId: supplierDetail?.cityID,
        districtId: supplierDetail?.districtID,
      });
    } else {
      const formValues = {
        type: COMPANY_TYPE.COMPANY,
        isSeller: false,
        paymentMethod: PAYMENT_METHOD.DEBT,
      };

      form.setFieldsValue({
        ...formValues,
      });
    }
  }

  function handleChangeCity(cityId) {
    form.setFieldsValue({
      districtID: null,
      wardID: null,
    });
    setLocation({
      ...location,
      cityId,
      districtId: null,
    });
  }

  function handleChangeDistrict(districtId) {
    form.setFieldsValue({
      wardID: null,
    });
    setLocation({
      ...location,
      districtId,
    });
  }

  const history = useHistory();

  const clearFields = () => {
    form.resetFields();
    form.setFieldsValue({
      type: typeSupplier,
      isSeller: isSeller,
      paymentMethod: paymentMethod,
    });
  };
  const [visible, setVisible] = useState(false);

  const handleOpenModalSave = () => {
    setVisible(true);
  };

  const handleCloseModalSave = () => {
    setVisible(false);
  };

  const handleFinish = async () => {
    if (isSeller === true) {
      try {
        handleCloseModalSave();
        handleOpenModalSave();
      } catch (errorInfo) {
        console.info("Form validation failed:", errorInfo);
      }
    } else {
      handleOpenModalSave();
    }
  };

  const handleSubmit = async (isDetail, isRedirect) => {
    const values = await form.validateFields();
    const {
      fullName,
      email,
      address,
      bankAccountNumber,
      bankBranch,
      bankID,
      cityID,
      company_email,
      debtAge,
      debtLimit,
      districtID,
      idNumber,
      legal_representative_phone,
      representative,
      companyAddress,
      sellerLevelID,
      shortName,
      taxIDNumber,
      telephone,
      wardID,
      warehouseIDs,
      type,
      paymentMethod,
      contactName,
      contactEmail,
      contactPhone,
      isSeller,
      isSupplier = true,
      defaultExportVat,
      allowEditVat,
    } = values;

    const payload = {
      sellerID: isUpdate && id,
      sellerInfo: {
        idNumber,
        type: type || COMPANY_TYPE.COMPANY,
        logoUrl: avatar?.src || supplierDetail?.logoUrl,
        shortName,
        debtAge: paymentMethod === PAYMENT_METHOD.DEBT ? debtAge : undefined,
        debtLimit: paymentMethod === PAYMENT_METHOD.DEBT ? debtLimit : undefined,
        sellerLevelID,
        fullName,
        email: email || undefined,
        address: trim(address),
        cityID,
        districtID,
        wardID,
        telephone,
        paymentMethod,
        representName: contactName || null,
        representEmail: contactEmail || null,
        representPhone: contactPhone || null,
        isSeller,
        isSupplier,
        sellerGroupID:
          [supplierDetail?.sellerGroup?.code, supplierDetail?.sellerGroup?.name].join(" - ") === values?.sellerGroupID
            ? supplierDetail?.sellerGroupID
            : values?.sellerGroupID,
      },
      vatInfo: {
        address: companyAddress,
        bankAccountNumber,
        bankBranch: removeWhiteSpace(bankBranch),
        bankID,
        businessName: fullName,
        email: company_email || null,
        representative: removeWhiteSpace(representative) || null,
        taxIDNumber,
        telephone: legal_representative_phone || null,
        defaultExportVat,
        allowEditVat,
      },
      warehouseIDs: isSeller ? warehouseIDs : [],
      bankAccounts: convertBankAccounts(bankAccounts),
    };

    if (isUpdate) {
      try {
        await handleUpdateSupplier(payload);
        notify.success({
          message: t("supplier.updateSuccess"),
        });
        history.goBack();
      } catch (err) {
        notify.error({
          message: t("supplier.updateFail"),
          description: err.message,
        });
      }
    } else {
      try {
        await handleCreateSupplier(payload);
        notify.success({
          message: t("supplier.createSuccess"),
        });
        setBankAccounts([]);
        clearFields();
        isRedirect && history.push("/supplier-list");
      } catch (err) {
        notify.error({
          message: t("supplier.createFail"),
          description: err.message,
        });
      }
    }
  };

  const { handleUpload } = useUpload();
  const [uploading, setUploading] = useState(false);

  function onUploadChange(info) {
    if (info.file.status === "uploading") {
      setUploading(true);
    }
    if (info.file.status === "done") {
      setUploading(false);
      readFile({
        getResult: async (result) => {
          const uploadUrl = await handleUpload({ file: info?.file?.originFileObj });
          setAvatar({
            base64String: result,
            src: uploadUrl,
          });
        },
        type: "Base64String",
        file: info?.file?.originFileObj,
      });
    } else if (info.file.status === "error") {
      return;
    }
  }

  useEffect(() => {
    initForm();
    setTypeSupplier(isUpdate ? supplierDetail?.type : typeSupplier);
    setIsSeller(isUpdate ? supplierDetail?.isSeller : isSeller);
    setPaymentMethod(isUpdate ? supplierDetail?.paymentMethod : paymentMethod);
  }, [supplierDetail, loadingDetail]);

  useEffect(() => {
    setBankAccounts([...bankAccounts, ...dataBankAccountsServer]);
  }, [supplierDetail?.bankAccounts]);

  useEffect(() => {
    setIsDisabledInput(paymentMethod === PAYMENT_METHOD.WALLET);
  }, [paymentMethod, isSeller]);

  return {
    form,
    avatar,
    uploading,
    typeSupplier,
    isSeller,
    location,
    loading: loadingCreate || loadingUpdateSupplier,
    supplierDetail,
    data: supplierDetail,
    isDisabledInput,
    paymentMethod,
    handleValuesChange,
    onUploadChange,
    handleSubmit,
    handleChangeCity,
    handleChangeDistrict,
    handleFinish,
    visible,
    handleOpenModalSave,
    handleCloseModalSave,
    sellerID: id,
    bankAccounts,
    handleGetBankAccounts,
  };
}

export default useSupplierCreate;
