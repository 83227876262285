import { Col, Form, Row, Space, Spin, Typography } from "antd";
import FormInput from "components/FormInput";
import PageHeader from "components/PageHeader";
import { INPUT_TYPE } from "config/constants";
import Card from "features/Cart/components/Card";
import { t } from "i18next";
import useAgent from "pages/App/Agent/hooks";
import ButtonGroup from "./ButtonGroup";
import CommonForm from "./CommonForm";
import GeneralInformation from "./GeneralInformation";
import useSupplierCreate from "./useSupplierCreate";

const titleCard = {
  level: 5,
  style: {
    color: "#052987",
  },
};

function SupplierCreate({ isUpdate, supplierDetail, loadingDetail }) {
  const {
    form,
    avatar,
    uploading,
    typeSupplier,
    isSeller,
    location,
    loading,
    data,
    isDisabledInput,
    handleValuesChange,
    onUploadChange,
    handleSubmit,
    handleChangeCity,
    handleChangeDistrict,
    handleFinish,
    visible,
    handleOpenModalSave,
    handleCloseModalSave,
    sellerID,
    bankAccounts,
    handleGetBankAccounts,
  } = useSupplierCreate({ isUpdate, supplierDetail, loadingDetail });

  const {
    paymentMethodOptions,
    companyOptions,
    citiesOptions,
    districtsOptions,
    wardsOptions,
    warehouseOptions,
    banksOptions,
    hasMoreData,
    customerOptions,
    handleSearchSelect,
    loadMoreDataCustomers,
    onSearch,
    refetch,
  } = useAgent(location);

  return (
    <Spin spinning={loading}>
      <Form layout="vertical" form={form} onValuesChange={handleValuesChange}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <PageHeader
              pageTitle={isUpdate ? t("supplier.updateSupplier") : t("supplier.supplierCreate")}
              routes={[
                { path: "/supplier-list", name: t("supplier.title") },
                { path: "/supplier-list", name: t("supplier.supplierManagement") },
                {
                  path: "#",
                  name: isUpdate ? t("supplier.updateSupplier") : t("supplier.supplierCreate"),
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Space>
                  <Typography style={{ marginBottom: "24px", paddingRight: 100 }}>
                    <Typography.Text strong>{t("supplier.supplierClassification")}: </Typography.Text>
                  </Typography>
                  <FormInput
                    type={INPUT_TYPE.RADIO_GROUP}
                    formItemOptions={{
                      name: "type",
                    }}
                    inputOptions={{
                      options: companyOptions,
                    }}
                  />
                </Space>
              </Col>
              <Col span={16}>
                <FormInput
                  type={INPUT_TYPE.CHECK_BOX}
                  formItemOptions={{
                    name: "isSeller",
                    valuePropName: "checked",
                  }}
                  inputChildren={t("supplier.isCustomer")}
                />
              </Col>
            </Row>
          </Col>
          {/* THong tin chung */}
          <Col span={24}>
            <Card title={<Typography.Title {...titleCard}>{t("supplier.generalInformation")}</Typography.Title>}>
              <GeneralInformation
                data={data}
                avatar={avatar}
                onUploadChange={onUploadChange}
                uploading={uploading}
                typeSupplier={typeSupplier}
                isSeller={isSeller}
                warehousesOptions={warehouseOptions}
                customerOptions={customerOptions}
                form={form}
                refetch={refetch}
                onSearch={onSearch}
                hasMoreData={hasMoreData}
                loadMoreDataCustomers={loadMoreDataCustomers}
                citiesOptions={citiesOptions}
                handleChangeCity={handleChangeCity}
                districtsOptions={districtsOptions}
                handleChangeDistrict={handleChangeDistrict}
                handleSearchSelect={handleSearchSelect}
                wardsOptions={wardsOptions}
              />
            </Card>
          </Col>
          {/* ALL CARD */}
          <Col span={24}>
            <CommonForm
              typeSupplier={typeSupplier}
              isSeller={isSeller}
              paymentMethodOptions={paymentMethodOptions}
              banksOptions={banksOptions}
              isDisabledInput={isDisabledInput}
              data={supplierDetail}
              sellerID={sellerID}
              loadingDetail={loadingDetail}
              form={form}
              bankAccounts={bankAccounts}
              handleGetBankAccounts={handleGetBankAccounts}
            />
          </Col>
        </Row>
        <ButtonGroup
          loading={loading}
          isUpdate={isUpdate}
          isSeller={isSeller}
          data={data}
          onSaveAndAdd={() => handleSubmit(false, false)}
          onSave={() => handleSubmit(true, true)}
          onFinish={handleFinish}
          visible={visible}
          handleOpenModalSave={handleOpenModalSave}
          handleCloseModalSave={handleCloseModalSave}
        />
      </Form>
    </Spin>
  );
}

export default SupplierCreate;
