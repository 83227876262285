import { Dropdown, Menu, message, Space, Tag, Tooltip, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import ProductLink from "components/CustomRedirectLink/ProductLink";
import CustomUom from "components/CustomUom";
import CustomTitle from "features/Serial/components/CustomTitle";
import { truncateAddDots } from "features/WarrantyRequest/helperFuncs";
import { convertUnit } from "hooks/warranty/converter";
import { t } from "i18next";
import ModalSelectSerial from "../../ModalSelectSerial";
import styles from "./index.module.scss";

function TableTransferSerial({ getQuantityById, renderLack }) {
  const copyToClipboard = (record) => {
    const listSerialCopy = record?.exportItems?.map((item) => item?.serial?.code);
    if (listSerialCopy?.length > 0) {
      const textToCopy = listSerialCopy.join(", ");
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = textToCopy;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);
      message.success(t("serialIE.coppySerial"));
    } else {
      message.error(t("serialIE.dontCopy"));
    }
  };
  const columns = [
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.stt")} />,
      render: (_, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    {
      title: <CustomTitle title={t("serialIE.productInfo.parameter")} />,
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography.Link style={{ textAlign: "start", margin: 0 }} strong>
            <ProductLink product={record} />
          </Typography.Link>
          <Tooltip title={record?.product?.name}>
            <Typography.Text style={{ textAlign: "start", margin: 0 }} strong>
              {truncateAddDots(record?.product?.name, 80)}
            </Typography.Text>
          </Tooltip>
          <CustomUom uom={record?.product?.uom} />
        </div>
      ),
      isHidden: false,
      width: 300,
      fixed: "left",
    },
    {
      align: "center",
      title: <CustomTitle title={"Thời hạn bảo hành"} />,
      render: (_, record, index) => {
        return (
          <Typography.Text>
            {record?.warrantyOnSale} {convertUnit(record?.warrantyOnSaleUnit)}
          </Typography.Text>
        );
      },
      width: 180,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={t("serialIE.BYORDER")} />,
      render: (_, record, index) => <Typography.Text>{record?.expectedQuantity || 0}</Typography.Text>,
      width: 130,
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.quantity")} subTitle={"(Thực tế)"} />,
      width: 130,
      render: (_, record) => {
        return (
          <Space direction="vertical">
            <Typography.Text>{record?.exportItems?.length || 0}</Typography.Text>
            {
              <Typography.Text className={styles["sub-title"]}>
                {renderLack({
                  quantity: record?.expectedQuantity || 0,
                  scanned: record?.exportItems?.length || 0,
                }) < 0 ? (
                  <span style={{ color: "#D2434D" }}>
                    {t("serialIE.productInfo.residual")} :{" "}
                    {Math.abs(
                      renderLack({
                        quantity: record?.expectedQuantity || 0,
                        scanned: record?.exportItems?.length || 0,
                      })
                    )}
                  </span>
                ) : renderLack({
                    quantity: record?.expectedQuantity || 0,
                    scanned: record?.exportItems?.length || 0,
                  }) > 0 ? (
                  <span style={{ color: "#EF9351" }}>
                    {t("serialIE.productInfo.lack")} :{" "}
                    {renderLack({
                      quantity: record?.expectedQuantity || 0,
                      scanned: record?.exportItems?.length || 0,
                    })}
                  </span>
                ) : (
                  <span style={{ color: "#00AB78" }}>{t("serialIE.productInfo.enough")}</span>
                )}
              </Typography.Text>
            }
          </Space>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("serialIE.productInfo.detailSerialB")} />,
      render: (_, record, index) => {
        return (
          <div className="modal-detail-export-serial">
            <Space>
              <div className="input-tag-tags-detail">
                {record?.exportItems?.length > 0
                  ? record?.exportItems?.map((val) => {
                      return (
                        <Tag className="input-tag-tag" key={val}>
                          {val.serial?.code}
                        </Tag>
                      );
                    })
                  : t("serialExportCreate.noCheckedSerial")}
              </div>
            </Space>
          </div>
        );
      },
    },
    {
      align: "center",
      title: <CustomTitle title={t("campaign.actions")} />,
      render: (text, record, index) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <ModalSelectSerial
                    record={record}
                    index={index}
                    quantity={getQuantityById(record?.product?.id)}
                    copyToClipboard={copyToClipboard}
                  />
                </Menu.Item>
                <Menu.Item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: "5px",
                    }}
                    onClick={() => copyToClipboard(record)}
                  >
                    <SvgIcon.IconCoppy />
                    <Typography.Text>{t("serialIE.copy")}</Typography.Text>
                  </div>
                </Menu.Item>
              </Menu>
            }
            className="button-action-recommend button-offers-recommend"
          >
            <SvgIcon.IconMore style={{ fontSize: "16px" }} />
          </Dropdown>
        );
      },
      width: 70,
      fixed: "right",
    },
  ];
  return { columns };
}

export default TableTransferSerial;
