/* eslint-disable react-hooks/exhaustive-deps */
import { CameraOutlined, CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Radio, Row, Space, Typography, message } from "antd";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import FormInput from "components/FormInput";
import { PrimaryButton } from "components/PrimaryButton";
import { INPUT_TYPE } from "config/constants";
import copy from "copy-to-clipboard";
import { useGenerateLink } from "hooks/generateLink/query";
import * as htmlToImage from "html-to-image";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency, notify } from "utils/helperFuncs";

const QUOTATION_FORM = {
  LINK: "LINK",
  INFO: "INFO",
};

function ModalCopyProductPrice({ loading, data, handleSetSelectedRows, selectedRowKeys, handleSetSelectedRowKeys }) {
  const [form] = Form.useForm();

  const initialValues = data.map((item) => ({
    id: item?.id,
    code: item?.code,
    name: item?.name,
    price: item?.productPrices?.price,
    priceVat: item?.productPrices?.priceVat,
    oldPrice: item?.lastPriceLog?.oldPrice,
    newPrice: item?.lastPriceLog?.newPrice,
    oldPriceVat: item?.lastPriceLog?.oldPriceVat,
    newPriceVat: item?.lastPriceLog?.newPriceVat,
    lastPriceLog: item?.lastPriceLog,
  }));
  const [itemsCopy, setItemsCopy] = useState();
  const [quotationForm, setQuotationForm] = useState(QUOTATION_FORM.LINK);
  const [visible, setVisible] = useState(false);

  const { handleGenerateLink, loading: loadingGenLink } = useGenerateLink();

  const openModal = () => {
    if (data?.length) {
      setVisible(true);
    } else {
      notify.warning({ message: t("product.errorMessage") });
      setVisible(false);
    }
  };

  const closeModal = () => {
    setVisible(false);
  };

  const [showColumns, setShowColumns] = useState([
    {
      title: "GIÁ CHƯA VAT",
      name: "price",
      checked: true,
    },
    {
      title: "GIÁ CÓ VAT",
      name: "priceVat",
      checked: false,
    },
  ]);

  const handleColumnChange = (index) => (e) => {
    setShowColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];
      updatedColumns[index] = {
        ...updatedColumns[index],
        checked: e.target.checked,
      };
      return updatedColumns;
    });
  };

  const checkNullValue = (value) => {
    if (value === null || value === "" || value === undefined) {
      return "- -";
    } else {
      return value;
    }
  };

  const checkValueDuplicate = (price, lastPriceLog, oldValue, newValue) => {
    if (lastPriceLog) {
      if (oldValue.toString() === newValue.toString()) {
        return oldValue;
      } else
        return (
          <span>
            <div>{checkNullValue(oldValue)}</div>
            <div style={{ color: "#32BF46" }}>&nbsp;&#10142;&nbsp;{checkNullValue(newValue)}</div>
          </span>
        );
    } else {
      return price;
    }
  };

  const columns = [
    {
      title: (
        <Typography.Text style={{ color: "rgba(91, 102, 115, 1)" }} strong>
          STT
        </Typography.Text>
      ),
      align: "center",
      width: 50,
      render: (_, __, index) => index + 1,
    },

    {
      title: "THÔNG TIN SẢN PHẨM",
      width: 250,
      render: (_, record) => (
        <>
          <Link to={`/product/detail/${record?.id}`} target="_blank">
            <Typography.Link strong>{record?.code}</Typography.Link>
          </Link>
          <CustomTextHiddenLine text={record?.name} line={3} />
        </>
      ),
    },

    ...(quotationForm !== QUOTATION_FORM.LINK
      ? showColumns?.map((column, index) =>
          column?.checked
            ? {
                title: column?.title,
                dataIndex: ["productPrices", column?.name],
                width: 150,
                render: (_, record, index) => (
                  <FormInput
                    {...{
                      type: INPUT_TYPE.CURRENCY_INPUT,
                      formItemOptions: {
                        name: [index, column?.name],
                        rules: [
                          {
                            required: true,
                            message: "Không được để trống trường này!",
                          },
                        ],
                      },
                      inputOptions: {
                        min: 0,
                        maxLength: 11,
                      },
                    }}
                  />
                ),
              }
            : undefined
        )
      : [
          {
            title: "GIÁ CHƯA VAT",
            width: 150,
            align: "right",
            render: (_, record) =>
              checkValueDuplicate(
                formatCurrency(record?.price),
                record?.lastPriceLog,
                formatCurrency(record?.oldPrice),
                formatCurrency(record?.newPrice)
              ),
          },
          {
            title: "GIÁ CÓ VAT",
            width: 150,
            align: "right",
            render: (_, record) =>
              checkValueDuplicate(
                formatCurrency(record?.priceVat),
                record?.lastPriceLog,
                formatCurrency(record?.oldPriceVat),
                formatCurrency(record?.newPriceVat)
              ),
          },
        ]),
    {
      align: "center",
      width: "10px",
      render: (_, record) => {
        const handleDelete = () => {
          const cloneItemsCopy = [...itemsCopy];
          const cloneData = [...data];
          const cloneDataRowKeys = [...selectedRowKeys];

          const newData = cloneData?.filter((item) => item?.id !== record?.id);
          const newItemsCopy = cloneItemsCopy?.filter((item) => item?.id !== record?.id);
          const newDataRowKeys = cloneDataRowKeys?.filter((item) => item !== record?.id);

          setItemsCopy(newItemsCopy);
          handleSetSelectedRows(newData);
          handleSetSelectedRowKeys(newDataRowKeys);
        };
        return (
          <div style={{ cursor: "pointer" }} onClick={handleDelete}>
            <DeleteOutlined />
          </div>
        );
      },
    },
  ].filter(Boolean);

  const formattedData = itemsCopy?.map(({ name, price, priceVat }) => {
    const selectedColumns = showColumns.filter((column) => column.checked);
    const rowData = [name];

    selectedColumns.forEach((column) => {
      if (column.name === "price") {
        rowData.push(formatCurrency(price));
      } else if (column.name === "priceVat") {
        rowData.push(formatCurrency(priceVat));
      }
    });

    return rowData;
  });

  const textToCopy = formattedData?.map((row) => row.join("\t")).join("\n");

  const handleCopyTable = () => {
    copy(textToCopy);
    message.success(t("product.copyTypeTextSuccess"));
  };

  const tableRef = useRef(null);
  const [loadingCopy, setLoadingCopy] = useState(false);

  const copyTableToClipboard = async () => {
    setLoadingCopy(true);
    try {
      const table = tableRef.current;
      await htmlToImage
        .toPng(table)
        .then((dataUrl) => {
          const blob = dataURLToBlob(dataUrl);
          // eslint-disable-next-line no-undef
          const item = new ClipboardItem({ [blob.type]: blob });
          navigator.clipboard
            .write([item])
            .then(() => {
              setLoadingCopy(false);
              message.success(t("product.copyTypeImageSuccess"));
            })
            .catch((error) => {
              console.error("Failed to copy table as image to clipboard:", error);
            });
        })
        .catch((error) => {
          console.error("Conversion failed:", error);
          setLoadingCopy(false);
        });
    } catch (error) {
      console.info(error);
    }
  };

  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);
    for (let i = 0; i < n; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues) {
      const newData = itemsCopy.map((item, index) => {
        return {
          ...item,
          price: allValues?.[index]?.price || item?.price,
          priceVat: allValues?.[index]?.priceVat || item?.priceVat,
          newPrice: allValues?.[index]?.newPrice || item?.newPrice,
          newPriceVat: allValues?.[index]?.newPriceVat || item?.newPriceVat,
          olePrice: allValues?.[index]?.olePrice || item?.olePrice,
          oldPriceVat: allValues?.[index]?.oldPriceVat || item?.oldPriceVat,
        };
      });
      setItemsCopy(newData);
    }
  };

  const handleCopyLink = async () => {
    let resultIDs = selectedRowKeys.join(",");
    try {
      const shopURL = process.env.REACT_APP_SHOP;
      const link = `${shopURL}/products?product_ids=${resultIDs}&from_home=false&leading_text`;
      await handleGenerateLink({ url: link }).then((res) => {
        const dynamicLayout = `CHI TIẾT BÁO GIÁ
        Xem tại: ${res?.data?.linkQuery?.generateDynamicLinkFromUrl}
        ${itemsCopy?.map((item, index) => `- ${item?.name}`).join("\n")}`;

        copy(dynamicLayout);

        message.success("Đã sao chép link vào bộ nhớ tạm");
      });
    } catch (error) {
      console.info(error);
    }
  };

  useEffect(() => {
    setItemsCopy([...initialValues]);
    form.setFieldsValue({ ...initialValues });
  }, [data]);

  return (
    <CustomModal
      customComponent={<Typography.Text>Sao chép báo giá</Typography.Text>}
      title={<Typography.Title level={4}>{t("product.infoProducPrice")}</Typography.Title>}
      closable={false}
      centered
      footer={false}
      buttonLoading={loading}
      width="55%"
      hideConfirmButton
      onOpen={openModal}
      onCancel={closeModal}
      visible={visible}
      addMoreButton={
        quotationForm === QUOTATION_FORM.LINK ? (
          <PrimaryButton title={"Sao chép"} onClick={handleCopyLink} loading={loadingGenLink} />
        ) : (
          <Space>
            <Button onClick={copyTableToClipboard} loading={loadingCopy} icon={<CameraOutlined />} type="primary">
              {t("product.copyTypeImage")}
            </Button>
            <Button onClick={handleCopyTable} icon={<CopyOutlined />} type="primary">
              {t("product.copyTypeText")}
            </Button>
          </Space>
        )
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <Col span={4}>
              <Typography>
                <Typography.Text strong>Hình thức báo giá </Typography.Text>
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Col>
            <Col>
              <Radio.Group
                defaultValue={quotationForm}
                value={quotationForm}
                onChange={(e) => setQuotationForm(e.target.value)}
              >
                <Radio value={QUOTATION_FORM.LINK}>Sao chép link báo giá</Radio>
                <Radio value={QUOTATION_FORM.INFO}>Sao chép thông tin báo giá</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
        {quotationForm === QUOTATION_FORM.LINK && (
          <Col span={24}>
            <Typography.Text type="warning">Lưu ý: Link sao chép sẽ điều hướng đại lý mở app.</Typography.Text>
          </Col>
        )}
        {quotationForm !== QUOTATION_FORM.LINK && (
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <Typography>
                  <Typography.Text strong>{t("product.choosePriceLv")} </Typography.Text>
                  <span style={{ color: "red" }}>*</span>
                </Typography>
              </Col>
              <Col>
                {showColumns?.map((column, index) => (
                  <Checkbox key={index} checked={column?.checked} onChange={handleColumnChange(index)}>
                    {column?.title}
                  </Checkbox>
                ))}
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Form
            form={form}
            initialValues={initialValues}
            style={{ paddingBottom: "16px" }}
            onValuesChange={handleValuesChange}
          >
            <div ref={tableRef}>
              {quotationForm === QUOTATION_FORM.LINK ? (
                <CustomTable
                  rowKey={(record) => record?.id}
                  bordered
                  columns={columns}
                  dataSource={itemsCopy}
                  {...(itemsCopy?.length > 10 && {
                    scroll: {
                      x: "max-content",
                      y: "500px",
                    },
                  })}
                />
              ) : (
                <CustomTable rowKey={(record) => record?.id} bordered columns={columns} dataSource={itemsCopy} />
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </CustomModal>
  );
}

export default ModalCopyProductPrice;
