/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Input, Row, Typography } from "antd";
import { Icon } from "assets/icons";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { useUpdateProductStatus } from "hooks/product/product";
import { SELLING_STATUS } from "pages/App/Product/constants";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency, notify } from "utils/helperFuncs";

function ModalSwitchContact({ data, handleSetSelectedRows, selectedRowKeys, handleSetSelectedRowKeys }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [searchKeyword, setSearchKeyword] = useState("");

  const { handleUpdateProductStatus, loading } = useUpdateProductStatus();

  const initialValues = data.map((item) => ({
    id: item?.id,
    code: item?.code,
    name: item?.name,
    price: item?.productPrices?.price,
    priceVat: item?.productPrices?.priceVat,
  }));

  const columns = [
    {
      title: (
        <Typography.Text style={{ color: "rgba(91, 102, 115, 1)" }} strong>
          STT
        </Typography.Text>
      ),
      align: "center",
      width: 50,
      render: (_, __, index) => index + 1,
    },
    {
      title: "THÔNG TIN SẢN PHẨM",
      width: 300,
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Link to={`/product/detail/${record?.id}`} target="_blank" strong>
            <Typography.Link strong>{record?.code}</Typography.Link>
          </Link>
          <CustomTextHiddenLine text={record?.name} line={3} />
        </div>
      ),
    },
    {
      title: "GIÁ CHƯA VAT",
      width: 150,
      align: "right",
      render: (_, record) => <Typography.Text>{formatCurrency(record?.price)}</Typography.Text>,
    },
    {
      title: "GIÁ CÓ VAT",
      width: 150,
      align: "right",
      render: (_, record) => <Typography.Text>{formatCurrency(record?.priceVat)}</Typography.Text>,
    },
    {
      title: "HÀNH ĐỘNG",
      align: "center",
      width: "100px",
      render: (_, record) => {
        const handleDelete = () => {
          const cloneDataSource = [...dataSource];
          const cloneData = [...data];
          const cloneDataRowKeys = [...selectedRowKeys];

          const newData = cloneData?.filter((item) => item?.id !== record?.id);
          const newItemsCopy = cloneDataSource?.filter((item) => item?.id !== record?.id);
          const newDataRowKeys = cloneDataRowKeys?.filter((item) => item !== record?.id);

          setDataSource(newItemsCopy);
          handleSetSelectedRows(newData);
          handleSetSelectedRowKeys(newDataRowKeys);
        };
        return (
          <div style={{ cursor: "pointer" }} onClick={handleDelete}>
            <DeleteOutlined />
          </div>
        );
      },
    },
  ];

  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
  };
  const filteredDataSource = dataSource?.filter(
    (item) =>
      item.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.code.toLowerCase().includes(searchKeyword.toLowerCase())
  );
  const openModal = () => {
    if (data?.length) {
      setVisible(true);
    } else {
      notify.warning({ message: "Vui lòng chọn sản phẩm trước khi Chuyển sang liên hệ" });
      setVisible(false);
    }
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handleOkModalContact = async () => {
    try {
      return handleUpdateProductStatus({ ids: selectedRowKeys, sellingStatus: SELLING_STATUS.CONTACT })
        .then(() => {
          notify.success({
            message: "Thay đổi trạng thái sản phẩm thành công",
          });
          closeModal();
        })
        .catch((err) => {
          notify.error({
            message: "Thay đổi trạng thái sản phẩm thất bại",
            description: err?.message,
          });
          openModal();
        });
    } catch (error) {
      console.info(error);
    }
  };

  useEffect(() => {
    setDataSource([...initialValues]);
    form.setFieldsValue({ ...initialValues });
  }, [data]);

  return (
    <CustomModal
      customComponent={<Typography.Text>Chuyển sang liên hệ</Typography.Text>}
      title={<Typography.Title level={4}>Cập nhật trạng thái liên hệ</Typography.Title>}
      closable={false}
      centered
      footer={false}
      buttonLoading={loading}
      width={"55%"}
      onOpen={openModal}
      onCancel={closeModal}
      visible={visible}
      okeButtonLabel={"Cập nhật"}
      onOke={handleOkModalContact}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text>Vui lòng kiểm tra kĩ các mã sản phẩm trước khi cập nhật</Typography.Text>
            <Typography.Text type="warning">
              <span style={{ color: "#faad14" }}>Sau khi cập nhật thành công,</span> giá của các sản phẩm sẽ bằng 0đ{" "}
              <span style={{ color: "#faad14" }} và></span> trạng thái của sản phẩm
              <span style={{ color: "#faad14" }}> trên app/web đại lý sẽ là </span>
              Liên hệ
              <span style={{ color: "#faad14" }}>.</span>
            </Typography.Text>
          </div>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]} style={{ alignItems: "center" }}>
            <Col span={4}>
              <Typography.Text>Tìm kiếm sản phẩm</Typography.Text>
            </Col>
            <Col span={14}>
              <Input
                prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
                placeholder={"Tìm kiếm sản phẩm theo tên, mã"}
                allowClear={true}
                value={searchKeyword}
                onChange={handleSearchChange}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form form={form} initialValues={initialValues} style={{ paddingBottom: "16px" }}>
            <CustomTable
              rowKey={(record) => record?.id}
              bordered
              columns={columns}
              dataSource={filteredDataSource}
              {...(filteredDataSource?.length > 10 && {
                scroll: {
                  x: "max-content",
                  y: "500px",
                },
              })}
            />
          </Form>
        </Col>
      </Row>
    </CustomModal>
  );
}

export default ModalSwitchContact;
