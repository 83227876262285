import { gql } from "@apollo/client";

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation UpdateProduct(
    $productID: ID!
    $vat: Boolean
    $vatPercent: Int32
    $vatValue: Int64
    $note: String
    $prices: ProductPriceInput
    $productDiscounts: [ProductDiscountInput!]
    $sellingStatus: SellingStatus
    $vatType: VatType = OPTIONAL
    $minOrderQuantity: Int64
  ) {
    product {
      setPrices(
        id: $productID
        prices: $prices
        vat: $vat
        vatPercent: $vatPercent
        vatValue: $vatValue
        productDiscounts: $productDiscounts
        note: $note
        sellingStatus: $sellingStatus
        vatType: $vatType
        minOrderQuantity: $minOrderQuantity
      ) {
        id
      }
    }
  }
`;

export const UPDATE_PRODUCT_STATUS = gql`
  mutation UpdateProductStatus($ids: [ID!], $sellingStatus: SellingStatus) {
    product {
      updateStatus(ids: $ids, sellingStatus: $sellingStatus) {
        id
        isActive
      }
    }
  }
`;

export const CREATE_PRODUCT_GROUP_MUTATION = gql`
  mutation CreateProductGroup($req: ProductGroupInput!) {
    productGroup {
      create(req: $req) {
        id
        products {
          id
        }
        code
      }
    }
  }
`;

export const UPDATE_PRODUCT_GROUP_MUTATION = gql`
  mutation UpdateProductGroup($id: ID!, $req: ProductGroupInput!) {
    productGroup {
      update(id: $id, req: $req) {
        id
      }
    }
  }
`;

export const UPLOAD_FILE_PRODUCT_PRICE_LOGS_MUTATION = gql`
  mutation UploadFileProductPriceLogs($file: Upload!) {
    product {
      importProductPrices(file: $file) {
        id
        status
        errorFileURL
        errorReason
      }
    }
  }
`;

export const CREATE_PRODUCT_BRAND = gql`
  mutation CreateProductBrand($request: CreateBrandInput) {
    brand {
      create(request: $request)
    }
  }
`;

export const UPDATED_PRODUCT_BRAND = gql`
  mutation UpdateProductBrand($request: UpdateBrandInput) {
    brand {
      update(request: $request)
    }
  }
`;
