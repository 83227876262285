import { Table, Typography } from "antd";
import EllipsisText from "components/_storybooks/EllipsisText";
import ProductParameter from "components/_storybooks/ProductParameter";
import { FilterLoadMore } from "components/CustomFilterMenu";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import {
  ACTIONS_OPTION,
  OWNER_TYPE,
  PROMOTION_PRICE_BID_STATUS,
  STATUS_PRICE_BID,
  actionsLabel,
  convertActiveKey,
  setColorStatusPriceBid,
  setLabelStatusPriceBid,
} from "features/Products/constants";
import { useGetInfoPlatform } from "hooks/info-platform";
import { useGetSummaryTabsPriceBid } from "hooks/priceBid/query";
import { useGetListPriceBidWaitResponse } from "hooks/product/product";
import { useGetSellerCreateCart } from "hooks/seller";
import useDebounce from "hooks/useDebounce";
import { useGetStaff } from "hooks/warranty";
import { omit } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "utils/dateTime";
import { buildQueryString, formatCurrency, getQuery } from "utils/helperFuncs";
import FilterMenu from "./components/FilterMenu";
import { SelectActionsBid } from "./components/SelectActionBid";

const SORT_TYPES = {
  ascend: "ASC",
  descend: "DESC",
};

export default function useListPriceBid() {
  const GET_QUERY = getQuery();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState(undefined);
  const debouncedValue = useDebounce(searchTerm, 500);
  const { platformName } = useGetInfoPlatform();

  const [statusTabs, setStatusTabs] = useState(
    GET_QUERY?.status && GET_QUERY?.status?.length === 1 ? GET_QUERY?.status[0] : PROMOTION_PRICE_BID_STATUS.ALL
  );

  const isStatusAll = statusTabs === PROMOTION_PRICE_BID_STATUS?.ALL;
  const [filterTags, setFilterTags] = useState([]);

  const [params, setParams] = useState(() => {
    return {
      activeKey: GET_QUERY?.activeKey || "ALL",
      filters: {
        query: GET_QUERY?.query || undefined,
        sellerIDs: GET_QUERY.sellerIDs || [],
        timeRange: {
          from: GET_QUERY?.from || undefined,
          to: GET_QUERY?.to || undefined,
        },
        status: GET_QUERY?.status || [],
        ownerType: GET_QUERY.ownerType || [],
        productIDs: GET_QUERY.productIDs || [],
        lastEventTypes: GET_QUERY.lastEventTypes,
        lastPerformUsers: {
          ids: GET_QUERY?.lastPerformUsers?.ids,
          types: GET_QUERY?.lastPerformUsers?.types,
        },
      },
      pagination: {
        limit: GET_QUERY?.limit || 10,
        offset: GET_QUERY?.offset || 0,
      },
      sort: [],
    };
  });
  const { data: dataSummaryTabs, refetch } = useGetSummaryTabsPriceBid();
  const { data, paginationData, loading } = useGetListPriceBidWaitResponse(omit(params));

  const [paramsSeller, setParamsSeller] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { data: sellers, onLoadMoreData, hasMoreData } = useGetSellerCreateCart(paramsSeller);
  const sellersOptions = sellers?.map(({ id, fullName }) => ({ value: id, label: fullName }));

  const handleChange = (activeKey) => {
    refetch();
    setStatusTabs(activeKey);
    setParams({
      activeKey: activeKey,
      filters: {
        timeRange: {
          from: undefined,
          to: undefined,
        },
        query: null,
        status: convertActiveKey(activeKey),
        isActive: undefined,
        sellerIDs: [],
        ownerType: [],
        productIDs: [],
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
      sort: [],
    });
  };

  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    };
    setParams(newParams);
    setParams(newParams);
    const queryString = {
      ...omit(newParams.filters, ["lastPerformUsers", "timeRange"]),
      ...newParams.pagination,
      ...newParams.filters.timeRange,
      activeKey: newParams.activeKey,
    };
    buildQueryString({ params: { ...queryString } });
  };

  const handleSearchSellers = (value) => {
    setParamsSeller({
      ...paramsSeller,
      pagination: { offset: 0, limit: 10 },
      filters: { query: value || undefined },
    });
  };

  const handleRemoveFilterTag = (key) => {
    setParams({
      pagination: { offset: 0, limit: 10 },
      filters: {
        ...omit({ ...params.filters }, [`${key}`]),
      },
      sort: params.sort,
      activeKey: params.activeKey,
    });
    buildQueryString({
      params: {
        ...omit({ ...params?.filters }, [`${key}`]),
        ...params?.pagination,
        activeKey: params.activeKey,
      },
    });
  };

  const OPTION_OWNER_TYPE = [
    {
      label: t("product.priceBid.HongAnhOfferSeller", { platformName }),
      value: OWNER_TYPE.STAFF,
    },
    {
      label: t("product.priceBid.AgentsPay"),
      value: OWNER_TYPE.SELLER,
    },
  ];

  const OPTION_PRICE_BID_STATUS = [
    {
      label: t("product.priceBid.Pending"),
      value: STATUS_PRICE_BID.WAITING_FOR_STAFF_CONFIRM,
    },
    {
      label: t("product.priceBid.WaitResponse"),
      value: STATUS_PRICE_BID.WAITING_FOR_SELLER_CONFIRM,
    },
    {
      label: t("product.priceBid.Accepted"),
      value: STATUS_PRICE_BID.APPROVED,
    },
    {
      label: t("product.priceBid.Reject"),
      value: STATUS_PRICE_BID.REJECTED,
    },
    {
      label: t("product.priceBid.completed"),
      value: STATUS_PRICE_BID.COMPLETED,
    },
    {
      label: t("product.priceBid.Canceled"),
      value: STATUS_PRICE_BID.CANCELED,
    },
  ];

  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const {
    data: optionsCareStaffs,
    onLoadMoreData: onLoadMoreStaff,
    hasMoreData: hasMoreStaff,
  } = useGetStaff(paramsStaff);

  const onFilterLastPerformUsersFilter = (key, value) => {
    const ids = value.filter((item) => item !== OWNER_TYPE.SELLER);
    const types = value.filter((item) => item === OWNER_TYPE.SELLER);
    handleFilter &&
      handleFilter({
        lastPerformUsers: {
          ids: ids,
          types: types,
        },
      });
  };

  const handleSearchStaff = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const isFilterFromActiveKey =
    GET_QUERY?.activeKey === "ALL" || GET_QUERY?.activeKey === "APPROVED" || GET_QUERY?.activeKey === "CANCELED";

  const columns = {
    createdAt: {
      title: <span>{t("product.priceBid.dayReception")}</span>,
      render: (_, record) => formatDateTime(record?.createdAt),
      width: 160,
      sorter: true,
      align: "center",
    },
    parameter: {
      title: t("product.priceBid.parameter"),
      render: (_, record) => {
        return <ProductParameter parameter={record?.product} />;
      },
      width: 300,
    },
    agentName: {
      title: t("product.priceBid.agentName"),
      align: "left",
      width: 300,
      render: (_, record) => {
        return <EllipsisText isLink link={`/seller/detail/${record?.seller?.id}`} title={record?.seller?.fullName} />;
      },
      filterDropdown: (
        <FilterLoadMore
          params={params}
          data={sellersOptions}
          onLoadMoreData={onLoadMoreData}
          onFilter={(value) => handleFilter({ sellerIDs: value })}
          onSearchTextChange={handleSearchSellers}
          hasMore={hasMoreData}
        />
      ),
    },
    priceDefault: {
      title: t("product.priceBid.priceDefault"),
      align: "right",
      render: (_, record) => (
        <Typography.Link className="text-end">{formatCurrency(record.originalPrice)}</Typography.Link>
      ),
    },
    priceRequest: {
      title: t("product.priceBid.priceRequest"),
      align: "right",
      render: (_, record) => (
        <Typography.Link className="text-end">{formatCurrency(record.currentSuggestedPrice)}</Typography.Link>
      ),
    },
    quantityRequest: {
      title: t("product.priceBid.quantityRequest"),
      align: "center",
      width: 200,
      render: (_, record) => <Typography.Link>{record.currentSuggestedQuantity}</Typography.Link>,
    },
    difference: {
      title: t("product.priceBid.difference"),
      align: "right",
      render: (_, record) => {
        const currentDeviantPrice = record?.currentSuggestedPrice - record?.originalPrice;
        return (
          <Typography.Text
            className="text-end"
            style={{
              color: currentDeviantPrice >= 0 ? "#32BF46" : "#D2434D",
            }}
          >
            {formatCurrency(currentDeviantPrice)}
          </Typography.Text>
        );
      },
    },
    status: {
      title: t("product.priceBid.STATUS"),
      render: (_, record) => (
        <span
          style={{
            color: setColorStatusPriceBid(record.status),
          }}
        >
          {setLabelStatusPriceBid(record.status)}
        </span>
      ),
      filterDropdown: (
        <FilterMenu
          onFilter={(value) => handleFilter({ status: value })}
          type="checkbox"
          options={OPTION_PRICE_BID_STATUS}
        />
      ),
    },
    actionAll: {
      title: t("product.priceBid.operation"),
      align: "center",
      fixed: "right",
      render: (_, record) => {
        const disabled =
          record.status === STATUS_PRICE_BID.WAITING_FOR_STAFF_CONFIRM ||
          record.status === STATUS_PRICE_BID.WAITING_FOR_SELLER_CONFIRM;
        return <SelectActionsBid record={record} disabled={!disabled} />;
      },
    },
    actionNew: {
      title: t("product.priceBid.action"),
      align: "left",
      render: (_, record) => {
        return <CustomTextHiddenLine text={actionsLabel[record?.events?.[0]?.eventType]} />;
      },
      filterDropdown: isFilterFromActiveKey && (
        <FilterMenu
          params={params}
          onFilter={(value) => handleFilter({ lastEventTypes: value })}
          type="checkbox"
          options={ACTIONS_OPTION}
        />
      ),
    },
    implementer: {
      title: t("product.priceBid.implementer"),
      align: "left",
      render: (_, record) => {
        return (
          <CustomTextHiddenLine
            text={
              record?.events?.[0]?.createdBy?.type === OWNER_TYPE.SELLER
                ? t("product.priceBid.agent")
                : record?.events?.[0]?.createdBy?.fullname
            }
          />
        );
      },
      filterDropdown: isFilterFromActiveKey && (
        <FilterLoadMore
          onFilter={(value) => onFilterLastPerformUsersFilter("lastPerformUsers", value)}
          data={[{ label: t("product.priceBid.agent"), value: OWNER_TYPE.SELLER }]?.concat(optionsCareStaffs)}
          onLoadMoreData={onLoadMoreStaff}
          hasMore={hasMoreStaff}
          onSearchTextChange={handleSearchStaff}
        />
      ),
    },
  };

  const expandedRowRender = (record) => {
    const originalPrice = record.originalPrice;
    const columnChild = [
      {
        title: t("product.priceBid.time"),
        render: (_, record) => formatDateTime(record?.createdAt),
        width: 100,
        align: "center",
      },
      {
        title: t("product.priceBid.action"),
        align: "left",
        render: (_, record) => {
          return <CustomTextHiddenLine text={actionsLabel[record?.eventType]} />;
        },
        width: 250,
      },
      {
        title: t("product.priceBid.implementer"),
        align: "left",
        render: (_, record) => {
          return (
            <CustomTextHiddenLine
              text={record?.ownerType === OWNER_TYPE.SELLER ? t("product.priceBid.agent") : record?.createdBy?.fullname}
            />
          );
        },
        width: 250,
      },
      {
        title: t("product.priceBid.priceRequest"),
        render: (_, record) => (
          <Typography.Link className="text-end">{formatCurrency(record.suggestedPrice)}</Typography.Link>
        ),
        width: 200,
        align: "right",
      },
      {
        title: t("product.priceBid.quantityRequest"),
        render: (_, record) => <Typography.Link>{record.suggestedQuantity}</Typography.Link>,
        width: 150,
        align: "center",
      },
      {
        width: 200,
        align: "right",
        title: t("product.priceBid.difference"),
        render: (_, record) => {
          const currentDeviantPrice = record?.suggestedPrice - originalPrice;
          return (
            <Typography.Text
              className="text-end"
              style={{
                color: currentDeviantPrice >= 0 ? "#32BF46" : "#D2434D",
              }}
            >
              {formatCurrency(currentDeviantPrice)}
            </Typography.Text>
          );
        },
      },
    ];
    return (
      <div style={{ marginBottom: "16px", marginLeft: "36px", width: "fit-content" }}>
        <Table columns={columnChild} dataSource={record.events} pagination={false} />
      </div>
    );
  };

  const {
    createdAt,
    parameter,
    agentName,
    priceDefault,
    priceRequest,
    quantityRequest,
    difference,
    status,
    actionAll,
    actionNew,
    implementer,
  } = columns;

  const ALL_COLUMNS_TABLE = [
    createdAt,
    parameter,
    agentName,
    actionNew,
    implementer,
    priceDefault,
    priceRequest,
    quantityRequest,
    difference,
    status,
    actionAll,
  ];

  const PENDING_COLUMNS_TABLE = [
    createdAt,
    parameter,
    agentName,
    actionNew,
    implementer,
    priceDefault,
    priceRequest,
    quantityRequest,
    difference,
    actionAll,
  ];

  const WAIT_COLUMNS_TABLE = [
    createdAt,
    parameter,
    agentName,
    actionNew,
    implementer,
    priceDefault,
    priceRequest,
    quantityRequest,
    difference,
    actionAll,
  ];

  const APPROVED_COLUMNS_TABLE = [
    createdAt,
    parameter,
    agentName,
    actionNew,
    implementer,
    priceDefault,
    priceRequest,
    quantityRequest,
    difference,
  ];

  const REJECTED_COLUMNS_TABLE = [
    createdAt,
    parameter,
    agentName,
    actionNew,
    implementer,
    priceDefault,
    priceRequest,
    quantityRequest,
    difference,
  ];

  const COMPLETED_COLUMNS_TABLE = [
    createdAt,
    parameter,
    agentName,
    actionNew,
    implementer,
    priceDefault,
    priceRequest,
    quantityRequest,
    difference,
  ];

  const CANCELED_COLUMNS_TABLE = [
    createdAt,
    parameter,
    agentName,
    actionNew,
    implementer,
    priceDefault,
    priceRequest,
    quantityRequest,
    difference,
  ];

  const renderColumns = (type) => {
    switch (type) {
      case undefined:
        return ALL_COLUMNS_TABLE;
      case PROMOTION_PRICE_BID_STATUS.ALL:
        return ALL_COLUMNS_TABLE;
      case PROMOTION_PRICE_BID_STATUS.WAITING_FOR_STAFF_CONFIRM:
        return PENDING_COLUMNS_TABLE;
      case PROMOTION_PRICE_BID_STATUS.WAITING_FOR_SELLER_CONFIRM:
        return WAIT_COLUMNS_TABLE;
      case PROMOTION_PRICE_BID_STATUS.APPROVED:
        return APPROVED_COLUMNS_TABLE;
      case PROMOTION_PRICE_BID_STATUS.REJECTED:
        return REJECTED_COLUMNS_TABLE;
      case PROMOTION_PRICE_BID_STATUS.COMPLETED:
        return COMPLETED_COLUMNS_TABLE;
      case PROMOTION_PRICE_BID_STATUS.CANCELED:
        return CANCELED_COLUMNS_TABLE;
      default:
        return;
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setParams({
      ...params,
      pagination: {
        limit: 10,
        offset: 0,
      },
    });
  };

  const handleChangeTable = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    let newParams = {
      ...params,
      pagination: { ...params.pagination, offset: (current - 1) * pageSize },
      sort: sorter?.order ? [{ by: "CREATED_AT", sortType: SORT_TYPES[`${sorter?.order}`] }] : [],
    };

    setParams(newParams);
    const queryString = {
      ...omit(newParams.filters, ["lastPerformUsers", "timeRange"]),
      ...newParams.pagination,
      ...newParams.filters.timeRange,
      activeKey: newParams.activeKey,
    };
    buildQueryString({ params: { ...queryString } });
  };

  useEffect(() => {
    const newParams = { ...params };
    const queryString = {
      ...omit(newParams.filters, ["lastPerformUsers", "timeRange"]),
      ...newParams.pagination,
      activeKey: newParams.activeKey,
      types: newParams?.filters?.lastPerformUsers?.types,
      ids: newParams?.filters?.lastPerformUsers?.ids,
      from: newParams?.filters?.timeRange?.from,
      to: newParams?.filters?.timeRange?.to,
    };
    buildQueryString({ params: { ...queryString } });
  }, [params]);

  useEffect(() => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        query: debouncedValue,
      },
      sort: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    let newParams = {
      ...params,
      filters: {
        ...params.filters,
        status: GET_QUERY.status,
      },
      sort: [],
    };
    setParams({
      ...newParams,
      filters: {
        ...newParams.filters,
        lastPerformUsers: {
          types: GET_QUERY.types,
          ids: GET_QUERY.ids,
        },
      },
    });
    setSearchTerm(GET_QUERY.query);
    buildQueryString({
      params: {
        ...omit(newParams.filters, ["lastPerformUsers", "timeRange"]),
        ...newParams?.pagination,
        activeKey: newParams.activeKey,
        types: GET_QUERY.types,
        ids: GET_QUERY.ids,
        from: newParams?.filters?.timeRange?.from,
        to: newParams?.filters?.timeRange?.to,
      },
    });
  }, []);

  return {
    renderColumns,
    params,
    data,
    handleChange,
    loading,
    paginationData,
    isStatusAll,
    handleSearch,
    expandedRowRender,
    columns,
    sellersOptions,
    handleRemoveFilterTag,
    OPTION_OWNER_TYPE,
    setParams,
    handleChangeTable,
    setFilterTags,
    filterTags,
    GET_QUERY,
    dataSummaryTabs,
    PROMOTION_PRICE_BID_STATUS,
    OPTION_PRICE_BID_STATUS,
  };
}
