import { gql } from "@apollo/client";

export const GET_WARRANTY = gql`
  query GetWarranty($filters: WarrantyFilters, $pagination: PaginationRequest!, $sort: [WarrantySort!]) {
    warranty {
      pagination(filters: $filters, pagination: $pagination, sort: $sort) {
        noteWarranty {
          id
          code
          serialImei
          categoryID
          category {
            name
          }
          product {
            id
            code
            name
          }
          orderID
          order {
            number
            id
          }
          seller {
            id
            fullName
            telephone
            address
          }
          providerID
          provider {
            fullName
            telephone
          }
          branchID
          warrantyType
          customerName
          status
          soldDate
          warrantyOnSale
          warrantyDateUnit
          warrantyEndDate
          importDayFromProvider
          warrantyPeriodProviders
          endDateFromProvider
          remainingWarrantyDays
          createdAt
          updatedAt
          productName
          sellerID
          supplierName
          seller {
            id
            fullName
          }
        }
        paginationData {
          total
          offset
          limit
        }
      }
    }
  }
`;

export const GET_WARRANTY_REQUEST_DETAIL = gql`
  query GetWarrantyRequestDetail(
    $id: ID!
    $filters: WarrantyRequestDetailFilters
    $sort: [WarrantyRequestDetailSort!]
  ) {
    warrantyRequest {
      get(id: $id) {
        id
        code
        warrantyCustomerName
        warrantyCustomerPhone
        warrantyCustomerAddress
        warrantyReceiveMethod
        branchID
        branch {
          name
        }
        note
        totalItems
        status
        createdAt
        updatedAt
        updatedBy {
          fullname
        }
        totalItemsByStatus {
          all
          waitingForCheck
          checking
          waitingForDistribute
          repairing
          waitingForReturn
          delivering
          completed
          canceled
        }
        items(filters: $filters, sort: $sort) {
          oldSerial
          id
          serial
          productName
          productType
          warrantyRequestType
          warrantyRequestID
          warrantyNote {
            code
            id
            warrantyEndDate
            remainingWarrantyDays
            productName
            status
            order {
              id
              number
            }
            orderID
            provider {
              address
              code
              fullName
              id
              telephone
            }
            isMigrated
          }
          product {
            id
            code
            name
          }
          productID
          categoryID
          promisedReturnDate
          accessoryAttachment
          itemAmount
          customerRequest
          defectDescription
          imageURLs
          status
          solution
          shippingType
          receiveParty
          result
          brandResult
          resultReceivedDate
          alternativeProductSerial
          alternativeProductName
          alternativeProductCode
          alternativeProductCategoryID
          alternativeProductCategory {
            id
            name
          }
          receiveAmount
          returnAmount
          repairAccessoryName
          alternativeAccessoryName
          alternativeAccessoryStatus
          repairCost
          resultFileURLs
          deliverFileURLs
          isCancel
          delayReturnTimeRange
          receivePartyPhone
          warrantySendDate
          technicalStaffID
          technicalStaff {
            telephone
            fullname
            id
          }
          receivePartyName
          receivePartyPhone
          receiveParty
          receivePartyAddress
          distributeShippingType
          distributeShippingConfig {
            busConfig {
              busName
              telephone
            }
            deliver3PartyConfig {
              name
            }
            urbanConfig {
              fee
            }
          }
          shippingConfig {
            busConfig {
              busName
              telephone
            }
            deliver3PartyConfig {
              name
            }
            urbanConfig {
              fee
            }
          }
          fullEvents {
            state
            name
            createdAt
            createdByID
            createdBy {
              id
              fullname
            }
          }
        }
      }
    }
  }
`;

export const GET_WARRANTY_REQUEST_POPUP = gql`
  query GetWarrantyRequestPopup($id: ID!, $filters: WarrantyRequestDetailFilters) {
    warrantyRequest {
      get(id: $id) {
        id
        status
        items(filters: $filters) {
          id
          serial
          productName
          productType
          warrantyRequestType
          warrantyNote {
            code
            id
            warrantyEndDate
            remainingWarrantyDays
            productName
          }
          productID
          product {
            name
            code
          }
          promisedReturnDate
          status
          solution
        }
      }
    }
  }
`;

export const GET_WARRANTY_REQUEST = gql`
  query GetWarrantyRequest(
    $filters: WarrantyRequestFilters
    $pagination: PaginationRequest!
    $sort: [WarrantyRequestSort!]
  ) {
    warrantyRequest {
      pagination(filters: $filters, pagination: $pagination, sort: $sort) {
        warrantyRequests {
          id
          code
          warrantyCustomerName
          warrantyCustomerPhone
          warrantyCustomerAddress
          warrantyReceiveMethod
          branchID
          note
          totalItems
          createdByID
          createdBy {
            fullname
          }
          createdAt
          status
          totalItemsByStatus {
            all
            waitingForCheck
            checking
            waitingForDistribute
            repairing
            waitingForReturn
            delivering
            completed
            canceled
          }
        }
        paginationData {
          total
          offset
          limit
        }
      }
    }
  }
`;

export const GET_WARRANTY_SERIAL = gql`
  query GetWarrantySerial(
    $filters: WarrantyRequestItemFilters
    $pagination: PaginationRequest!
    $sort: [WarrantyRequestSort!]
  ) {
    warrantyRequest {
      paginationSerial(filters: $filters, pagination: $pagination, sort: $sort) {
        totalItemByStatus {
          all
          waitingForCheck
          checking
          waitingForDistribute
          repairing
          waitingForReturn
          delivering
          completed
          canceled
        }
        warrantyRequestItems {
          id
          productID
          product {
            id
            name
            code
          }
          warrantyRequest {
            createdAt
            warrantyCustomerPhone
            warrantyCustomerName
            createdBy {
              fullname
            }
            code
          }
          serial
          productName
          warrantyRequestID
          customerRequest
          status
          warrantyNote {
            remainingWarrantyDays
            status
            productName
            supplierName
            providerID
            provider {
              fullName
              code
              id
              address
              telephone
            }
          }
          result
          receivePartyName
          alternativeProductSerial
          warrantyRequestType
          solution
          result
          shippingType
          technicalStaff {
            telephone
            fullname
            id
          }
          shippingConfig {
            busConfig {
              busName
              telephone
              location
              busStation
              fee
            }
            deliver3PartyConfig {
              provider {
                id
                name
              }
            }
          }
        }
        paginationData {
          total
          offset
          limit
        }
      }
    }
  }
`;

export const GET_WARRANTY_DETAIL = gql`
  query GetWarranty($id: ID!) {
    warranty {
      get(id: $id) {
        id
        code
        serialImei
        productID
        product {
          id
          code
          name
        }
        categoryID
        category {
          id
          name
        }
        orderID
        order {
          id
          number
        }
        sellerID
        seller {
          id
          fullName
          address
          telephone
          email
        }
        isMigrated
        migratedSerialData {
          id
          serial
          name
          supplier
          dateOfImport
          warrantyOnImportType
          warrantyOnImportPeriod
          warrantyOnImportPeriodUnit
          dateOfSale
          customerName
          customerEmail
          customerPhone
          warrantyOnSaleType
          warrantyOnSalePeriod
          warrantyOnSalePeriodUnit
          branchName
        }
        providerID
        provider {
          id
          fullName
          telephone
          email
          address
        }
        branchID
        branch {
          id
          name
        }
        supplierName
        warrantyType
        status
        soldDate
        productName
        customerName
        supplierName
        warrantyEndDate
        warrantyOnSale
        warrantyDateUnit
        remainingWarrantyDays
        createdAt
        updatedAt
        importDayFromProvider
        warrantyPeriodProviders
        providerDateUnit
        endDateFromProvider
        remainingWarrantyDaysFromProvider
      }
    }
  }
`;

export const GET_TOTAL_ITEM_STATUS = gql`
  query GetTotalItemStatus($id: ID!) {
    warrantyRequest {
      totalItemStatus(warrantyRequestID: $id) {
        all
        waitingForCheck
        checking
        waitingForDistribute
        repairing
        waitingForReturn
        delivering
        completed
        canceled
      }
    }
  }
`;

export const GET_WARRANTY_LOAD_MORE = gql`
  query GetWarrantyLoadmore($filters: WarrantyFilters, $pagination: PaginationRequest!, $sort: [WarrantySort!]) {
    warranty {
      pagination(filters: $filters, pagination: $pagination, sort: $sort) {
        noteWarranty {
          id
          serialImei
          code
          productID
          product {
            name
            code
          }
          productName
          categoryID
          category {
            name
          }
          orderID
          order {
            number
          }
          sellerID
          seller {
            fullName
            telephone
            email
            address
          }
          warrantyType
          status
          soldDate
          warrantyEndDate
          remainingWarrantyDays
          warrantyOnSale
          warrantyDateUnit
          customerName
        }
        paginationData {
          total
          offset
          limit
        }
      }
    }
  }
`;

export const GET_WARRANTY_HISTORY = gql`
  query GetWarrantyHistory($warrantyNoteID: ID!) {
    warranty {
      histories(warrantyNoteID: $warrantyNoteID) {
        warrantyRequestID
        createdAt
        code
        customerName
        customerPhone
        errorDescription
        warrantyResult
        status
        solution
        receivePartyName
        oldSerial
        alterProductSerial
        receiveParty
        technicalStaff {
          id
          fullname
        }
      }
    }
  }
`;

export const GET_SERIAL_DETAIL = gql`
  query GetSerialDetail($id: ID!) {
    warrantyRequest {
      getSerial(id: $id) {
        id
        warrantyNoteID
        warrantyNote {
          id
          code
          serialImei
          productName
          customerName
          supplierName
          productID
          product {
            name
            code
          }
          categoryID
          category {
            code
            name
          }
          orderID
          order {
            number
          }
          sellerID
          seller {
            fullName
          }
          providerID
          provider {
            fullName
            telephone
            email
            address
          }
          branchID
          branch {
            name
          }
          warrantyType
          status
          soldDate
          warrantyOnSale
          warrantyEndDate
          remainingWarrantyDays
          importDayFromProvider
          warrantyPeriodProviders
          endDateFromProvider
          createdAt
          updatedAt
          warrantyDateUnit
          providerDateUnit
          remainingWarrantyDaysFromProvider
        }
        warrantyRequestID
        warrantyRequest {
          code
          id
          warrantyCustomerName
          warrantyCustomerPhone
          warrantyCustomerAddress
          warrantyReceiveMethod
          branchID
          branch {
            name
          }
          note
        }
        serial
        productName
        productType
        warrantyRequestType
        promisedReturnDate
        accessoryAttachment
        customerRequest
        defectDescription
        imageURLs
        status
        fullEvents {
          state
          name
          createdAt
          createdByID
          createdBy {
            fullname
          }
        }
        solution
        shippingType
        shippingConfig {
          busConfig {
            busName
            telephone
          }
          deliver3PartyConfig {
            providerID
            provider {
              name
            }
          }
        }
        receiveParty
        receivePartyName
        receivePartyPhone
        receivePartyAddress
        warrantySendDate
        distributeShippingType
        distributeShippingConfig {
          busConfig {
            busName
            telephone
          }
          deliver3PartyConfig {
            providerID
            provider {
              name
            }
          }
        }
        technicalStaffID
        technicalStaff {
          telephone
          fullname
          id
        }
        result
        brandResult
        resultNote
        resultReceivedDate
        alternativeProductSerial
        alternativeProductName
        alternativeProductCode
        alternativeProductCategoryID
        alternativeProductCategory {
          name
        }
        receiveAmount
        returnAmount
        repairAccessoryName
        alternativeAccessoryName
        alternativeAccessoryStatus
        repairCost
        reimportAmount
        reimportDate
        reimportFileURLs
        resultFileURLs
        deliverFileURLs
        isCancel
        product {
          name
          code
        }
        itemAmount
        categoryID
        productID
        sendNoteNumber
        declineReason
        declineFileURLs
      }
    }
  }
`;

export const GET_SUMMARY_TABS = gql`
  query GetSummaryTabs($pagination: PaginationRequest! = { offset: 0, limit: 10 }) {
    warrantyRequest {
      paginationSerial(pagination: $pagination) {
        totalItemByStatus {
          all
          waitingForCheck
          checking
          waitingForDistribute
          repairing
          waitingForReturn
          delivering
          completed
          canceled
        }
      }
    }
  }
`;

export const GET_WARRANTY_TABLE_PENDING = gql`
  query GetWarrantyTablePending($filters: WarrantyFilters, $pagination: PaginationRequest!, $sort: [WarrantySort!]) {
    warranty {
      pagination(filters: $filters, pagination: $pagination, sort: $sort) {
        noteWarranty {
          id
          code
          serialImei
          serialID
          categoryID
          category {
            name
          }
          product {
            id
            code
            name
            uom {
              name
            }
          }
          orderID
          order {
            number
          }
          seller {
            id
            fullName
            telephone
            email
            address
          }
          customerName
          providerID
          branchID
          warrantyType
          status
          soldDate
          warrantyDateUnit
          provider {
            fullName
            telephone
          }
          warrantyOnSale
          warrantyEndDate
          importDayFromProvider
          warrantyPeriodProviders
          endDateFromProvider
          remainingWarrantyDays
          warrantyDateUnit
          createdAt
          updatedAt
          productName
        }
        paginationData {
          total
          offset
          limit
        }
      }
    }
  }
`;

export const GET_SHIPPING_PROVIDER = gql`
  query GetShippingProvider {
    shippingProvider {
      list {
        id
        name
      }
    }
  }
`;

export const GET_SERIAL_REPLACE = gql`
  query GetSerialReplace($filter: SerialFilters, $pagination: PaginationRequest! = { offset: 0, limit: 10 }) {
    serial {
      pagination(filters: $filter, pagination: $pagination) {
        Serials {
          id
          code
          product {
            id
            code
            name
          }
        }
        paginationData {
          total
          limit
          offset
        }
      }
    }
  }
`;
