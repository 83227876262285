import { Typography } from "antd";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import { t } from "i18next";
import { VAT_TYPE } from "pages/App/Product/constants";

export const ModalConfirmCreate = ({
  handleFinishForm,
  isVAT = true,
  customComponent,
  handleModalConfirmOpen,
  handleModalConfirmCancel,
  isModalConfirm,
  loading,
  quantityRealStockHasVat,
  productCode,
  formData,
  isActionCreate,
}) => {
  const checkStock = isActionCreate
    ? true
    : (formData?.products &&
        formData?.products[0]?.stocks &&
        formData?.products[0]?.stocks[0]?.quantityRealStockHasVat === 0) ||
      (formData?.products && formData?.products[0]?.stocks === null);

  const getVatPercent = formData?.vatPercent ?? formData?.taxablePercentVAT;

  const titleInfoConfirm = () => {
    if (formData?.vatType === VAT_TYPE.NOT_REQUIRED) {
      return (
        <Typography.Text italic>
          Lưu ý: Khi nhập sai thuế suất sẽ dẫn tới hoá đơn VAT bị sai và bạn hoàn toàn chịu trách nhiệm!
        </Typography.Text>
      );
    } else if (formData?.vatType === VAT_TYPE.REQUIRED) {
      if (getVatPercent <= 0 && checkStock) {
        return (
          <>
            <div>
              Mã sản phẩm <Typography.Link>{productCode || formData?.code}</Typography.Link> có{" "}
              <Typography.Link>tồn VAT = 0</Typography.Link>.
            </div>
            <div>Bạn có chắn chắn xuất VAT cho mã sản phẩm này không?</div>
          </>
        );
      }
      if (getVatPercent > 0 && checkStock) {
        return (
          <Typography.Text italic>
            Lưu ý: Nhập sai thuế suất sẽ dẫn tới hoá đơn VAT bị sai và bạn hoàn toàn chịu trách nhiệm!
          </Typography.Text>
        );
      }
    } else {
      return t("product.modalConfirmCreate.messageNotVAT");
    }
  };

  const titleConfirm = () => {
    if (formData?.vatType === VAT_TYPE.NOT_REQUIRED) {
      return "Bạn có chắc chắn sản phẩm này không có thuế VAT?";
    } else if (formData?.vatType === VAT_TYPE.REQUIRED) {
      if (getVatPercent <= 0 && checkStock) {
        return t("product.productForm.confirm_export_vat");
      }
      if (getVatPercent > 0 && checkStock) {
        return "Bạn có chắc chắn thuế suất VAT của sản phẩm đã được nhập chính xác?";
      }
    } else {
      return t("product.modalConfirmCreate.title");
    }
  };

  return (
    <CustomModal
      centered={true}
      footer={false}
      onOke={handleFinishForm}
      onOpen={handleModalConfirmOpen}
      onCancel={handleModalConfirmCancel}
      visible={isModalConfirm}
      customComponent={customComponent}
      buttonLoading={loading}
      title={
        <div style={{ textAlign: "center" }}>
          {formData?.vatType === VAT_TYPE.REQUIRED && getVatPercent === 0 && checkStock ? (
            <SvgIcon.IconInfoQuestion />
          ) : (
            <SvgIcon.WarningFilledIcon style={{ marginBottom: "16px", height: "50px", width: "50px" }} />
          )}

          <Typography.Title style={{ margin: 0 }} level={4}>
            {titleConfirm()}
          </Typography.Title>
        </div>
      }
      message={<Typography.Text>{titleInfoConfirm()}</Typography.Text>}
    />
  );
};
