/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import CustomCard from "components/CustomCard";
import { TAB_INFO_DETAIL } from "features/Agent/constants";
import { useSellerPermissions } from "hooks/seller";
import { useSupplierPermissions } from "hooks/supplier";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import Img from "../../../../../assets/images";
import CreateButton from "../../../../../components/CreateButton";
import { AccountLoginTab } from "./components/AccountLoginTab";
import { GeneralInfoTab } from "./components/GeneralInfoTab";
import { PaymentInfoTab } from "./components/PaymentInfoTab";
import "./index.scss";

const Info = ({ dataDetail, refetch, isRedirectSeller, isSupplier }) => {
  const { canUpdate: canUpdateSeller } = useSellerPermissions();
  const { canUpdateSupplier } = useSupplierPermissions();

  const canUpdate = isSupplier ? canUpdateSupplier : canUpdateSeller;

  const GET_QUERY = getQuery();
  const [activeKeyMain, setActiveKeyMain] = useState(GET_QUERY?.statuses || TAB_INFO_DETAIL.GENERAL_INFO);

  const tabList = [
    {
      key: TAB_INFO_DETAIL.GENERAL_INFO,
      tab: t("potentialCustomer.form.generalInfo"),
    },
    {
      key: TAB_INFO_DETAIL.PAYMENT_INFO,
      tab: t("seller.labelInput.paymentInfo"),
    },
    {
      key: TAB_INFO_DETAIL.ACCOUNT_LOGIN,
      tab: t("potentialCustomer.form.loginAccount"),
    },
  ];

  const handleChangeTab = (activeKey) => {
    setActiveKeyMain(activeKey);
    buildQueryString({
      params: { tab: activeKey },
    });
  };

  const contentList = {
    GENERAL_INFO: <GeneralInfoTab dataDetail={dataDetail} isSupplier={isSupplier} />,
    PAYMENT_INFO: <PaymentInfoTab dataDetail={dataDetail} />,
    ACCOUNT_LOGIN: <AccountLoginTab dataDetail={dataDetail} />,
  };

  useEffect(() => {
    buildQueryString({
      params: {
        statuses: GET_QUERY.tab || TAB_INFO_DETAIL.GENERAL_INF,
        ...GET_QUERY,
      },
    });
  }, [GET_QUERY.tab]);

  return (
    <>
      <Row
        gutter={[16, 16]}
        className="card-title-detail-seller-supplier padding hr"
        justify={"space-between"}
        align={"middle"}
      >
        <Col span={16}>
          <Row gutter={[16, 16]} align="middle" style={{ gap: "24px" }}>
            <img className="avatar" width={40} height={40} alt="" src={dataDetail?.logoUrl || Img.AvatarPlaceHolder} />
            <span className="title">{dataDetail?.fullName || "--"}</span>
            {dataDetail?.isActive || dataDetail?.isActiveSupplier ? (
              <span className="status-true">{t("accountant.cooperate")}</span>
            ) : (
              <span className="status-false">{t("accountant.stopCooperate")}</span>
            )}
          </Row>
        </Col>
        {canUpdate && (
          <Col>
            <CreateButton
              onClick={() => refetch()}
              className="button-action"
              title={
                <Link
                  to={
                    dataDetail?.isSupplier ? `/supplier/update/${dataDetail?.id}` : `/seller/update/${dataDetail?.id}`
                  }
                >
                  {t("order.orderDetail.editBtn")}
                </Link>
              }
            />
          </Col>
        )}
      </Row>
      <div className="wrapper-info">
        <CustomCard
          tabList={tabList}
          onTabChange={handleChangeTab}
          defaultActiveTabKey={activeKeyMain}
          contentList={contentList}
          activeTabKey={activeKeyMain}
        />
      </div>
    </>
  );
};

export default Info;
