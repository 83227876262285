import { Typography } from "antd";
import { Link } from "react-router-dom";
import Tooltip from "../Tooltip";

const EllipsisText = ({ title, line = 2, isLink = false, link, limit = 60, target = "_blank", ...rest }) => {
  const DEFAULT_TEXT = "<không có thông tin>";

  const style = {
    display: "-webkit-box",
    WebkitLineClamp: line,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textAlign: title ? undefined : "center",
  };

  const displayText = title || DEFAULT_TEXT;
  const Content = isLink ? Typography.Link : Typography.Text;

  return (
    <div style={style}>
      <Tooltip title={title?.length > limit ? title : undefined}>
        {link ? (
          <Link target={target} to={link}>
            <Content {...rest}>{displayText}</Content>
          </Link>
        ) : (
          <Content {...rest}>{displayText}</Content>
        )}
      </Tooltip>
    </div>
  );
};

export default EllipsisText;
