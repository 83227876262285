import { Tooltip as TooltipAnt } from "antd";

function Tooltip({ children, title, limit = 60, ...rest }) {
  return (
    <TooltipAnt title={title?.length > limit && title} overlayInnerStyle={{ minWidth: "400px" }} {...rest}>
      {children}
    </TooltipAnt>
  );
}

export default Tooltip;
